import React, {Fragment, useState} from 'react';


import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import logo from '../assets/exchvnge-logo-white.png';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';

import DashboardIcon from '@mui/icons-material/Dashboard'
import BusinessIcon from '@mui/icons-material/Business'
import GroupIcon from '@mui/icons-material/Group'
import CategoryIcon from '@mui/icons-material/Category';
import StorefrontIcon from '@mui/icons-material/Storefront';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ExitIcon from '@mui/icons-material/ExitToAppOutlined'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import { getAuth, signOut } from 'firebase/auth';

const auth = getAuth();


const ConsoleMenu = props => { 

    const [isMenuOpen, setMenuOpen] = useState(false);
    

    const navigateToPage = (page) => {
        props.navigation.navigate(page);
        setMenuOpen(false);
    }

    const toggleDrawerMenu = (event) => {
        setMenuOpen(!isMenuOpen);
    }

    const logout = async () => {
        await sessionStorage.clear();
        await signOut(auth);

        props.navigation.navigate("loginView");
        
    }

    return <Fragment >
    <div className="hamburger-menu header-menu">
            <IconButton onClick={toggleDrawerMenu} ><MenuIcon style={{color: "#fff" }} /></IconButton>
        </div>
        <Drawer anchor="left" onClose={toggleDrawerMenu} open={isMenuOpen} className="app-drawer">
        <div className="drawer-header">
            <img src={logo} className="drawer-logo" alt="logo" />
        </div>
        <Box sx={{width:250}}>
        <List className="header-menu">
            <ListItem className="menu-button-item"  onClick={ (e) => navigateToPage("dashboard")}>
                <ListItemIcon><DashboardIcon /></ListItemIcon>
                <ListItemText primary="Dashboard" /> 
            </ListItem>
            
            <ListItem  onClick={ (e) => navigateToPage("eventsLanding")}>
                <ListItemIcon><EventIcon /></ListItemIcon>
                <ListItemText primary="Events" /> 
            </ListItem>
            
            
            {props.admin &&
            <ListItem  onClick={ (e) => navigateToPage("productsLanding")}>
                <ListItemIcon><CategoryIcon /></ListItemIcon>
                <ListItemText primary="Products" /> 
            </ListItem>
            }
           
            {props.admin &&
            <ListItem  onClick={ (e) => navigateToPage("brandsLanding")}>
                <ListItemIcon><BusinessIcon /></ListItemIcon>
                <ListItemText primary="Brands" /> 
            </ListItem>
            }
           
            <ListItem onClick={ (e) => navigateToPage("orderLanding")}>
                <ListItemIcon><LocalShippingIcon /></ListItemIcon>
                <ListItemText primary="Orders" /> 
            </ListItem>
            
           
            {props.admin &&
            <ListItem onClick={ (e) => navigateToPage("discountsLanding")}>
                <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
                <ListItemText primary="Coupons" /> 
            </ListItem>
            }

            
            
            { (props.admin ) && //|| sessionStorage.getItem("isHost") === "true"
            <ListItem  onClick={ (e) => navigateToPage("usersLanding")}>
                <ListItemIcon><GroupIcon /></ListItemIcon>
                <ListItemText primary="Members" /> 
            </ListItem>
            }

            { props.admin && 
               <ListItem  onClick={ (e) => navigateToPage("waitlistLanding")}>
               <ListItemIcon><HourglassEmptyIcon /></ListItemIcon>
               <ListItemText primary="Waitlist" /> 
           </ListItem>
            }
            <ListItem  onClick={ (e) => { window.open("https://host.exchvnge.co", "_host")}}>
                <ListItemIcon><VideoCameraFrontIcon /></ListItemIcon>
                <ListItemText primary="Host Portal" />
            </ListItem>
            <ListItem onClick={logout}>
                <ListItemIcon><ExitIcon /></ListItemIcon>
                <ListItemText primary="Logout" /> 
            </ListItem>
        </List>
        </Box>
            
        </Drawer>
</Fragment>
}

export default ConsoleMenu;

/*

 {props.admin &&
            <ListItem button={true} onClick={ (e) => navigateToPage("reportsLanding")}>
                <ListItemIcon><TimelineIcon /></ListItemIcon>
                <ListItemText primary="Reports" /> 
            </ListItem>
            }

    */
