import React, {useState, useEffect} from 'react'

import ConsoleTemplate, { adminUsers } from '../components/consoleTemplate'

import {  Avatar, Button, IconButton, Fab, Switch, FormControlLabel, FormControl, InputLabel, NativeSelect } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { getFirestore, collection,  getDocs, query,  orderBy, setDoc, doc, where} from "firebase/firestore";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FileUploadIcon from '@mui/icons-material/FileUpload';

import moment from 'moment'
import {saveAs} from 'file-saver'

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import firebase from '../components/firebase'



const db = getFirestore();

const ProductsLandingView = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [products, setProducts] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [hostProfiles, setHostProfiles] = useState([]);
    const [dataRowSelected, setDataRowSelected] = useState([]);
    const [newVendor, setNewVendor]  = useState("");
    const [newStatus, setNewStatus] = useState(true);


    useEffect( () => {
       
        loadProducts();
        
    }, [])

    

    const loadProducts = async () =>{
        setIsLoading(true);
        try{
            
            const hostResult = await getDocs(query(collection(db, "profiles"),  orderBy("displayname", "asc" )));
            if(hostResult.size >0 ){
                
                const hostDocs = hostResult.docs;
                const profiles = [];
                hostDocs.forEach( (host) => {
                    const hostData = host.data();
                    hostData.id = host.id;
                    if(hostData["isHost"] === true || hostData["isVendor"] === true ) {
                        profiles.push(hostData);
                    }
                });
                setHostProfiles(profiles);
                //console.log("hosts");
               // console.log(profiles);
            }

            const collectionRef = collection(db, "products");
            var result = await getDocs(query(collectionRef, where("createdOn", ">=", new Date("2023-09-01") ),orderBy("createdOn", "asc")));
         
            
            if(result.size > 0) {
                const loadedProducts = [];
                const docs = result.docs;
                docs.forEach ( (product) => {
                    const productObj = product.data();
                    if (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true") {
                    
                    productObj.id = product.id;
                    loadedProducts.push(productObj);
                    } else if(productObj["createdByUid"] === sessionStorage.getItem("uid") || (productObj["hosts"] !== undefined && productObj["hosts"].includes(sessionStorage.getItem("uid")))){
                        productObj.id = product.id;
                        loadedProducts.push(productObj);
                    }

                    
                })
                //console.log(loadedProducts)

                setProducts(loadedProducts);
            }
            
           
            setIsLoading(false);

        }catch (err) {
            setAlertType("error");
            setAlertMessage(err.message);
            setShowAlert(true);
            setIsLoading(false);
            console.log(err);
        }
        setIsLoading(false)
    }

    const onProductClick = (productid) => {
        props.navigation.navigate("productEdit", {id: productid});
    }

    const setProductStatus = async (productid, status) => {
        try {
            setIsLoading(true);

            await setDoc(doc(db, "products", productid), {isActive: status}, {merge:true});

            loadProducts();

            setIsLoading(false);
        }catch(err){
            setAlertType("error");
            setAlertMessage(err.message);
            setShowAlert(true);
            setIsLoading(false);

        }
    } 

    const setFeaturedProduct = async (productid, status) => {
        try {
            setIsLoading(true);

            await setDoc(doc(db, "products", productid), {isFeatured: status}, {merge:true});

            loadProducts();

            setIsLoading(false);
        }catch(err){
            setAlertType("error");
            setAlertMessage(err.message);
            setShowAlert(true);
            setIsLoading(false);

        }
    }

    const onNewVendorChange = async (vendorid) => {
       //console.log(vendorid);
       setNewVendor(vendorid);
    }

    const assignVendorToProducts = async () => {
        try {
            setIsLoading(true);
           // console.log(dataRowSelected);
            const selectedProducts = [];
            for(var i=0; i<dataRowSelected.length; i++) {
               const selectedProduct = products.find( product => product.id === dataRowSelected[i]);
               const productHosts = selectedProduct.hosts;
               if(productHosts.indexOf(newVendor)){
                   console.log("update product " + dataRowSelected[i] + " host " + newVendor);
                   const newHosts = productHosts;
                   newHosts.push(newVendor);
                    const newData = {
                        hosts: newHosts,
                        updatedByName: sessionStorage.getItem("displayname"),
                        updatedByUid:  sessionStorage.getItem("uid"),
                        updatedOn: new Date(),
                    }
                    console.log(newData);
                   await setDoc(doc(db, "products", dataRowSelected[i]), newData, {merge:true});
               }
               
            }
          

            loadProducts();
            setIsLoading(false);
        } catch (err){
            setAlertType("error");
            setAlertMessage("Unable to assign vendor, please try again later");
            setShowAlert(true);
            setIsLoading(false);
        }
    }

    const downloadProductCSVForShopify = async () => {
        setIsLoading(true);
        try {
            var csvContent = "Handle,Title,Body (HTML),Vendor,Product Category,Type,Tags,Published,Option1 Name,Option1 Value,Option2 Name,Option2 Value,Option3 Name,Option3 Value,Variant SKU,Variant Grams,Variant Inventory Tracker,Variant Inventory Qty,Variant Inventory Policy,Variant Fulfillment Service,Variant Price,Variant Compare At Price,Variant Requires Shipping,Variant Taxable,Variant Barcode,Image Src,Image Position,Image Alt Text,Gift Card,SEO Title,SEO Description,Google Shopping / Google Product Category,Google Shopping / Gender,Google Shopping / Age Group,Google Shopping / MPN,Google Shopping / AdWords Grouping,Google Shopping / AdWords Labels,Google Shopping / Condition,Google Shopping / Custom Product,Google Shopping / Custom Label 0,Google Shopping / Custom Label 1,Google Shopping / Custom Label 2,Google Shopping / Custom Label 3,Google Shopping / Custom Label 4,Variant Image,Variant Weight Unit,Variant Tax Code,Cost per item,Price / International,Compare At Price / International,Status\n";

            for(let i=0; i<dataRowSelected.length; i++){
               let product = products.find( (product) => product.id === dataRowSelected[i]);
                console.log(product);
                product.name = product.name.replaceAll('"', '');
                product.description = product.description.replaceAll('"', '');
                let handle = product.name.replaceAll(" ", "-").toLowerCase();
                let productString = `${handle}, ${product.name}, ${product.description.replace("\n", " // ")},${product.curator.length>0?product.curator[0].name:product.createdByName},,${product.category},${product.tags},TRUE,Size,${product.size[0].size},,,,,,, shopify,${product.size[0].inventory},deny,manual,${product.price},,TRUE,TRUE,,${product.images[0]},1,,,,,,,,,,,,,,,,,,,,,${product.cost},,,active\n`;


                if(product.images.length > 1){
                    for(let j=1; j<product.images.length; j++){
                        productString += `${handle},,,,,,,,,,,,,,,,,,,,,,,,,${product.images[j]},,,,,,,,,,,,,,,,,,,,,,,,,\n`;
                    }
                }

                csvContent += productString;
            }
            saveAs(new Blob([csvContent], {type: "text/csv;charset=utf-8"}), `exchvnge_products_${Date.now()}.csv`);
            

        } catch (err){
            setIsLoading(false);
            setShowAlert(true);
            setAlertType("error");
            console.log(err);
            setAlertMessage("unable to download CSV. please try again later")
        }

        setIsLoading(false);
    }

    const bulkUpdateProductStatus = async () => {
       
        setIsLoading(true);
        try {
            
            const selectedProducts = [];
            for(var i=0; i<dataRowSelected.length; i++) {
               //const selectedProduct = products.find( product => product.id === dataRowSelected[i]);
              //console.log("update product " + dataRowSelected[i]);
               const data = {
                     isActive: newStatus,
                     updatedByName: sessionStorage.getItem("displayname"),
                     updatedByUid: sessionStorage.getItem("uid"),
                     updatedOn: new Date(),
               }
               await setDoc(doc(db, "products", dataRowSelected[i]), data, {merge:true});
               //console.log(data);
               
            }

            loadProducts();
        }catch(err){
            setAlertType("error");
            setAlertMessage("Unable to update product status, please try again later");
            setShowAlert(true);
        }
        setIsLoading(false);
    }
    

    return (
        <ConsoleTemplate {...props} isLoading={isLoading} tab={2} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            
            <h2>Products</h2>
            
            { (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isHost") === "true" || sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("isVendor"))&& 
            <Fab color="secondary" size="large" variant="extended" onClick={()=> onProductClick(null)}>
                <AddCircleOutlineIcon /> &nbsp;Add
            </Fab>
         
            }
            <Fab color="secondary" size="large" variant="extended" className="fab-secondary" onClick={()=> {
                 props.navigation.navigate("productsUpload", {eventid: null});
            }}>
                <FileUploadIcon /> &nbsp;Upload CSV
            </Fab>
            
            
            <FormControlLabel control={
                <Switch className="form-input-field" name="showall" checked={showAll} onChange={ (e) => setShowAll(!showAll)} color="secondary" />
            } label="Show All" />
            {dataRowSelected.length > 0 && 
            <div>
               
                <div className="table-action-row">
                <FormControl fullWidth={true} className="select-vendor form-input-field" >
                <InputLabel variant="standard" id="vendor-select-label" shrink={true}>Status</InputLabel>
                <NativeSelect id="vendor-select" value={newStatus.toString()} onChange={ (e) => setNewStatus(e.target.value === "true") } label="Status" >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                </NativeSelect>
                </FormControl>
                <Button variant="contained" color="secondary" onClick={ bulkUpdateProductStatus }>Update Status</Button>
                &nbsp;&nbsp;
                <Button variant="contained" color="secondary" onClick={ downloadProductCSVForShopify }>Download CSV</Button>
                </div>
            </div>
            }
            <div className="products-table data-table">
           
                <DataGrid 
                       checkboxSelection={true}
                       pageSize={20}
                       autoHeight={true}
                       rowsPerPageOptions={[20]}
                       columns= {[  

 { field: "actions", headerName: "Action", type:"actions", flex: 1,  headerClassName: "data-table-header", 
 renderCell: (data) =>  <IconButton aria-label="Manage Product" onClick={ () => { onProductClick(data.id)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>},
                           {field: "image1", headerName: "Image", flex:1, headerClassName: "data-table-header", renderCell: (data) => {
                                return <Avatar name={data.row.name} src={data.row.images[0]} />
                           }},
                           {field: "name", headerName: "Name", flex: 2, headerClassName:"data-table-header"},
                            {field: "price", headerName: "Price", flex:1, headerClassName:"data-table-header", valueFormatter: (event)=>"$" + event.row.price},
                            {/* field: "shipping", headerName:"Shipping", flex:2, headerClassName:"data-table-header", renderCell: (event) => {
                                const shipping = (event.row.freeShipping) ? "FREE" : "$" + event.row.shippingCost;
                                const intlShipping = (event.row.intlFreeShipping) ? "FREE" : event.row.intlShippingCost === -1 ? "N/A": "$" + event.row.intlShippingCost;               
                                return <div>{"US: " + shipping +" / International: " + intlShipping }</div>
                            } */},
                            {field: "hosts", headerName:"Created By", flex:2, headerClassName:"data-table-header" , renderCell: (event) => { 
/*                                 const hosts = event.row.hosts ?? [];
                                const hostNames = [];
                                if(hosts.length === 0) { 
                                    return <div>None</div>;
                                } else {
                                    hosts.forEach( (host) => {
                                        hostNames.push(hostProfiles.find( (profile) => profile.id === host).displayname ?? "NOT FOUND");
                                    });
                                    return <div>{hostNames.join(", ")}</div>
                                } */
                                return <div>{event.row.createdByName}</div>
                            }
                             },
                            {field: "tags", headerName:"Tags", flex:1, headerClassName:"data-table-header" },
                            {field: "inventory", headerName:"Stock", flex:1, headerClassName:"data-table-header", renderCell: (data) => { 
                                
                                var stock = 0;
                                for(var i=0; i<data.row.size.length; i++ ) {
                                    stock += data.row.size[i].inventory;
                                }
                                return stock;

                            }},
                            {field: "isActive", headerName:"Active", flex:1, headerClassName:"data-table-header", renderCell: (data) => <Switch className="form-input-field" checked={data.row.isActive} color="secondary" onChange= { (e) => setProductStatus(data.row.id, !data.row.isActive)} /> },
                            {field: "isFeatured", headerName:"Featured", flex:1, headerClassName:"data-table-header", renderCell: (data) => <Switch className="form-input-field" checked={data.row.isFeatured} color="secondary" onChange= { (e) => setFeaturedProduct(data.row.id, !data.row.isFeatured)} /> },
                           
                       ]}
                       rows={products.filter( ( (product) => showAll ? true : product.isActive))}
                       onSelectionModelChange ={ (selectionModel, details) => {
                          // console.log(selectionModel);
                          setDataRowSelected(selectionModel);
                         //  console.log(details);
                       }
                       }

                />


                    
            </div>


           
        </ConsoleTemplate>
    )
}

export default ProductsLandingView


/*
 <div className="table-action-row">
                <FormControl fullWidth={true} className="select-vendor form-input-field" >
                <InputLabel variant="standard" id="vendor-select-label" shrink={true}>Vendor</InputLabel>
                <NativeSelect id="vendor-select" value={newVendor} onChange={ (e) => {  onNewVendorChange(e.target.value) }} label="Vendor" >
                    <option value="">--- SELECT VENDOR --- </option>
                    {hostProfiles.map( (host) =><option key={host.uid} value={host.uid}>{host.displayname + "(" + host.email +")"}</option>)}
                </NativeSelect>
                </FormControl>
                {newVendor !== "" && 
                <Button variant="contained" color="secondary" onClick={ assignVendorToProducts }>Assign to Vendor</Button>
                }
                </div>


*/

/*

 { field: "actions", headerName: "Action", type:"actions", flex: 1,  headerClassName: "data-table-header", 
                        renderCell: (data) =>  <IconButton aria-label="Manage Product" onClick={ () => { onProductClick(data.id)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>},*/