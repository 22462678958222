import React, {useState, useEffect} from 'react'
import ConsoleTemplate, { adminUsers } from '../components/consoleTemplate'

import {Avatar, Button,  Switch, Dialog, DialogActions, FormControlLabel, DialogContent, DialogTitle, Fab, InputLabel, MenuItem, FormControl, ListItemText, Input, FormHelperText, IconButton} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DataGrid, useGridColumnMenu } from '@mui/x-data-grid';
import { getFirestore, collection,  getDocs, query,  orderBy,  doc, setDoc, addDoc, getDoc, where, deleteDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

//import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DownloadingIcon from '@mui/icons-material/DownloadingOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import SettingsIcon from '@mui/icons-material/Settings';


import {saveAs} from 'file-saver'
import firebase from '../components/firebase'

//import moment from 'moment'
const axios = require('axios').default;
const db = getFirestore();
const auth = getAuth();


const UsersLandingView = (props) =>{

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [userToEdit, setUserToEdit] = useState(null);
    const [statusBadges, setStatusBadges] = useState([]);
    const [showAll, setShowAll] = useState(false);
    //|| sessionStorage.getItem("isHost") === "true"
    useEffect( () => {
        if (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true" ) {
            loadUsers();
           
        }
        getAvailableBadges();
    }, [db])

    useEffect( () => {
        if(userToEdit !== null){
            getUserBlockedStatus();
        }
    }, [userToEdit]);

    const getUserBlockedStatus = async () => {
        try {
            setIsLoading(true);
            const result = await getDoc(doc(db, "restricted_users", userToEdit.uid));
            //getDocs(query(collection(db, "restricted_users"), where("uid", "==", userToEdit.uid)));
         ///   console.log(result);
            if(result.data() !== undefined) {
                //userToEdit.isBlocked = true;
                const user = userToEdit;
                user.isBlocked = true;
                setUserToEdit(user);
                //return true;
          //      console.log("user " + userToEdit.uid + " is blocked");
            } else {
                //userToEdit.isBlocked = false;
                //return false;
                const user = userToEdit;
                user.isBlocked = false;
                setUserToEdit(user);
            //    console.log("user " + userToEdit.uid + " is free");
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.error("getUserBlockedStatus error: ", err.message);
            return false;
        }
        setIsLoading(false);
    }

    const getAvailableBadges = async () => {
        try {
            const result = await getDocs(query(collection(db, "user-status-badges"), orderBy("name", "desc")));
            if(!result.empty) {
                const list = [];
                result.forEach((badgeDoc, index) => {
                    const doc = badgeDoc.data();
                    doc.id = badgeDoc.id;
                    list.push(doc);
                });
               // console.log("badges ");
               // console.log(list);
                setStatusBadges(list);
            }
            
        }catch(err) {
            console.error("badge error");
            console.log(err);
        }
    }

    const downloadUserList = async () =>{
        try{
            setIsLoading(true);
            
            const result = await getDocs(query(collection(db, "profiles"), orderBy("createdOn", "desc")));
            const userList = [];
            var userCSV = "NAME,EMAIL,PHONE,COUNTRY\n";
            if(!result.empty) {
                result.forEach((userDoc, index) => {
                    const userData = userDoc.data();
                    userList.push(userData);
                    userCSV += userData["displayname"]+ ","+userData["email"] +","+userData["phone"] +","+userData["shipping_country"]+"\n";
                });
                saveAs(new Blob([userCSV], {type:"text/csv;charset=utf-8"}), "LivfieUsers_"+Date.now()+".csv");
            }

          
            setIsLoading(false);

        } catch(err) {
            console.log(err);
            setAlertType("error");
            setAlertMessage("Unable to generate CSV file, please try again later");
            setShowAlert(true);
            setIsLoading(false);
        }
    }
    

    const loadUsers = async () => {
        setIsLoading(true)
        console.log("get user info");
        try {
            
              
                const result = await getDocs(query(collection(db, "profiles"), where("displayname", "!=", ""),orderBy("displayname", "desc")));
                if (result.size > 0) {
                    const docs = result.docs;
                    const loadedItems = [];
                    docs.forEach( (item) => {
                        const obj = item.data();
                        obj.id = item.id;
                        obj.name = obj.displayname;
                        obj.description = obj.displayname;
                        obj.followerCount = "followers" in obj ? obj.followers.length : 0;
                        if(userToEdit !== null && obj.id ===  userToEdit.uid) {
                            //await 

                             setUserToEdit(obj);
                        }
                    //  console.log(item)
                        loadedItems.push(obj)
                    });

                    setUsers(loadedItems);
                }
            
            }catch (err) {
                console.error("Error loading users");
                console.log(err);
                setIsLoading(false)
            }
            setIsLoading(false)
    }

    

    const setUserHost = async (uid, host) => {
        try {
            setIsLoading(true);
            await setDoc(doc(db, "profiles", uid), {isHost: host}, {merge: true})
            
            /* await firebase.firestore().collection("profiles").doc(uid).set({
                isHost: host
            }, {merge: true}); */
            loadUsers();
            setIsLoading(false);
        }catch(err) {
            console.log("error setting user host status");
            setIsLoading(false);
        }
    }

    const setUserVerified = async (uid, verified) => {
        try {
            setIsLoading(true);
            await setDoc(doc(db, "profiles", uid), {isVerified: verified, verified_by: sessionStorage.getItem("uid"), verified_on: new Date()}, {merge: true})
            loadUsers();
            setIsLoading(false);

        }catch(err){
            console.log("Error setting user verified status");
            setIsLoading(false);
        }

    }

    const setUserBlocked = async (uid, isBlocked) => {
        try {
            console.log("set blocked " + isBlocked);
            setIsLoading(true);
            if(isBlocked) {
                await setDoc(doc(db, "profiles", uid), {isBlocked: isBlocked}, {merge:true});
                await setDoc(doc(db, "restricted_users", uid), {
                    uid: uid,
                    banned_by: sessionStorage.getItem("uid"),
                    banned_date: new Date(),
                    reason: "Blocked in console"
                });
                const user = userToEdit;
                user.isBlocked = true;
                setUserToEdit(user);
                setIsLoading(false);
            } else {
              //  await 
              await setDoc(doc(db, "profiles", uid), {isBlocked: isBlocked}, {merge:true});
              await deleteDoc(doc(db, "restricted_users", uid));
              const user = userToEdit;
              user.isBlocked = false;
                setUserToEdit(user);
              setIsLoading(false);
            }

        } catch (err) {
            console.log("Error blocking user");
            setIsLoading(false);
        }
    }

    const setUserAdmin = async (uid, host) => {
        try {
            setIsLoading(true);
            await setDoc(doc(db, "profiles", uid), {isAdmin: host}, {merge: true})
            
           
            loadUsers();
            setIsLoading(false);
        }catch(err) {
            console.log("error setting user host status");
            setIsLoading(false);
        }
    }

    const setUserVendor = async (uid, vendor) => {
        console.log("set "+ uid + "vendor to " + vendor);
        try {
            setIsLoading(true);
           await setDoc(doc(db, "profiles", uid), {isVendor: vendor}, {merge: true})
            
           
            loadUsers();
            setIsLoading(false);
        }catch(err) {
            console.log("error setting user host status");
            setIsLoading(false);
        }
    }

    const setUserShop = async (uid, enableShop) => {
        try {
            setIsLoading(true);
            await setDoc(doc(db, "profiles", uid), {shopEnabled: enableShop}, {merge: true})
            
           
            loadUsers();
            setIsLoading(false);

        }    catch(err) {
            console.log("error setting user host status");
            setIsLoading(false);
        }     
    }

    

    const onUserClose = () => {
        setIsDialogOpen(false);
        setUserToEdit(null);
    }

    const onBadgeChange = async (event, uid) => {
        //console.log("onbadge change");
        //console.log(badge);
        try {
            setIsLoading(true);
            await setDoc(doc(db, "profiles", uid), {"badge": event.target.value }, {merge: true});
            loadUsers();
            setIsLoading(false);
        }catch(err){
            setIsLoading(false);
            console.log("Unable to assign badge");
            console.log(err);
        }
    }

    const editUser = async (userObj) => { 
        //https://api.exchvnge.co/isUserEmailVerified?email=

        //const emailAuth = await axios.get("https://api.exchvnge.co/isUserEmailVerified?email=" + userObj.email);
        const authUser = await axios.get("https://api.exchvnge.co/getUserById?uid=" + userObj.uid);
        userObj.isEmailVerified = false;
        //console.log("last signin ", userObj);
        userObj.lastSignInTime = userObj.lastLogin !== null ? new Date(userObj.lastLogin.toDate()).toLocaleDateString() : "";
        userObj.creationTime = "";
        //console.log(authUser);
        if(authUser.status == 200 && authUser.email !== userObj.email) {
            userObj.username = authUser.data.email;
            
            userObj.isEmailVerified = authUser.data.emailVerified;
            if(userObj.lastSignInTime ==="") {
                userObj.lastSignInTime = new Date( parseInt(Date.parse(authUser.data.metadata.lastSignInTime))).toLocaleDateString();
            }
            userObj.creationTime =  new Date(parseInt(Date.parse(authUser.data.metadata.creationTime))).toLocaleDateString();

            console.log("auth user ", authUser.data);
        } else {
            userObj.username = "USER DOES NOT EXIST";
        }
        setUserToEdit(userObj); 
        setIsDialogOpen(true); 
        console.log("Edit User :", userObj);
       
    }

   
    const sendVerificationEmail = async (userEmail) => {
        setIsLoading(true);
        try {

        } catch(err) {  
            console.log("Error sending verification email");
            console.log(err);
        }
        setIsLoading(false);
    }

    return (
        <ConsoleTemplate {...props} isLoading={isLoading} tab={5} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Users</h2>
             { (adminUsers.includes(sessionStorage.getItem("hash"))) && 
                    <Fab color="secondary" size="large" variant="extended" onClick={ (e) => downloadUserList()}>
                        <DownloadingIcon />&nbsp;Download
                    </Fab>
            }
              <FormControlLabel control={
                <Switch className="form-input-field" name="showall" checked={showAll} onChange={ (e) => setShowAll(!showAll)} color="secondary" />
            } label="Show All" />
            <div className="user-table data-table">
            <DataGrid 
                    pageSize={50}
                    autoHeight={true}
                    rowsPerPageOptions={[20]}
                    rows={users.filter( (user) => showAll ? true: !user.isBlocked===true).reverse()}
                    columns={ [
                        //{field: "isBlocked", headerName: "Block User", flex: 1, headerClassName:"data-table-header", renderCell: (data) => <IconButton  onClick={ () => {setUserBlocked(data.row.uid, true)}}><BlockIcon color="secondary" /></IconButton>},
                        {field: "image", headerName:" ", flex:1, headerClassName:"data-table-header", renderCell: (data)=> <Avatar alt={data.row.name} src={data.row.image}>{data.row.name.substr(0,2)}</Avatar>},
                        {field: "name", headerName: "Name", flex: 2, headerClassName:"data-table-header", renderCell: (data) =>  data.row.isBlocked ? <span className="blocked-user">{data.row.name}</span> : data.row.nam},
                        {field: "email", headerName: "Email", flex: 2, headerClassName:"data-table-header", renderCell: (data) => data.row.isEmailVerified ? data.row.email + " (verified)" : data.row.email },
                        {field: "followerCount", headerName: "Followers", flex: 1, headerClassName:"data-table-header"},
                      //  {field: "phone", headerName: "Phone", flex: 1, headerClassName:"data-table-header"},
                     

                       /*  {field: "isAdmin", headerName: "Admin", flex:2, headerClassName: "data-table-header", 
                            renderCell: (data) =>  
                            { return (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true")  ? 
                            <FormControlLabel control={<Switch className="form-input-field" onChange={ () => setUserAdmin(data.id, !data.row.isAdmin)  } checked={data.row.isAdmin} color="secondary" />} label="Admin" />
                            : data.row.isAdmin ? <CheckCircleIcon /> : <CancelIcon />
                            }
                        }, */
                       /*  {field: "isHost", headerName: "Host", flex:2, headerClassName: "data-table-header", 
                            renderCell: (data) =>
                            { return (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true")  ? 
                            <FormControlLabel control={<Switch className="form-input-field" onChange={ () => setUserHost(data.id, !data.row.isHost)  } checked={data.row.isHost} color="secondary" />} label="Host" /> : data.row.isHost ? <CheckCircleIcon /> : <CancelIcon />
                            }
                        }, */
                        {field: "isVendor", headerName: "Vendor", flex:2, headerClassName: "data-table-header", 
                            renderCell: (data) =>
                        
                            <FormControlLabel control={<Switch className="form-input-field" onChange={ () => setUserVendor(data.id, !data.row.isVendor)  } checked={data.row.isVendor} color="secondary" />} label="Vendor" /> 
                            
                        },
                       
                       
                        {field: "isVerified", headerName: "Verified", flex:2, headerClassName: "data-table-header", 
                            renderCell: (data) =><FormControlLabel control={<Switch className="form-input-field" onChange={ () => setUserVerified(data.id, !data.row.isVerified)  } checked={data.row.isVerified} color="secondary" />} label="Verified" />
                        },
                        
                        {field: "badge", headerName: "Badge", flex:2, headerClassName: "data-table-header", 
                        renderCell: (data) => 
                        {
                          
                            const selectedBadge = statusBadges.filter( (badge) => badge.id === data.row.badge);

                           // console.log(selectedBadge);
                        return   ((adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true"))  ? <FormControl sx={{width:100}}>
                                <InputLabel id="badge-select-label">Badge</InputLabel>
                                <Select labelid="badge-select-label" id="badge" value={data.row.badge} onChange={ (e) => onBadgeChange(e, data.id) } 
                                input={<Input label="Name " />}
                                
                                
                                renderValue={ (value) => {
                                
                                    return (value !== "" || value !== undefined) && selectedBadge.length > 0 ? <img src={selectedBadge[0].url} width="30" /> : "NONE";
                                    return "NONE";

                                 }}>
                                    <MenuItem key="0" value="" selected={ data.row.badge === null || data.row.badge === "" ? true : false}>None</MenuItem>
                                    {statusBadges.map ((badge) => {
                                      
                                        return <MenuItem key={badge.id} value={badge.id}><img src={badge.url} width="30" /></MenuItem>
                                    })}
                                </Select>
                                
                                
                                
                        </FormControl> :  selectedBadge.length > 0 ? <img src={selectedBadge[0].url} height="30" /> : "NONE"
                        }
                        
                
                },
                { field: "Settings", headerName: "Settings", flex: 1, headerClassName: "data-table-header",
                renderCell: (data) =>
                    <IconButton onClick={ () => {editUser(data.row)}}><SettingsIcon color="primary" /></IconButton>
                },  
                       /* { field: "actions", headerName: "Action", type:"actions", flex: 1,  headerClassName: "data-table-header", 
                                renderCell: (data) =>  <IconButton aria-label="Manage Brand" onClick={ () => { onUserEdit(data.row)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>}
                                */

                    ]}

            />

             

            </div>
            <Dialog open={userToEdit != null} onClose={onUserClose} user={userToEdit} className="dialog user-dialog" maxWidth="sm" >
                <DialogTitle>User Info</DialogTitle>
                <DialogContent>
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Name</label>
                        <div className="dialog-field-input">{userToEdit !== null ? userToEdit.displayname ?? "N/A" : "N/A"}</div>
                    </div>
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Username</label>
                        <div className="dialog-field-input">{userToEdit !== null ? userToEdit.username ?? "N/A" : "N/A"} {(userToEdit !== null  && userToEdit.isEmailVerified === true) ? <CheckCircleIcon color="primary" /> : " (Not Verified)"}</div>
                    </div>
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Email</label>
                        <div className="dialog-field-input">{userToEdit !== null ? userToEdit.email ?? "N/A" : "N/A"} {}</div>
                    </div>
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Phone</label>
                        <div className="dialog-field-input">{userToEdit !== null ? userToEdit.phone ?? "N/A" : "N/A"}</div>
                    </div>
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Bio</label>
                        <div className="dialog-field-input">{userToEdit !== null ? userToEdit.bio ?? "N/A" : "N/A"}</div>
                    </div>
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">IG Handle</label>
                        <div className="dialog-field-input">{userToEdit !== null ? userToEdit.igHandle ?? "N/A" : "N/A"}</div>
                    </div>
                    {(adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true") && userToEdit !== null &&
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Admin</label>
                        <div className="dialog-field-input"><Switch className="form-input-field" onChange={ () => setUserAdmin(userToEdit.uid, !userToEdit.isAdmin)} checked={userToEdit.isAdmin} color="secondary" /></div>
                    </div>}
                    {(adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true") && userToEdit !== null &&
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Host</label>
                        <div className="dialog-field-input"><Switch className="form-input-field" onChange={ () => setUserHost(userToEdit.uid, !userToEdit.isHost) } checked={userToEdit.isHost} color="secondary" /></div>
                    </div>}
                    {userToEdit !== null &&
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Vendor</label>
                        <div className="dialog-field-input"><Switch className="form-input-field" onChange={ () => setUserVendor(userToEdit.uid, !userToEdit.isVendor)} checked={userToEdit.isVendor} color="secondary" /></div>
                    </div>
                    }
                    
                    {userToEdit !== null &&
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Verified</label>
                        <div className="dialog-field-input"><Switch className="form-input-field" onChange={ () => setUserVerified(userToEdit.uid, !userToEdit.isVerified)} checked={userToEdit.isVerified} color="secondary" /></div>
                    </div>
                    }

{userToEdit !== null &&
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Enable Shop</label>
                        <div className="dialog-field-input"><Switch className="form-input-field" onChange={ () => setUserShop(userToEdit.uid, !userToEdit.shopEnabled)} checked={userToEdit.shopEnabled} color="secondary" /></div>
                    </div>
                    }

                    {userToEdit !== null && 
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Block User</label>
                        <div className="dialog-field-input"><Switch className="form-input-field" onChange={ () => { setUserBlocked(userToEdit.uid, ! (userToEdit.isBlocked ?? false))}} checked={userToEdit.isBlocked ?? false} color="secondary" /></div>
                    </div>
                    }   

                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Joined Date</label>
                        <div className="dialog-field-input">{userToEdit !== null ? userToEdit.creationTime ?? "N/A" : "N/A"}</div>
                    </div>
                    <div className="dialog-field-row">
                        <label className="dialog-field-label">Last Sign In</label>
                        <div className="dialog-field-input">{userToEdit !== null ? userToEdit.lastSignInTime ?? "N/A" : "N/A"}</div>
                    </div>
                </DialogContent>
                <DialogActions>
         
            <Button onClick={ onUserClose }>Close</Button>
        </DialogActions>
    </Dialog>

          
        </ConsoleTemplate>
    )
}

export default UsersLandingView
