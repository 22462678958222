import React, {useState, useEffect} from 'react'
import ConsoleTemplate, { adminUsers } from '../components/consoleTemplate'


import {Avatar, Button,  Switch, Dialog, DialogActions, FormControlLabel, DialogContent, DialogTitle, Fab, InputLabel, MenuItem, FormControl, ListItemText, Input, FormHelperText, IconButton} from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DataGrid, useGridColumnMenu } from '@mui/x-data-grid';
import { getFirestore, collection,  getDocs, query,  orderBy,  doc, setDoc, addDoc, getDoc, where, deleteDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";


import SettingsIcon from '@mui/icons-material/Settings';

const db = getFirestore();

const WaitlistView = (props) => {

    useEffect( () => {
      
        getWaitlist();
    }, [db]);


    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [waitlist, setWaitlist] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [currentApplication, setApplication] = useState(null);

    const getWaitlist = async () => {
        const data = await getDocs(query(collection(db, "waitlist"), orderBy("timestamp", "desc")));
        const list = [];
        setIsLoading(true);
        for(let i =0; i < data.docs.length; i++){

            const request = data.docs[i].data();
            request.id = data.docs[i].id;

            if(request.status === "approved" || request.status === "rejected"){
                request.completed = true;

            }/*  else {
                const profile = await getDoc(doc(db, "profiles", request.uid));

                if(profile.exists()){
                    const profileData = profile.data();
                    request.existingHost = profileData.isHost;
                    request.existingVendor = profileData.isVendor;
                    request.completed = request.existingHost && request.existingVendor || (request.isHost == request.existingHost && request.isVendor == request.existingVendor) ;
                } 

            }
 */
            list.push(request);
            console.log(list);

       // setWaitlist(data.docs.map(doc => ({...doc.data(), id: doc.id})))
        }
        setIsLoading(false);
        setWaitlist(list);
    }


    const approveApplication = async (applicationid, uid, isVendor, isHost) => {
        try {
            setIsLoading(true);
            console.log("Approve Application " + applicationid);
            
            const data = {"status": "approved", "approvedBy": sessionStorage.getItem("uid"), "approvedByName": sessionStorage.getItem("displayname"), "approvedByAvatar": sessionStorage.getItem("avatar"), "approvedTimestamp": new Date()};
            setDoc(doc(db, "waitlist", applicationid), data, {merge: true});

            const profileData = {"isVendor": isVendor, "isHost": isHost, "isVerified": true };
            setDoc(doc(db, "profiles", uid), profileData, {merge: true});

            setApplication(null);
            getWaitlist();

        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }


    const rejectApplication = async (applicationid) => {
        try {
            setIsLoading(true);
            const data = {"status": "rejected", "rejecteddBy": sessionStorage.getItem("uid"), "rejectedByName": sessionStorage.getItem("displayname"), "rejectedByAvatar": sessionStorage.getItem("avatar"), "rejectedTimestamp": new Date()};
            setDoc(doc(db, "waitlist", applicationid), data, {merge: true});

            setApplication(null);
            getWaitlist();
        } catch (error){
            console.error(error);
        }
        setIsLoading(true);
    }

    return  <ConsoleTemplate {...props} isLoading={isLoading} tab={5} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Vendor / Host Applications</h2>
            <FormControlLabel control={
                <Switch className="form-input-field" name="showall" checked={showAll} onChange={ (e) => setShowAll(!showAll)} color="secondary" />
            } label="Show All" />
            <div className="application-table data-table">
            <DataGrid 
                    pageSize={50}
                    autoHeight={true}
                    rowsPerPageOptions={[20]}
                    rows={waitlist.filter( (item) => showAll ? true :  (item.status !== "approved" && item.status !== "rejected" ) )}
                    columns={[
                        {field: "timestamp", headerName: "Date", flex: 1, valueFormatter: (params) => {
                            return new Date(params.value.seconds * 1000).toLocaleDateString("en-US");
                        }},
                        {field: "displayname", headerName: "User", flex: 2, renderCell: (params) => { return <div className="user-display"><div className="user-avatar"><Avatar alt={params.value} src={params.row.avatar} /> </div><div className="user-displayname">{params.value}</div></div> }},
                        
                        {field: "name", headerName: "Name",flex: 2},
                        
                        {field: "isHost", headerName: "To Host", flex: 1, renderCell: (params) => { return params.value ? <span>Yes</span> : <span>No</span> } },
                        {field: "isVendor", headerName: "To Vendor", flex: 1, renderCell: (params) => { return params.value ? <span>Yes</span> : <span>No</span> } },
                        {field: "message", headerName: "Message", flex: 2 },
                  
                       
                        {field: "status", headerName: "Status", flex: 1, renderCell: (params) => {
                            console.log(params);
                            if(params.value === true) return <span className="status completed">Completed</span>
                             if(params.value === "approved") return <span className="status approved">Approved</span> 
                            if(params.value === "rejected") return <span className="status rejected">Rejected</span>

                            if((params.row.completed === true)) {
                            return <span className="status approved">Completed <IconButton onClick={ () => { setApplication(params.row) }}><SettingsIcon color="primary" /></IconButton></span> 
                                }
 
                            return <span className="status pending">Pending <IconButton onClick={ () => { setApplication(params.row) }}><SettingsIcon color="primary" /></IconButton> </span>
                        }}
                    ]}
                >

                    </DataGrid>
            </div>
            <Dialog open={currentApplication !== null} onClose={ () => setApplication(null)}>
                    <DialogTitle>Application</DialogTitle>
                    {currentApplication !== null &&
                    <DialogContent>
                        
                        <div className="dialog-field-row">
                            <label className="dialog-field-label">User</label>
                            <div className="dialog-field-input"><div className="dialog-avatar"><Avatar alt={currentApplication.displayname} src={currentApplication.avatar}/></div><div className="dialog-displayname">{currentApplication.displayname}</div></div>
                        </div>
                        <div className="dialog-field-row">
                            <label className="dialog-field-label">Name</label>
                            <div className="dialog-field-input">{currentApplication.name}</div>
                        </div>
                        <div className="dialog-field-row">
                            <label className="dialog-field-label">Email</label>
                            <div className="dialog-field-input">{currentApplication.email}</div>
                        </div>
                        <div className="dialog-field-row">
                            <label className="dialog-field-label">Phone</label>
                            <div className="dialog-field-input">{currentApplication.phone}</div>
                        </div>
                        <div className="dialog-field-row">
                            <label className="dialog-field-label">IG Handle</label>
                            <div className="dialog-field-input">{currentApplication.igHandle}</div>
                        </div>
                       
                        <div className="dialog-field-row">
                            <label className="dialog-field-label">Apply For Host</label>
                            <div className="dialog-field-input">{currentApplication.isHost ? "Yes" : "No"}</div>
                        </div>
                        <div className="dialog-field-row">
                            <label className="dialog-field-label">Apply For Vendor</label>
                            <div className="dialog-field-input">{currentApplication.isVendor ? "Yes" : "No"}</div>
                        </div>
                        <div className="dialog-field-row">
                        <label className="dialog-field-label">Additional Info</label>
                            <div className="dialog-field-input">{currentApplication.message}</div>
                        </div>
                        <div className="dialog-field-row">
                            <label className="dialog-field-label">Application Date</label>
                            <div className="dialog-field-input">{new Date(currentApplication.timestamp.seconds * 1000).toLocaleDateString("en-US")}</div>
                        </div>
                        <div clssName="dialog-field-row">
                            <label className="dialog-field-label">Approval</label>
                            <div className="dialog-field-input">{currentApplication.status === "approved" || currentApplication.status === "rejected"  ? currentApplication.status +" by " + currentApplication.approvedByName : <div><Button onClick={ () => { approveApplication(currentApplication.id, currentApplication.uid, currentApplication.isVendor, currentApplication.isHost)}  }>Approve</Button> | <Button onClick={ () => { rejectApplication(currentApplication.id)} }>Reject</Button></div>}
                            
                            </div>
                        </div>


                    </DialogContent>
                    }
                    <DialogActions>
                        <Button onClick={ (e) => setApplication(null) }>Close</Button>
                    </DialogActions>
            </Dialog>
          
            
    </ConsoleTemplate>
}

export default WaitlistView;