import React, {useState, useEffect} from 'react'

import ConsoleTemplate, {adminUsers} from '../components/consoleTemplate'
import { Container, Box, TextField, Badge, Tabs, Tab, Input, TextareaAutosize,  Button, IconButton, Dialog, DialogContent, DialogActions, DialogTitle, Switch, FormControlLabel,  } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { getFirestore, collection, doc, getDoc, getDocs, query, where, orderBy, setDoc, Timestamp } from "firebase/firestore";
import { getMessaging } from 'firebase/messaging';

import { getAuth} from "firebase/auth";
//import {getMessaging, getToken} from "firebase/messaging";


import {saveAs} from 'file-saver'
import FirebaseFileUpload from '../components/firebase-fileupload';
import EmailIcon from '@mui/icons-material/EmailOutlined'
import SaveIcon from '@mui/icons-material/Save';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import SendIcon from '@mui/icons-material/Send';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DownloadingIcon from '@mui/icons-material/DownloadingOutlined';
import TabPanel from '../components/tabPanel'

import firebase from '../components/firebase'
import moment from 'moment'


const axios = require('axios').default;







const DashboardView = (props) =>{
    const db = getFirestore();
    const auth = getAuth();
   // const messaging = getMessaging();
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [activeTab, setActiveTab] = useState("events");
    const [showNotificationDialog, setShowNotificationDialog] = useState(false);

    const [events, setEvents] = useState([]);

    const [testEvents, setTestEvents] = useState([]);
    const [notificationSubject, setSubject] = useState("");
    const [notificationContent, setContent] = useState("");
    const [fcmToken, setFcmToken] = useState("");
    const [notificationEventid, setNotificationEventId] = useState("");
    const [notificationEventName, setNotificationEventName] = useState("");
    const [notificationEventHost, setNotificationEventHost] = useState("");
    const [notificationHostName, setNotificationHostName] = useState("");
    const [pushToken, setPushToken] = useState("");

    const [promoText, setPromoText] = useState("");
    const [promoLink, setPromoLink] = useState("");
    const [promoLinkText, setPromoLinkText] = useState("");
    const [showShop, setShowShop] = useState(true);
    const [bannerImage, setBannerImage] = useState("");
    const [bannerX, setBannerX] = useState(10);
    const [bannerY, setBannerY] = useState(80);
    const [bannerW, setBannerW] = useState(50);
    const [bannerH, setBannerH] = useState(50);

    /* getToken({vapidKey: "BLJrOp_YHHwkqZQocoTrUoWGpwXPOZ4kn_oad7-PtU5p1OleNOrI2ksdYTx8j_Ja-OKDqNtm_TUcC8bjHsvD1CA"}).then((currentToken) => {
        if(currentToken) {
            console.log("Token Received: " + currentToken)
        } else {
            console.log("No messaging token available");
        }
    }).catch( (err) => {
        console.log("Error retrieving FCM token ", err);
    });
 */
    const eventDataColumns = [
        { field: 'name',  headerName: 'Event Name',  flex: 5, headerClassName: "data-table-header" },
        { field: "eventDate", headerName: "Date", type: "dateTime", flex: 5,  headerClassName: "data-table-header", valueFormatter: (event) => { 
            const timestamp = new Timestamp(event.value.seconds,event.value.nanoseconds);
            //console.log(timestamp)
            //console.log(timestamp.toString());
            return moment(timestamp.toDate()).format("MM/DD/YYYY h:mm a");
        }},
        { field: "hostname", headerName: "Host",  headerClassName: "data-table-header", flex: 2},
        { field: "actions", headerName: "Actions",  flex: 2,  headerClassName: "data-table-header", 
            renderCell: (event) => {
              
                return (
                <div>
                
                <span>
                    { (sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("isHost") === "true" ) &&<IconButton aria-label="Send Reminder" onClick={ () => { showReminderForm(event.id, event.row.name, event.row.description, event.row.host, event.row.hostname)  }} ><Badge badgeContent={event.row.reminderCount ?? 0} color="primary"><EmailIcon sx={{ color: "#000000"}}/></Badge></IconButton>}
                </span>
                    
                { (sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("isHost") === "true" ) &&<IconButton aria-label="Manage Event" onClick={ () => { onManageClick(event.id)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>}
                
                

                </div>)
            }
            
        
    },
        ];
    const eventTableProps = {
        pageSize: 5,
        autoHeight: true,
        rowsPerPageOptions: [5,10],
        columns: eventDataColumns
        
       
    }

    useEffect ( () => {
  
        getPromoText();

      
       getAllEvents();
    }, []);

    const getAllEvents = async () => {
        try {
      
            const collectionRef = collection(db, "events");
            var q;
            
            if(adminUsers.includes(sessionStorage.getItem("hash") ) || sessionStorage.getItem("isAdmin") === "true"){ // livfie admin team
              
                q = query(collectionRef, orderBy("eventDate"));
            } else { // general hosts
              
                q = query(collectionRef, where("host", "==", sessionStorage.getItem("uid")));
            }
            
            const eventResult = await getDocs(q);
           
            if(!eventResult.empty) {
                const loadedActiveEvents = [];
                const loadedTestEvents = [];
                
                eventResult.forEach( (doc, index) => {
                    var eventData = doc.data();
                    eventData.id =doc.id;

                    if(eventData.isActive === true) {
                        loadedActiveEvents.push(eventData);
                    } 
                    if (eventData.isTest === true) {
                        loadedTestEvents.push(eventData);
                    }

                });
                
                setEvents(loadedActiveEvents);
                setTestEvents(loadedTestEvents);

            }

        } catch(err) {
            setAlertMessage(err.message);
            setAlertType("error");
            setShowAlert(true);
            console.log(err);
        }
    }

   


    const onManageClick = (eventid) => {
        console.log("live " + eventid);
        
        props.navigation.navigate("liveEvent", {id: eventid});
    }

  
   
    

    const sendEventReminders = async (eventid) => {
        try {
            setIsLoading(true)
            const endpoint = "https://us-central1-livfieapp.cloudfunctions.net/prepareReminders";
            
            

            const data = { eventid: eventid, subject: notificationSubject, content: notificationContent}
    
            const sendResult = await axios.post(endpoint, data);
            
            //const count = JSON.parse(sendResult);
            //console.log(sendResult);
            var message = sendResult.data.count + " reminders sent";

            setAlertMessage(message);
            setAlertType("success");
            setShowAlert(true);

            setIsLoading(false);
            setShowNotificationDialog(false);

        } catch (err) {
            setAlertMessage(err.message);
            setAlertType("error");
            setShowAlert(true);
            setIsLoading(false);
        }
    }

    const sendPushNotification = async (title, content, eventname, eventid, uid, displayname) => {
   
            setIsLoading(true);
            try {
               /*  const sendResult = await axios.post("https://api.exchvnge.co/members/sendNotificationsToSubscribers", {
                    subject: title,
                    body: content,
                    eventid: eventid, 
                    eventname: eventname,
                    uid: uid, 
                    displayname: displayname,
                   // token: "ce9fm2YdKU2ljgf5Rzhj4e:APA91bEsHcE3ej7px9TYDSkjjUBDnrBTclsFxMlkSHUIGQvNt_6XpKSme2AFLQj5HjLuyHc4wXqMfQiybJBmmGvbT-FyoRpy6OZBs0MxTNCn34zrRxthXciu1hQBwL7t0JGMu1TMjM3Q"
                }); */
                let url = "https://api.exchvnge.co/members/sendEventNotifications"; // "https://api.exchvnge.co/members/sendNotificationsToFollowers"
                if(fcmToken !== ""){
                    url = "https://api.exchvnge.co/members/sendMemberNotification";
                }
                
                

                const sendResult = await axios.post(url, {
                    subject: title,
                    body: content,
                    hostid: uid, 
                    eventid: eventid,
                    eventname: eventname,
                    uid: uid, 
                    displayname: displayname,
                    token: fcmToken,

                });

                setAlertMessage(sendResult.data.message);
                setAlertType("success");
                setShowAlert(true);
            } catch (err){
                setAlertMessage(err.message);
                setAlertType("error");
                setShowAlert(true);
                
            }

            setIsLoading(false);
       
    }

    const showReminderForm = async (eventid, eventname, description, uid, displayname) => {
        setShowNotificationDialog(true);
        const s = "Going live at Exchvnge - " + eventname;
        const c = description;
        setSubject(s);
        setContent(c);
        setNotificationEventId(eventid);
        setNotificationEventName(eventname);
        setNotificationEventHost(uid);
        setNotificationHostName(displayname);
        setShowNotificationDialog(true);
    }

    const getPromoText = async () => {
        try {
            setIsLoading(true);
            const result = await getDoc(doc(db, "app-settings", "all"));

            if(result) {
                const data = result.data();
             //   setPromoText(data["promoText"]);
              //  setPromoLink(data["promoLink"]);
              //  setPromoLinkText(data["promoLinkText"]);
                setBannerImage(data["partnerBanner"]);
                setPromoLink(data["partnerLink"]);
                setBannerX(data["bannerX"]);
                setBannerY(data["bannerY"]);
                setBannerW(data["bannerW"]);
                setBannerH(data["bannerH"]);
            }

            setIsLoading(false);

        }catch (err){
            setAlertType("error");
            setAlertMessage("Failed to load promo text. Please try again later");
            setShowAlert(true);
            setIsLoading(false);
        }
    }

    const savePartnerPromo = async () => {
        try {
            setIsLoading(true);

            await setDoc(doc(db, "app-settings", "all"), {
                
                partnerLink: promoLink,
                partnerBanner: bannerImage,
                bannerX: parseInt(bannerX),
                bannerY: parseInt(bannerY),
                bannerW: parseInt(bannerW),
                bannerH: parseInt(bannerH),
            }, {merge: true});


            setIsLoading(false);

        }catch (err){
            setAlertType("error");
            setAlertMessage("Failed to save promo text. Please try again later");
            setShowAlert(true);
            setIsLoading(false);
        }
    }


    const clearPromo = () => {
        setPromoText("");
        setPromoLink("");
        setPromoLinkText("");
        setBannerImage("");
    }

    const onShowShopChange = async (e) => {
        try {
            setIsLoading(true);
            console.log(e.target);
             await setDoc(doc(db, "app-settings", "all"), {
                showShop: !showShop
                
            }, {merge:true}); 
            setShowShop(!showShop);
            setIsLoading(false);
        } catch (err){
            setIsLoading(false);
            console.log("Update Show shop error");
            console.error(err);
        }
    }

    const onTabChange = (event, newValue) => {
        
        setActiveTab(newValue);
    }

    const downloadSubscribers = async (eventid, eventname) => {
        try {
            setIsLoading(true);
            //const result = await getDoc(doc(db, "app-settings", "all"));
            const result = await getDoc(doc(db, "event-reminders", eventid ));
    
            //console.log(result);
             if(result.exists()) {
                const subscriberData= result.data();
                console.log(subscriberData);
                const users = subscriberData["users"];
                var csvstring = "";
                users.forEach(( user) => {
                   // console.log();
                   csvstring += user["displayname"]+", "+user["email"]+","+user["date"].toDate()+"\n";
                })
                console.log(csvstring);
                saveAs( new Blob([ csvstring ], {type: "text/csv;charset=utf-8"}), eventname+".csv");
            } 

            setIsLoading(false);
        }catch (err){
            setAlertType("error");
            setAlertMessage("Unable to generate CSV File. Please try again");
            setShowAlert(true);
            setIsLoading(false);
        }
    }


    return (
        <ConsoleTemplate {...props} isLoading={isLoading} tab={0} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Dashboard</h2>

                <Container className="form-tabs" >
                    <Tabs value={activeTab} onChange={onTabChange} >
                        <Tab label="Events" value="events" />
                        {(adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true") &&
                        <Tab label="Settings" value="settings" />}
                    </Tabs>
                </Container>
                <TabPanel name="events" activeTab={activeTab}>
                
                { events.filter((event) => !event.isArchive).length > 0 &&
                <Container>  
                <h3>Upcoming Events</h3>
                    <div  className="dashboard-event-table-container">
                        <DataGrid 
                            {...eventTableProps} 
                           rows = {events.filter( (event) => !event.isArchive && event.eventDate.toDate() >= (Date.now() - 24 * 60 * 60 * 1000))} />
                    </div>
                </Container>
                }   
                
                    
                    {testEvents.length > 0 &&
                    <Container>
                    <h3>Preview Events</h3>
                    <div  className="dashboard-event-table-container">
                        <DataGrid 
                            {...eventTableProps}
                            
                            rows = {testEvents}

                          
                        />
                    </div>
                   
                    </Container>
                    }
                </TabPanel>
                <TabPanel name="settings" activeTab={activeTab}>
                    { (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true")  && <Box>
                    <h3>Partner Promo</h3>
                    <FirebaseFileUpload id="image-upload" className="form-image-upload" label="Banner Image" accept="image/*" name="image1" storagePath="promo/" onSuccess={ (path)=>{ setBannerImage(path)}} preview={bannerImage} onRemovePreview={ () => { setBannerImage("") }}/> 
                    <TextField id="promolink" className="form-input-field" size="small" type="url" name="promolink" fullWidth={true} label="Pormo Link" placeholder="https://www.google.com/" value={promoLink} onChange={ (e) => setPromoLink(e.target.value)} />
                    <TextField id="bannerW" className="form-input-field" size="small" type="number" name="bannerW" fullWidth={true} label="Banner Size" placeholder="size" value={bannerW} onChange={ (e) => setBannerW(e.target.value)} />
                 
                    <TextField id="promoOffsetX" className="form-input-field" size="small" type="number" name="promoOffsetX" fullWidth={true} label="X Position (from left)" placeholder="x position" value={bannerX} onChange={ (e) => setBannerX(e.target.value)} />
                    <TextField id="promoOffsetY" className="form-input-field" size="small" type="number" name="promoOffsetY" fullWidth={true} label="Y Position (from bottom)" placeholder="y position" value={bannerY} onChange={ (e) => setBannerY(e.target.value)} />
                    
                   
                    
                    
                    <Button variant="contained" color="buttonDark" onClick={savePartnerPromo} >SAVE</Button>&nbsp;&nbsp;
                    <Button variant="contained" color="buttonLight" onClick={ clearPromo } >CLEAR</Button>
                    <h3>Show Shop Tab</h3>
                    <FormControlLabel className="form-input-field" control={
                        <Switch color="secondary" checked={showShop } onChange={ onShowShopChange} />
                    } label="Show Shop Tab" />
                    </Box>}



               
                </TabPanel>                  
                    <Dialog open={showNotificationDialog} onClose = { () => {} }  >
                        <DialogTitle>Notify Members</DialogTitle>
                        <DialogContent sx={{ width:"80%", maxWidth:"850px"}}>

                            <form>
                                    <TextField fullWidth={true} className="form-input-field" type="text" name="token" label="Token" placeholder="Token" value={fcmToken} onChange={ (e)=> setFcmToken(e.target.value)} />
                                    <TextField fullWidth={true} className="form-input-field" type="text" name="subject" label="Subject" placeholder="subject" value={notificationSubject} onChange={ (e)=> setSubject(e.target.value)} />
                                    <TextField fullWidth={true} rows={3}  name="notificationContent" className="form-input-field" label="Notification Content" placeholder="content..." fullWdith={true} multiline value={notificationContent} onChange={ (e) => setContent(e.target.value)} />
                                 

                                   
                               
                            </form>
                        </DialogContent>
                        <DialogActions>
                       
                        <IconButton onClick={ () => downloadSubscribers(notificationEventid, notificationEventName)}><DownloadingIcon sx={{color:"#000" }} /></IconButton>
                        <Button variant="contained" color="buttonDark" onClick={ (e) => sendPushNotification(notificationSubject, notificationContent, notificationEventName, notificationEventid, notificationEventHost, notificationHostName) }>Send Push Notification</Button>
                            <Button variant="contained" color="buttonDark" onClick={ (e) => sendEventReminders(notificationEventid) }>Send Email</Button>
                            <Button variant="contained" color="buttonLight" onClick={ (e) => setShowNotificationDialog(false) }>Close</Button>


                        </DialogActions>
                    </Dialog>
        </ConsoleTemplate>
            

    )
}

export default DashboardView


// <Button variant="contained" color="buttonDark" onClick={ (e) => sendPushNotification(notificationSubject, notificationContent) }>Send Push Notification</Button>

