import React, {useState, useEffect, useRef} from 'react'
import firebase from '../components/firebase'
import ConsoleTemplate, {adminUsers} from '../components/consoleTemplate'
import { getFirestore, collection,  getDoc, getDocs, query, where, orderBy, setDoc, addDoc, doc} from "firebase/firestore";

import {  Container, NativeSelect, Tab, Tabs, InputLabel,  Table, TableBody, TableCell, TableContainer,  TableHead, TableRow, Input, TextField, Select, IconButton, Button, Switch, FormControl, Chip, MenuItem, FormControlLabel,  } from '@mui/material'



import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import FirebaseFileUpload from '../components/firebase-fileupload';
import TabPanel from '../components/tabPanel'

const db = getFirestore();



const ProductEditView = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
   const [categories, setCategories] = useState([]);
   const [conditions, setConditions] = useState([]);
    const [brands, setBrands]  = useState([]);

    const [selectedHosts, setSelectedHosts] = useState([]);
    const [selectedCollaborators, setSelectedCollaborators] = useState([]);
    const [selectedCurator, setSelectedCurator] = useState([]);

    const [hosts, setHosts] = useState([]);
    const [tags, setTags] = useState([]);
    const [activeTab, setActiveTab] = useState("basic"); 
    let hostArray = [];
    let selectedTags = useRef([]);


    const [state, setState] = useState({
        id: "",
        sku: "",
        name: "",
        description: "",
        brand: "",
        images: [],
        size: [{"size" : "ONE SIZE", "inventory": 1}],
        isActive: true,
        isFeatured: false,
        category:"",
        condition:"",
        events: [],
        hosts: [],
        collaborators: [],
        curator: [],
        tags: [],
        weight: 0.5,
        price: 0,
        cost: 0,
        originalPrice: 0,
        limitPerUser: 0,
        boxWidth:9,
        boxHeight: 1,
        boxLength: 15,
        shippingCost: 0,
        order: 1,
        freeShipping: false,
        intlFreeShipping: false,
        intlShippingCost: 0,
        owner: "",
    })

    useEffect ( () => {
       // loadCategories();
        getBrands();
        //loadEvents();
       // getHostData();
        const productid= props.navigation.getParam("id");
        //console.log(productid)
        getProductSettings();
        getProductData(productid);
        if (productid != null) {
            setState({...state, id: productid })
           
        }
    }, [])
    
    
    const getProductSettings = async ()  => {
        try {
            const result = await getDoc(doc(db, "app-settings", "products"));
            if(result) {
                const settings = result.data();
                if(settings) {
                   setCategories(settings.category);

                   setConditions(settings.condition);

                }
            }
        }catch (err) {
            console.log(err)
        }
    }

    const getProductData = async (productid) => {
        setIsLoading(true);
        // get host users
        var loadedHosts = [];
        const productHosts = [];
        const hostResult = await getDocs(query(collection(db, "profiles"),  orderBy("displayname", "asc")));
        if(hostResult.size >0 ) {
            const hostDocs = hostResult.docs;
            
            hostDocs.forEach( (host) => {
                const data = host.data();
                if(data.isHost === true || data.isVendor === true) {
                    const hostObj = { name: data.displayname, email: data.email, uid: data.uid, image: data.image};
                    loadedHosts.push(hostObj);
                }
                
            
            });
            
            setHosts(loadedHosts);
            hostArray = loadedHosts;
        }

        // get tags
        var loadedTags = [];

        const tagResult = await getDocs(query(collection(db, "tags"), orderBy("name", "asc")));
        if(tagResult.size >0 ) {
            const tagDocs = tagResult.docs;
            tagDocs.forEach( (tag) => {
                const data = tag.data();
                const tagObj = { name: data.name, id: data.id};
                loadedTags.push(tagObj);
            });
            setTags(loadedTags);


        }
                

        if (productid != null ) {
            
            try {
                
                const result = await getDoc(doc(db, "products", productid));
                //await firebase.firestore().collection("products").doc(productid).get();
                
                if (result) {
                    const product = result.data();

                    if ( !product.size) {
                        product.size  = [];
                    }

                    if (!product.events) {
                        product.events = [];
                    }
                    
                    if(product.hosts !== null && product.hosts.length > 0 ) {
                        for(var i=0; i<product.hosts.length;i++) {
                            for(var j=0; j<loadedHosts.length; j++) {
                                if(product.hosts[i] === hostArray[j].uid){
                                    productHosts.push(hostArray[j]);
                                }
                            }
                        }  
                        setSelectedHosts(productHosts);
                    
                    }
                    
                    selectedTags.current = product.tags ?? [];
              
                    
                    setState({
                        ...state,  
                        id: productid,
                        name: product.name,
                        description: product.description,
                        sku: product.sku,
                        brand: product.brand,
                        images: product.images,
                        size: product.size,
                        isActive: product.isActive,
                        isFeatured: product.isFeatured, 
                        category: product.category,
                        condition: product.condition,
                        price: parseInt(product.price),
                        cost: parseInt(product.cost), 
                        originalPrice: product.originalPrice != null && product.originalPrice >= product.price ? parseInt(product.originalPrice) : parseInt(product.price),
                        limitPerUser: product.limitPerUser != null ? product.limitPerUser : 0,
                        events: product.events ?? [],
                        collaborators: product.collaborators ?? [],
                        curator: product.curator ?? [],
                        tags: product.tags ?? [],
                        hosts: product.hosts ?? [],
                        weight: product.weight != null ? product.weight : 0.5,
                        order: product.order != null ? product.order : 1,
                        boxWidth: product.boxWidth != null ? product.boxWidth: 9,
                        boxHeight: product.boxHeight != null ? product.boxHeight: 1,
                        boxLength: product.boxLength != null ? product.boxLength : 15,
                        shippingCost: product.shippingCost != null? product.shippingCost : 0,
                        freeShipping: product.freeShipping != null ? product.freeShipping : false,
                        intlFreeShipping: product.intlFreeShipping != null? product.intlFreeShipping : false,
                        intlShippingCost: product.intlShippingCost != null ? product.intlShippingCost : -1,
                        owner: product.createdByUid,

                    })

                    setSelectedCollaborators(product.collaborators ?? []);
                    setSelectedCurator(product.curator ?? []);
//                    console.log(product)

                }


                setIsLoading(false);
            } catch (err) {
                setAlertMessage("Product: " + err.message);
                setAlertType("error");
                setShowAlert(true);
                setIsLoading(false)
            }   
        }
        setIsLoading(false);
    }

    const onActiveSwitchChange = (event) => {
        
        setState( {
            ...state,
            isActive: !state.isActive
        })
    }

    const onFeaturedSwitchChange = (event) => {
       
        setState( {
            ...state,
            isFeatured: !state.isFeatured
        })
    }

   

    const getBrands = async () => {
        try {
            var result; 
            if (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("isHost") === "true") {
                result = await getDocs(query(collection(db, "brands"), where("isActive", "==", true), orderBy("name", "asc")));
                //result = await firebase.firestore().collection("brands").where("isActive", "==", true).orderBy("name", "asc").get();
            } else {
                result = await getDocs(query(collection(db, "brands"), where("isActive", "==", true), where("users", "array-contains", sessionStorage.getItem("uid")), orderBy("name", "asc")));
                //result = await firebase.firestore().collection("brands").where("isActive", "==", true).where("users", "array-contains", sessionStorage.getItem("uid")).orderBy("name", "asc").get();
            }
            if(result.size > 0) {
                const docs = result.docs;
                var brandResults = [];
                docs.forEach( (item) => {
                    const data = item.data();
                    //data.id = item.id;
                    brandResults.push(data.name)
                });
                setBrands(brandResults);
            }


        }catch (err) {
            console.log(err)
        }
    }

   
    const updateProduct = async () => {
        setIsLoading(true)
        const productid = props.navigation.getParam("id");

        console.log(productid);

        try {

            const productObj = state;
            
            // go through selected hosts and add to productObj.hosts
            let hosts = [productObj.owner];
            for(let i=0; i <selectedCollaborators.length; i++){

                if(!hosts.includes(selectedCollaborators[i].uid)){
                    hosts.push(selectedCollaborators[i].uid);
                }
            }

            for(let i=0; i <selectedCurator.length; i++){

                if(!hosts.includes(selectedCurator[i].uid)){
                    hosts.push(selectedCurator[i].uid);
                }
            }

            productObj.hosts = hosts;

            
            productObj.updatedOn = new Date();
            productObj.updatedByName = sessionStorage.getItem("displayname");
            productObj.updatedByUid = sessionStorage.getItem("uid");

            console.log(productObj);


            if (productid !== null ) { //update product 
                
                await setDoc(doc(db, "products", productid), productObj, {merge:true});
                //await firebase.firestore().collection("products").doc(productid).set(productObj, {merge:true})
            } else { // add product
                productObj.createdOn = new Date();
                productObj.createdByUid = sessionStorage.getItem("uid");
                productObj.createdByName = sessionStorage.getItem("displayname");
                await addDoc(collection(db, "products"), productObj);
                //await firebase.firestore().collection("products").add(productObj);
            }
            setIsLoading(false)
            props.navigation.navigate("productsLanding")
        }catch (err) {
            setAlertType("error");
            setAlertMessage(err.message);
            setShowAlert(true)
            setIsLoading(false);
        }

    }

    const cancelUpdate = () => {
        props.navigation.navigate("productsLanding")
    }

    const onCategoryChange = (e) => {
        setState( {...state, category: e.target.value})
    }

    const onBrandChange = (e) => {
        setState({...state, brand: e.target.value});
    }

    

   

    

    const removeImage = (imageNum) => {
       /*  if(imageNum === 1) {
            setState({...state, image1:""});
        }else if(imageNum ===2 ){
            setState({...state, image2: ""})
        } else if (imageNum ===3) {
            setState({...state, image3: ""})
        } */

        const images = state.images;
        images[imageNum-1] = "";
        setState({...state, images: images});

    }

    const addNewSize = () => {
        //setState( {...state}, size: [{}] )
        var newSize = state.size;

        newSize.push( {
            size: "",
            inventory: 0,
        })

        setState({...state, size: newSize});
    }

    const updateInventory = (index, size = null, inventory = 0) => {
        var updateSize = state.size;
        if (size !== null) {
            updateSize[index].size = size;
        }
        if (inventory !== 0) {
            updateSize[index].inventory = parseInt(inventory);
        }
        setState({...state, size: updateSize})
    }

    const removeSize = (index, size) => {
       // console.log("Remove : " + index +" / " + size.size);
        var sizes = state.size;

        for(var i=0; i<sizes.length; i++) {
            if(i===index && sizes[i].size === size.size) {
                sizes.splice(i, 1);
            }
        }
        setState({...state, size: sizes});

    }

    const userInList = (host, list) => {
        let inList = -1

        for(let i=0; i<list.length; i++) {
            if(list[i].uid === host.uid) {
                return i;
            }
        }
     
        return inList;
    }

    const onHostChange = (event, child) => {
        //console.log(child.props.value);
        const selected =child.props.value;
        const hostList = selectedHosts;
        const stateUserList = state.hosts;
        var alreadyInList = false;
        hostList.forEach((host, index) => {
            if(host.uid === selected.uid) {
                alreadyInList = true;
                hostList.splice(index, 1);
                stateUserList.splice(index, 1);
            }
        })

        if(!alreadyInList) {
            hostList.push(selected);
            console.log(stateUserList);
            stateUserList.push(selected.uid);
        }

        console.log(stateUserList);

         setState( {
             ...state, 
            hosts: stateUserList,
        }) 
    }

    const onCollaboratorsChange = (event, child) => {
        const selected = child.props.value;
        console.log(selected);
//        let stateHosts = state.hosts;
        let pos = userInList(selected, selectedCollaborators);
        if(pos >=0 ) {
            // already in list, remove user
            selectedCollaborators.splice(pos, 1);
            
        } else {
            // not in list , add user
            selectedCollaborators.push(selected);
          
        }

        setState( {
            ...state,
            collaborators: selectedCollaborators,
           
        })
    

    }   

    const onCuratorChange = (event, child) => {
        const selected = child.props.value;
        let pos = userInList(selected, selectedCurator);
        if(pos >=0 ) {
            
            selectedCurator.splice(pos, 1);
        } else {

            selectedCurator.push(selected);
            
        }

        setState( {
            ...state,
            curator: selectedCurator,
           
        })
    
    }

    const onTagsChange = (event, child) => {
        
        
       
        const selected = child.props.children;
        var tagInList = false;
        selectedTags.current.forEach( (tag, index) => {
            console.log(tag);
            if(tag === selected) {
                console.log("remove " + tag)
                selectedTags.current.splice(index, 1);
                tagInList = true;
                
            }
        });
        if(!tagInList) {
            selectedTags.current.push(selected);   
        }
        setState( {
            ...state,
            tags: selectedTags.current
        })

        
    }

   
    

    return (
        <ConsoleTemplate {...props} isLoading={isLoading } tab={2} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage} >
            <h2>Product Details</h2>
            <div className="product-edit-form edit-form">

                <Container className="form-tabs">
                    <Tabs value={activeTab} onChange={ (e, tab) => setActiveTab(tab)}>
                        <Tab label="Basic Info" value="basic" />
                        <Tab label="Images" value="images" />
                        <Tab label="Inventory" value="inventory" />
                        <Tab label="Shipping" value="shipping" />
                        <Tab label="Additional Info" value="additional" />
                    </Tabs>
                </Container>
                <TabPanel name="basic" activeTab={activeTab}>
                    <TextField className="form-input-field" type="text" size="small" fullWidth={true} label="Product Name" placeholder="Product Name" id="name" value={state.name} onChange={ (event) => { setState({...state, name: event.target.value})}} />
                    <TextField className="form-input-field" type="text" size="small" multiline={true} fullWidth={true} label="Description" maxRows={4} placeholder="Product Description" id="description" value={state.description} onChange={ (event) => setState( {...state, description: event.target.value})} />
                    <TextField className="form-input-field" type="number" size="small" fullWidth={true} label="Selling Price" placeholder="Selling Price" id="price" value={state.price} onChange={ (event) => { setState({...state, price: parseInt(event.target.value)})}}  />
                    <FirebaseFileUpload id="image1-upload" className="form-image-upload" label="Cover Image" accept="image/*" name="image1" storagePath="products/" onSuccess={ (path)=>{ 
                        const images = state.images;
                        images[0] = path;

                        setState({...state, images: images})}} preview={state.images[0]} onRemovePreview={ () => removeImage(1) }/> 
                  
                     { (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isHost") === "true" || sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("isVendor") === "true" )&& 
                    <FormControl fullWidth={true} className="multi-select select-product-hosts form-input-field">
                                <InputLabel id="host-select-label" shrink={true} >Collaborators</InputLabel>
                                <Select id="product-hosts" multiple value={selectedCollaborators} onChange={ onCollaboratorsChange }
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={
                                        (selected) => {
                                            console.log("collab");;
                                            console.log(selected);
                                            return (<div className="select-chips host-chips">
                                                {selected.map( (value) => (
                                                    <Chip key={value.uid} label={value.name} className="chip" />
                                                ))}
                                            </div>)
                                        }

                                    }
                                    >
                                    {hosts.map( (host) =>(
                                        <MenuItem key={host.uid} value={host}>{host.name +"("+host.email +")"}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
}
{ (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isHost") === "true" || sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("isVendor") === "true" )&& 
                            <FormControl fullWidth={true} className="multi-select select-product-hosts form-input-field">
                                <InputLabel id="host-select-label" shrink={true} >Curator</InputLabel>
                                <Select id="product-hosts" multiple value={selectedCurator} onChange={ onCuratorChange }
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={
                                        (selected) => {
                                            
                                            return (<div className="select-chips host-chips">
                                                {selected.map( (value) => (
                                                    <Chip key={value.uid} label={value.name} className="chip" />
                                                ))}
                                            </div>)
                                        }

                                    }
                                    >
                                    {hosts.map( (host) =>(
                                        <MenuItem key={host.uid} value={host}>{host.name +"("+host.email +")"}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
}
                    <FormControlLabel className="form-input-field" control={
                    <Switch checked={state.isActive} onChange={onActiveSwitchChange} color="secondary" />
                    } label="Active" />

<FormControlLabel className="form-input-field" control={
                    <Switch checked={state.isFeatured} onChange={onFeaturedSwitchChange} color="secondary" />
                    } label="Featured Product" />
                </TabPanel>
                <TabPanel name="images" activeTab={activeTab}>
                    
                    <FirebaseFileUpload id="image2-upload" className="form-image-upload" label="Image 2" accept="image/*" name="image2" storagePath="products/" onSuccess={ (path)=>{ 
const images = state.images;
images[1] = path;

setState({...state, images: images})

                    }} preview={state.images[1]} onRemovePreview={ () => removeImage(2) }/> 
                    <FirebaseFileUpload id="image3-upload" className="form-image-upload" label="Image 3" accept="image/*" name="image3" storagePath="products/" onSuccess={ (path)=>{ 
                        const images = state.images;
                        images[2] = path;

                        setState({...state, images: images})
                    }} preview={state.images[2]} onRemovePreview={ () => removeImage(3) }/> 

<FirebaseFileUpload id="image4-upload" className="form-image-upload" label="Image 4" accept="image/*" name="image4" storagePath="products/" onSuccess={ (path)=>{ 
                        const images = state.images;
                        images[3] = path;

                        setState({...state, images: images})
                    }} preview={state.images[3]} onRemovePreview={ () => removeImage(4) }/> 


                </TabPanel>
                <TabPanel name="inventory" activeTab={activeTab}>
                    <div className="add-button-wrapper">
                        <Button variant="contained" color="secondary" className="add-button" startIcon={<AddCircleOutlineIcon />} onClick={ () => { addNewSize() } }>Add</Button>
                    </div>
                    <TableContainer >
                        <Table className="inventory-table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Inventory</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { state.size.map( (size, index) => {

                                    return (<TableRow key={index}>
                                            <TableCell className="inventory-size">
                                                <TextField type="text" className="form-input-field" placeholder="Size" id={`size-` + index} value={state.size[index].size} onChange={ (event) => { updateInventory(index, event.target.value, null)  }} />
                                            </TableCell>
                                            <TableCell className="inventory-qty">
                                                <TextField type="number" className="form-input-field" placeholder="inventory" id={`inventory-` + index} value={state.size[index].inventory} onChange={ (event) => { updateInventory(index, null, event.target.value) }} />
                                            </TableCell>
                                            <TableCell className="inventory-btn">
                                                <IconButton onClick={ () => { removeSize(index, size) }}><RemoveCircleIcon style={{color: "#ff0000" }} /> </IconButton>
                                            </TableCell>
                                            </TableRow>)

                                })}
                                
                            </TableBody>
                        </Table>
                    </TableContainer>

                </TabPanel>
                <TabPanel name="shipping" activeTab={activeTab}>

                    <h4>US Shipping</h4>
                
     
                    <TextField type="number" className="form-input-field" label="US Shipping Cost" placeholder="US Shipping Cost" id="shippingCost" value={state.shippingCost} onChange={ (event) => { setState({...state, shippingCost: parseInt(event.target.value)})}} />

                    
            
                <h4>International Shipping</h4>
                
             
                    <TextField type="number" className="form-input-field" label="International Shipping Cost" placeholder="International Shpping Cost" id="intlShippingCost" value={state.intlShippingCost} onChange={ (event) => { setState({...state, intlShippingCost: parseInt(event.target.value)})}} />       
        
             
                <div>
                    <TextField type="number" className="form-input-field" fullWidth={true} label="Parcel Width" placeholder="Parcel Width" id="boxWidth" value={state.boxWidth} onChange={ (event) => { setState({...state, boxWidth: parseInt(event.target.value)})}} />
                    <TextField type="number" className="form-input-field" fullWidth={true} label="Parcel Length" placeholder="Parcel Length" id="boxLength" value={state.boxLength} onChange={ (event) => { setState({...state, boxLength: parseInt(event.target.value)})}} />
                    <TextField type="number" className="form-input-field" fullWidth={true} label="Parcel Height" placeholder="Parcel Height" id="boxHeight" value={state.boxHeight} onChange={ (event) => { setState({...state, boxHeight: parseInt(event.target.value)})}} />
                    <TextField type="number" step="0.1" className="form-input-field" fullWidth={true} label="Parcel Weight" placeholder="Weight" id="weight" value={state.weight} onChange={ (event) => { setState({...state, weight: parseFloat(event.target.value)})}} />

                </div>
               
                </TabPanel>
                <TabPanel name="additional" activeTab={activeTab}>
                        <TextField className="form-input-field" type="text" size="small" fullWidth={true} label="SKU / Item. No" placehoder="SKU / Item No." value={state.sku} onChange={ (event) => setState({...state, sku: event.target.value})} />
                        <TextField className="form-input-field" type="number" size="small" fullWidth={true} label="Purchase Cost" placeholder="Purchase Cost" value={state.cost} onChange={ (event) => { setState({...state, cost: parseInt(event.target.value)})}} />
                        <FormControl fullWidth={true} className="select-category form-input-field" >
                        <InputLabel variant="standard" id="category-select-label" shrink={true}>Category</InputLabel>
                        <NativeSelect id="category-select" value={state.category} onChange={ (event) => { setState({...state, category: event.target.value}); } } label="Category" >
                            {categories.map( (item) =><option key={item} value={item}>{item}</option>)}
                        </NativeSelect>
                        </FormControl>
                        <FormControl fullWidth={true} className="select-condition form-input-field" >
                        <InputLabel variant="standard" id="condition-select-label" shrink={true}>Condition</InputLabel>
                        <NativeSelect id="condition-select" value={state.condition} onChange={ (event) => { setState({...state, condition: event.target.value}); } } label="Condition" >
                            {conditions.map( (item) =><option key={item} value={item}>{item}</option>)}
                        </NativeSelect>
                        </FormControl>
                        <FormControl fullWidth={true} className="select-brand form-input-field" >
                        <InputLabel variant="standard" id="brand-select-label" shrink={true}>Brands</InputLabel>
                        <NativeSelect id="brands-select" value={state.brand} onChange={ onBrandChange } label="Brands" >
                            {brands.map( (brandName) =><option key={brandName} value={brandName}>{brandName}</option>)}
                        </NativeSelect>
                        </FormControl>
                        <FormControl fullWidth={true} className="multi-select select-category form-input-field">
                            <InputLabel variant="standard" id="brand-select-label" shrink={true}>Tags</InputLabel>
                            <Select id="product-tags" multiple value={state.tags} onChange={ onTagsChange } 
                                input={<Input id="select-tag-chips" />}
                                renderValue={
                                    (selected) => (
                                        <div className="select-chips tag-chips">
                                            {selected.map(( tag) => <Chip key={tag} label={tag} className="tag-chip" />)}
                                        </div>
                                    )
                                }
                            >   
                            {tags.map (( tag ) => <MenuItem  key={tag.id} value={tag.id}>{tag.name}</MenuItem>)}
                            </Select>

                         
                        </FormControl>
                      
                        
                        
                </TabPanel>
               
    
                <div className="input-row form-actions">
                    <Button variant="contained" color="buttonDark" endIcon={ <SaveIcon /> } onClick={ updateProduct }>Save</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="buttonLight" endIcon={ <ClearIcon /> } onClick={ cancelUpdate  }>Cancel</Button>
                </div>
            </div>

            
        </ConsoleTemplate>
    )
}

export default ProductEditView

//<TextField className="form-input-field" type="number" size="small" fullWidth={true} label="Limit Per User" placeholder="Purchase Limit" value={state.limitPerUser} onChange={(event) => setState( {...state, limitPerUser: parseInt(event.target.value)})} />