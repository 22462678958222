import { createSwitchNavigator  } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import loginView from '../views/loginView'
import dashboard from '../views/dashboardView'
import eventsLanding from '../views/eventsLandingView'
import eventEdit from '../views/eventEditView'
import productsLanding from '../views/productsLandingView'
import productEdit from '../views/productEditView'
import brandsLanding from '../views/brandsLandingView'
import discountsLanding from '../views/discountsLandingView'
import orderLanding from '../views/orderLandingView'
import usersLanding from '../views/usersLandingView'
import brandEdit from '../views/brandEditView'
import orderEdit from '../views/orderEditView'
import liveEvent from '../views/liveEventView'
import storesLanding from '../views/storesLandingView'
import storeEdit from '../views/storeEditView'
import waitlistLanding from '../views/waitlistView'
import productsUpload  from '../views/productsUploadView';
import recordingsView from '../views/recordingsView';


const mainAppNavigator = createSwitchNavigator( {
    loginView,

    dashboard,
    liveEvent,
    eventsLanding,
    eventEdit,
    productsLanding,
    productEdit,
    brandsLanding,
    discountsLanding,
    orderLanding,
    usersLanding,
    brandEdit,
    orderEdit,
    storesLanding,
    storeEdit,
    waitlistLanding,
    productsUpload,
    recordingsView, 
}, {
    initialRouteName: "loginView"
    
})

export default createBrowserApp(mainAppNavigator);