
import { initializeApp } from 'firebase/app';


const firebaseConfig = {
  apiKey: "AIzaSyBPcYPscHd82Fz-QaI2a5oAr5D3PjXxFQ0",
  authDomain: "livfieapp.firebaseapp.com",
  databaseURL: "https://livfieapp.firebaseio.com",
  projectId: "livfieapp",
  storageBucket: "livfieapp.appspot.com",
  messagingSenderId: "432207423329",
  appId: "1:432207423329:web:fc19fa00746be891530720",
  measurementId: "G-NQ9LFFWCVW"
};

const firebase = initializeApp(firebaseConfig);

export default firebase;
