import React, {useState, useEffect, useRef} from 'react'
import firebase from '../components/firebase'
import ConsoleTemplate, {adminUsers} from '../components/consoleTemplate'
import { getFirestore, collection,  getDoc, getDocs,  query, where, orderBy, setDoc, addDoc, doc, runTransaction, writeBatch, FieldValue, arrayUnion} from "firebase/firestore"
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; 
import { Autocomplete, Button, Container, Input, Box, FormControl, Select, InputLabel, MenuItem, Chip, TextField, Switch, FormControlLabel } from '@mui/material'
import CSVReader from 'react-csv-reader'
import LoadingButton from '@mui/lab/LoadingButton'
import UploadIcon from '@mui/icons-material/UploadFile'


import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'


const db = getFirestore();

const ProductsUploadView = (props) => {

    const exchvngeProfile = {
        name: "Exchvnge Official",
        email: "info@livfie.com",
        uid: "pdnoNRTHIdNTk7srwhSpSyjcy542",
        image: "https://firebasestorage.googleapis.com:443/v0/b/livfieapp.appspot.com/o/profiles%2Fprofile_pdnoNRTHIdNTk7srwhSpSyjcy542_1648680250329?alt=media&token=fcc065fe-655c-4b98-87a3-d05dc966ac1d",
    };

    const defaultImage = "";
    const exchvngeStoreId = "pdnoNRTHIdNTk7srwhSpSyjcy542";

    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [events, setEvents] = useState([]);
    const [file, setFile] = useState("");
    const [isUploading,  setIsUploading] = useState(false);
    const [eventid, setEventId] = useState("");
    const [eventHost, setEventHost] = useState({});
    const [products, setProducts] = useState([]);
    const [isStoreProduct, setIsStoreProduct] = useState(false);

    const [selectedCollaborators, setSelectedCollaborators] = useState([exchvngeProfile]);
    const [selectedCurator, setSelectedCurator] = useState([]);
    const [hosts, setHosts] = useState([]);
    const [state, setState] = useState({});
   

    useEffect(() => {   
        getUserEvents(sessionStorage.getItem("uid"));
        getHosts();
    }, []);

    const getHosts = async () => {
        try {
            let hostList = [];
            const hostResult = await getDocs(query(collection(db, "profiles"), where("isActive", "==", true), orderBy("displayname")));
            console.log(hostResult);
            if(hostResult.size > 0){
                hostResult.forEach((doc) => {
                    const docData = doc.data();

                    if(docData.displayname !== ""){
                        const hostData = {
                            label: docData.displayname + " (" + doc.data().email + ")",
                            name: docData.displayname,
                            email: docData.email,
                            uid: doc.id,
                            image: docData.image,
                        };
                    
                    hostList.push(hostData);
                    }
                });
                setHosts(hostList);
            }
        } catch (err){
            console.log(err);
        }
    }

    const userInList = (host, list) => {
        let inList = -1

        for(let i=0; i<list.length; i++) {
            if(list[i].uid === host.uid) {
                return i;
            }
        }
     
        return inList;
    }

    const onAddCollaborator = (collaborator) => {
        let alreadyInList = false;
        console.log("add coloborator");
        console.log(collaborator);
        if(collaborator.uid !== ""){
            for(let i=0; i<selectedCollaborators.length; i++){
                if(selectedCollaborators[i].uid === collaborator.uid){
                    alreadyInList = true;
                    break;
                }
            }
        }
        if(!alreadyInList){
            setSelectedCollaborators([...selectedCollaborators, collaborator]);
        }

    }

    
    const onAddCurator = (curator) => {
        setSelectedCurator([curator]);
    }

    const onRemoveCurator = (curator) => {
        
        /*  const selected = selectedCurator;
         for(var i=0; i<selected.length; i++) {
             if(selected[i].uid === collaborator.uid) {
                 selected.splice(i, 1);
             }
         }
        // setState({...state, guestHosts: selected}); */
       
        setSelectedCurator([]);
        console.log("after remove");
        console.log(selectedCurator);
         
     }

     
    const onRemoveCollaborator = (collaborator) => {
        console.log("remove collaborator");
        console.log(collaborator);
        const selected = selectedCollaborators;
        for(var i=0; i<selected.length; i++) {
            if(selected[i].uid === collaborator.uid) {
                selected.splice(i, 1);
            }
        }
       // setState({...state, guestHosts: selected});
       console.log("after remove");
       console.log(selected);
       setSelectedCollaborators(selected);
       setState({});
      
        
    }

    const cancelUpdate = () => {
        props.navigation.navigate("productsLanding")
    }

    const getStorageLink = async (image) =>{
        const storage = getStorage();
        const bucket = "gs://livfieapp.appspot.com/products/store-loveless-sept2024/";
        const storageRef = ref(storage, bucket + image.toLowerCase());
        const url = await getDownloadURL(storageRef);
        console.log("url " + url);
        return url;
    }
    

    const uploadStoreProducts = async () => {
        try {
            setShowAlert(true);
            setAlertMessage("Uploading products...");
            setAlertType("info");
            setIsLoading(true);
            let productids = [];
            const productBatch = writeBatch(db);

            for(let i=0; i<products.length; i++){

                let images = [];


                if(products[i].image1 !== ""){
                    const imageUrl = await getStorageLink(products[i].image1);
                    images.push(imageUrl);
                } else {
                    images.push(defaultImage);
                }                

                if(products[i].image2 !== ""){
                    const image2Url = await getStorageLink(products[i].image2);
                    images.push(image2Url);
                }

                if(products[i].image3 !== ""){
                    const image3Url = await getStorageLink(products[i].image3);
                    images.push(image3Url);
                }

                if(products[i].image4 !== ""){
                    const image4Url = await getStorageLink(products[i].image4);
                    images.push(image4Url);
                }

                let hostids = [sessionStorage.getItem("uid")];
              
                for(let j=0; j<selectedCollaborators.length; j++){
                    if(hostids.findIndex( (host) => host === selectedCollaborators[j].uid) === -1){
                        hostids.push(selectedCollaborators[j].uid);
                    }
                }

                for(let k=0; k<selectedCurator.length; k++){
                    if(hostids.findIndex( (host) => host === selectedCurator[k].uid) === -1){
                        hostids.push(selectedCurator[k].uid);
                    }
                }


                const product = {
                    id: "",
                    sku: "",
                    name: products[i].name,
                    description: products[i].description,
                    brand: "",
                    images: [],
                    size: [{"size" : products[i].size !== "" ? products[i].size : "ONE SIZE", "inventory": parseInt(products[i].qty)}],
                    isActive: true,
                    isFeatured: false,
                    category:products[i].category,
                    condition:"",
                    events: [],
                    images: images,
                    hosts: hostids,
                    collaborators: selectedCollaborators,
                    curator: selectedCurator,
                    tags: [],
                    weight: 0.5,
                    price: parseInt(products[i].price),
                    cost: 0,
                    originalPrice: 0,
                    limitPerUser: 0,
                    boxWidth:9,
                    boxHeight: 1,
                    boxLength: 15,
                    order: 1,
                    isActive: true,
                    shippingCost: parseInt(products[i].shippingCost),
                    freeShipping: false,
                    intlFreeShipping: false,
                    intlShippingCost: parseInt(products[i].intlShippingCost),
                    owner: sessionStorage.getItem("uid"),
                    
                    createdOn: new Date(),
                    updatedOn: new Date(),
                    createdByUid: sessionStorage.getItem("uid"),
                    updatedByUid: sessionStorage.getItem("uid"),
                    createdByName: sessionStorage.getItem("displayname"),
                    updatedByName: sessionStorage.getItem("displayname"),
                }
                const productRef = doc(collection(db, "products"));
                console.log("new product " + productRef.id);
                productids.push(productRef.id);
                productBatch.set(productRef, product);
            }

            if(isStoreProduct && productids.length > 0){
                const storeRef = doc(db, "stores", exchvngeStoreId);
                productBatch.update(storeRef, {products: arrayUnion(...productids)});
            }

            productBatch.commit().then(() => {
                console.log("batch committed");
                setShowAlert(true);
                setAlertMessage(products.length + " products uploaded successfully");
                setAlertType("success");
            });
            setIsLoading(false);



        } catch (err){
            setIsLoading(false);
            console.log(err);
        }
    }

   /*  const uploadProducts = async () => {
        try {
        if(eventid === "" && !isStoreProduct){
            setShowAlert(true);
            setAlertMessage("Please select an event");
            setAlertType("error");    
        } else if(products.length === 0){
            setShowAlert(true);
            setAlertMessage("Please choose a CSV file with correct product information");
            setAlertType("error");    
        } else if(isStoreProduct) {
            setShowAlert(true);
            setAlertMessage("Uploading products...");
            setAlertType("info");
            setIsLoading(true);
            const productBatch = writeBatch(db);
            let productids = [];
            for(let i=0; i<products.length; i++){
                const product = {
                    id: "",
                    sku: "",
                    name: products[i].name,
                    description: products[i].description,
                    brand: "",
                    images: [],
                    size: [{"size" : products[i].size !== "" ? products[i].size : "ONE SIZE", "inventory": parseInt(products[i].qty)}],
                    isActive: true,
                    isFeatured: false,
                    category:products[i].category,
                    condition:"",
                    events: [],
                    images: ["https://firebasestorage.googleapis.com/v0/b/livfieapp.appspot.com/o/exchvnge-image-placeholder.png?alt=media&token=c069656d-e94c-47e5-86ae-e28dad533e5e"],
                    hosts: [sessionStorage.getItem("uid")],
                    collaborators: selectedCollaborators,
                    curator: selectedCurator,
                    tags: [],
                    weight: 0.5,
                    price: parseInt(products[i].price),
                    cost: 0,
                    originalPrice: 0,
                    limitPerUser: 0,
                    boxWidth:9,
                    boxHeight: 1,
                    boxLength: 15,
                    order: 1,
                    isActive: true,
                    shippingCost: parseInt(products[i].shippingCost),
                    freeShipping: false,
                    intlFreeShipping: false,
                    intlShippingCost: parseInt(products[i].intlShippingCost),
                    owner: sessionStorage.getItem("uid"),
                    
                    createdOn: new Date(),
                    updatedOn: new Date(),
                    createdByUid: sessionStorage.getItem("uid"),
                    updatedByUid: sessionStorage.getItem("uid"),
                    createdByName: sessionStorage.getItem("displayname"),
                    updatedByName: sessionStorage.getItem("displayname"),
                }
                const productRef = doc(collection(db, "products"));
                console.log("new product " + productRef.id);

               
                productids.add(productRef.id);
                productBatch.set(productRef, product);
            }
            const storeRef = doc(db, "stores", exchvngeStoreId);
                productBatch.update(storeRef, {products: firebase.firestore.FieldValue.arrayUnion(productids)});
        } else {
            setShowAlert(true);
            setAlertMessage("Uploading products...");
            setAlertType("info");
            setIsLoading(true);
            const productBatch = writeBatch(db);
            for(let i=0; i<products.length; i++){
              //  console.log(products[i]);
                if(products[i].type === "Buy Now"){
                    const product = {
                        id: "",
                        sku: "",
                        name: products[i].name,
                        description: products[i].description,
                        brand: "",
                        images: [],
                        size: [{"size" : products[i].size !== "" ? products[i].size : "ONE SIZE", "inventory": parseInt(products[i].qty)}],
                        isActive: true,
                        isFeatured: false,
                        category:products[i].category,
                        condition:"",
                        events: [],
                        images: ["https://firebasestorage.googleapis.com/v0/b/livfieapp.appspot.com/o/exchvnge-image-placeholder.png?alt=media&token=c069656d-e94c-47e5-86ae-e28dad533e5e"],
                        hosts: [sessionStorage.getItem("uid")],
                        collaborators: selectedCollaborators,
                        curator: selectedCurator,
                        tags: [],
                        weight: 0.5,
                        price: parseInt(products[i].price),
                        cost: 0,
                        originalPrice: 0,
                        limitPerUser: 0,
                        boxWidth:9,
                        boxHeight: 1,
                        boxLength: 15,
                        order: 1,
                        isActive: true,
                        shippingCost: parseInt(products[i].shippingCost),
                        freeShipping: false,
                        intlFreeShipping: false,
                        intlShippingCost: parseInt(products[i].intlShippingCost),
                        owner: sessionStorage.getItem("uid"),
                        
                        createdOn: new Date(),
                        updatedOn: new Date(),
                        createdByUid: sessionStorage.getItem("uid"),
                        updatedByUid: sessionStorage.getItem("uid"),
                        createdByName: sessionStorage.getItem("displayname"),
                        updatedByName: sessionStorage.getItem("displayname"),
                    }
                    const productRef = doc(collection(db, "products"));
                    console.log("new product " + productRef.id);
                    const eventRef = doc(db, "events/"+eventid+"/products", productRef.id);//collection(db, "events/"+ eventid+"/products").doc(productRef.id);
                    productBatch.set(productRef, product);
                    productBatch.set(eventRef, {productid: productRef.id, uid: sessionStorage.getItem("uid"), hosts:[sessionStorage.getItem("uid")], last_updated: new Date()}, {merge: true});
                    console.log(product);
                   // const productRef = await addDoc(collection(db, "products"), product);
                 //   await addDoc(collection(db, "events/"+ eventid+"/products/"+productRef.id), {productid: product.id, uid: sessionStorage.getItem("uid"), hosts:[sessionStorage.getItem("uid")], last_updated: new Date()}, {merge: true});
                    
                } else if(products[i].type == "Auction"){
                    let host = {};
                    for(let i=0; i<events.length; i++){
                        if(events[i].id === eventid && events[i].host !== sessionStorage.getItem("uid")) {
                          host = {
                                uid: events[i].host,
                                name: events[i].hostname,
                                email: events[i].hostEmail,
                                image: events[i].hostAvatar,
                            }
                        }
                    }

                    const product ={
                        name: products[i].name,
                        description: products[i].description,
                        isActive: false,
                        eventid: eventid,
                        collaborators: host != {} ? [host] : [],
                        currentBid: 0,
                        duration:0,
                        start: null,
                        end: null,
                        processPayment: false,
                        verifiedOnly: false,
                        autoExtend: false,
                        usShipping: parseInt(products[i].shippingCost),
                        intlShipping: parseInt(products[i].intlShippingCost),
                        size: products[i].size,
                        status: "prepare",
                        bidIncrement: 1,
                        bids: [],
                        startPrice: parseInt(products[i].price),
                        uid: sessionStorage.getItem("uid"),
                        createdByName: sessionStorage.getItem("displayname"),
                        createdBy: sessionStorage.getItem("uid"),
                        createdByEmail: sessionStorage.getItem("email"),
                        createdOn: new Date(),
                        lastUpdated: new Date(),
                    };

                    const auctionRef = doc(collection(db, "event-auctions"));
                    productBatch.set(auctionRef, product);

                    console.log(products[i]);
                }
                
                
                
            }
            productBatch.commit().then(() => {
                console.log("batch committed");
                setShowAlert(true);
                setAlertMessage(products.length + " products uploaded successfully");
                setAlertType("success");
            });
            setIsLoading(false);
        }
    } catch(err){
        setIsLoading(false);
        console.log(err);
    }
    } */

    const getUserEvents = async (uid) =>{
        try {
        let userEvents = [];
        const eventResult = await getDocs(query(collection(db, "events"), where("isActive", "==",true), orderBy("eventDate", "desc")));
            if(eventResult.size > 0){
                eventResult.forEach((doc) => {
                    const eventData = doc.data();
                    eventData.id = doc.id;

                    for(let i=0; i< eventData.guestHosts.length; i++){
                      //  console.log(eventData.guestHosts[i].uid);
                        if(eventData.host === uid || eventData.guestHosts[i].uid === uid){
                            userEvents.push(eventData);
                            //return true;
                        }
                    }
                    
                });
                setEvents(userEvents);
               // console.log(userEvents);
            }
        } catch (err){
            console.log(err);
        }
    }

    const readCSV = async (file) => {
       
       if(typeof file === "object"){

        if(file.length > 0){
            console.log("file length " + file.length);
            let products = [];
            for(let i=1; i<file.length; i++){
                let product = file[i];
               // if(product.length === 9){
                let newProduct = {  
                    category: product[0],
                    name: product[1],
                    description: product[2],
                    size: product[3],
                    qty: product[4],
                    price: product[5],
                    shippingCost: product[6],
                    intlShippingCost: product[7],
                    image1: product[8],
                    image2: product[9],
                    image3: product[10] ,
                    image4: product[11],
                }
                products.push(newProduct);
               // }
            }
            setProducts(products);
            console.log("no of products " + products.length);
        }
       } else {
        console.log("not array " + typeof file);
       } 
    } 


    return  <ConsoleTemplate {...props} isLoading={isLoading } tab={2} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage} >
        <h2>Upload Products</h2>
        <div className="products-upload-form">
        <form encType='multipart/form-data'>
            <Box sx={{marginTop:"10px", marginBottom:"10px"}}className={props.className}>

           
                <label htmlFor="products-file" className="form-image-upload-label"> Upload a CSV file (<a target="_blank" href="https://docs.google.com/spreadsheets/d/1AQkHiVCzMIFrmVuSZ5A2qGydPDhGDs6EwmWXEK75fTU/edit#gid=0">Download CSV Template</a>)</label>

                <CSVReader onFileLoaded={(data, fileInfo, originalFile) => readCSV(data)} />
                <div className="csv-result">{products.length >0 ? products.length + " products found" : ""}</div>

            </Box>
        </form>
        {/* <FormControl sx={{width: 200}}>
            <InputLabel id="demo-simple-select-label">Event</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={eventid}
                label="Event"
                onChange={ (e) => {
                    setEventId(e.target.value);
                }}
            >
                {
                    events.map( (event, index) => {
                        return <MenuItem key={index} value={event.id}>{event.name}</MenuItem>
                    })
                }
               
            </Select>
        </FormControl> */}
        
        <FormControlLabel className="form-input-field" control={
                    <Switch onChange={ (e) => {
                        setIsStoreProduct(e.target.checked);
                    }}  checked={isStoreProduct} color="secondary" />
                } label="Add to Exchvnge Store" />
        { /* collaborators */}            
        <Autocomplete fullWidth={true} id="host" options={hosts} autoSelect={true} autoHighlight={true} openOnFocus={true}
                    value={selectedCollaborators.length > 0 ? selectedCollaborators[0].name +"(" + selectedCollaborators[0].email+")" : ""}
                    renderOption = { (props, option, state) => <li {...props} key={option.id} >{option.name + "(" + option.email +")"}</li>}
                    renderInput = { (params) => <TextField className="form-input-field" {...params} label="Collaborator" variant="outlined" inputProps={{...params.inputProps}} />}
                    onChange={ (event, value, reason, details) => { 
                        if(value !== null ) {
                        onAddCollaborator({ 
                            uid: value.uid,
                            name: value.name,
                            image: value.image,
                            email: value.email,
                        });
                    }
                    
                    }}
                />
                        <div className="guest-host-chips">
                            {selectedCollaborators.map( (collab) => {
                                    return <Chip key={collab.uid} label={collab.name + " (" + collab.email + ")"} variant="filled" className="chip" onDelete={ () => onRemoveCollaborator(collab)}/> 
                                })                            
                            }
    
    </div>  
    <br /><br />
                            {/* Curator */}
                            <Autocomplete fullWidth={true} id="host" options={hosts} autoSelect={true} autoHighlight={true} openOnFocus={true}
                    value={selectedCurator.length > 0 ? selectedCurator[0].name +"(" + selectedCurator[0].email+")" : ""}
                    renderOption = { (props, option, state) => <li {...props} key={option.id} >{option.name + "(" + option.email +")"}</li>}
                    renderInput = { (params) => <TextField className="form-input-field" {...params} label="Collaborator" variant="outlined" inputProps={{...params.inputProps}} />}
                    onChange={ (event, value, reason, details) => { 
                        if(value !== null ) {
                        onAddCurator({ 
                            uid: value.uid,
                            name: value.name,
                            image: value.image,
                            email: value.email,
                        });
                    }
                    
                    }}
                />
                        <div className="guest-host-chips">
                            {selectedCurator.map( (collab) => {
                                    return <Chip key={collab.uid} label={collab.name + " (" + collab.email + ")"} variant="filled" className="chip" onDelete={ () => onRemoveCurator(collab)}/> 
                                })                            
                            }
    
    </div>  
           

        <div className="input-row form-actions">
                    <Button variant="contained" color="buttonDark" endIcon={ <SaveIcon /> } onClick={ uploadStoreProducts }>Import Products</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="buttonLight" endIcon={ <ClearIcon /> } onClick={ cancelUpdate  }>Cancel</Button>
                </div>
        </div>
    </ConsoleTemplate>
}

export default ProductsUploadView;