import React, {useState, useEffect, forwardRef} from 'react'
import ConsoleTemplate, { adminUsers } from '../components/consoleTemplate'

import {  Button, IconButton, Fab} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { getFirestore, collection,  getDocs, query,  orderBy,} from "firebase/firestore";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import firebase from '../components/firebase'

const db = getFirestore();






const BrandsLandingView = (props) =>{

    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");

    useEffect( () => {
        if (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true") {
        loadBrands();
        }
    }, [])

    const loadBrands = async () => {
        setIsLoading(true)
        try {
        const collectionRef = collection(db, "brands");
        const result = await getDocs(query(collectionRef, orderBy("name", "asc")));

        if (result.size > 0) {
            const docs = result.docs;
            const loadedItems = [];
            docs.forEach( (item) => {
                const obj = item.data();
                obj.id = item.id;
                loadedItems.push(obj)
            });

            setBrands(loadedItems);
        
        }
        }catch (err) {
            console.error("Error loading brands");
            console.log(err);
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    const onBrandClick = (brandid) => {
        //console.log("edit brand " + brandid)
       
        props.navigation.navigate("brandEdit", {id: brandid});
    }

    return (
        <ConsoleTemplate {...props} isLoading={isLoading} tab={3} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Brands</h2>
            
              { (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isHost") === "true" || sessionStorage.getItem("isVendor") === "true" || sessionStorage.getItem("isAdmin") === "true")&& 
            <Fab color="secondary" size="large" variant="extended" onClick={()=> onBrandClick(null)}>
                <AddCircleOutlineIcon /> &nbsp;Add
            </Fab>
         
            }
            
            <div className="brands-table data-table">
                <DataGrid
                    pageSize={20}
                    autoHeight={true}
                    rowsPerPageOptions={[20]}
                    columns={ [
                        { field: "actions", headerName: "Action", type:"actions", flex: 1,  headerClassName: "data-table-header", 
                                renderCell: (data) =>  <IconButton aria-label="Manage Brand" onClick={ () => { onBrandClick(data.id)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>},
                        {field: "name", headerName: "Name", flex: 3, headerClassName:"data-table-header"},
                        {field: "categories", headerName: "Categories", flex:1, headerClassName:"data-table-header"},
                        {field: "isActive", headerName:"Active", flex:1, headerClassName:"data-table-header", renderCell: (data) => data.row.isActive ? <CheckCircleIcon fontSize="small"/> :  <CancelIcon color="disabled" fontSize="small"/> },
                        

                    ]}
                    rows={brands}

                />

            </div>


           </ConsoleTemplate>
    )
}

export default BrandsLandingView