import React, { useEffect } from 'react'
import { Container, Snackbar, Alert } from '@mui/material'
import Header from './header'
import LoadingIndicator from './loadingIndicator'
import { getAuth, signOut } from 'firebase/auth';


export const adminUsers = []  //"76d4400d2720ac05ae66120a456c20b5", "457e278091380ab55488c20a03b50590", "7b678c16a018b3fa8bd3a71034edc9ba", "15f5bf3fcf1f2c3e9004e30430a596fb"
export const imagePlaceholder = "https://firebasestorage.googleapis.com/v0/b/livfieapp.appspot.com/o/exchvnge-image-placeholder.png?alt=media&token=c069656d-e94c-47e5-86ae-e28dad533e5e";
export const orderStatus = ["Processing", "Awaiting Payment", "Paid", "Shipped", "Completed", "On Hold", "Returned", "Refunded", "Cancelled", "Merged"];

const auth = getAuth();

const ConsoleTemplate = (props) => {

    const isAdmin = adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true" ? true : false ;
    
    useEffect( () => {
        if(!sessionStorage.getItem("uid")) {
            logout();
        }
    }, []);
    
    const logout = async () => {
        await sessionStorage.clear();
        await signOut(auth);

        props.navigation.navigate("loginView");
    }

    return (
        <Container maxWidth={false} >
            <Container maxWidth={false} className="App">
                <Header {...props} admin={isAdmin}/>
                
                {props.children}
            </Container>
            
            <Snackbar open={props.showAlert} autoHideDuration={3000} onClose={ ()=>props.setShowAlert(false)}>
                <Alert variant="filled" severity={props.alertType}>{props.alertMessage}</Alert>
            </Snackbar>
            <LoadingIndicator visible={props.isLoading} />
        </Container>
    )
}

export default ConsoleTemplate;


