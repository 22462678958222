import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import ConsoleTemplate, {adminUsers} from '../components/consoleTemplate';
import {IconButton, Fab} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid'
import { getFirestore, collection,  getDocs, query, where, orderBy, Timestamp } from "firebase/firestore";


import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import firebase from '../components/firebase'

import moment from 'moment'






const StoresLandingView = (props ) => {
    const history = useHistory();
    const db = getFirestore();
    
    const [stores, setStores] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");

    useEffect( () => {
        const getStores = async () => {
            setIsLoading(true);
            try {
            const storesResult = await getDocs(collection(db, "stores"));
            
            if(storesResult.size > 0){
                const loadedStores = [];
                storesResult.docs.forEach( (doc) =>{
                    const shop = doc.data();
                    shop.id = doc.id;
                    shop.docid = doc.id;
                    loadedStores.push(shop);
                })
                //console.log(loadedStores);
                setStores(loadedStores);
            }
            
            }catch(err){
                console.error("Error loading shops", err);
                setAlertType("error");
                setAlertMessage("Unable to load shops");
                setShowAlert(true);        
            }
            setIsLoading(false);
        }
        getStores();
    }, [db]);

    
    
    const onStoreClick = (storeid) => {
        props.navigation.navigate("storeEdit", {id: storeid});
    }

    return <ConsoleTemplate {...props} isLoading={isLoading} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Stores (Coming Soon in Livfie V2)</h2>
            { adminUsers.includes(sessionStorage.getItem("hash") ?? "") && 
            <Fab color="secondary" size="large" variant="extended" onClick={()=> onStoreClick("")}>
                <AddCircleOutlineIcon /> &nbsp;Add
            </Fab>
           
            }
             <div className="stores-table data-table">
                <DataGrid pageSize={20}
                    rows={stores}
                    columns={[
                        {field: "name", headerName: "Event Name", flex:3, headerClassName:"data-table-header"},
                        {field: "hostname", headerName: "Host", flex:2, headerClassName:"data-table-header"},
                        { field: "actions", headerName: "Action", type:"actions", flex: 1,  headerClassName: "data-table-header", 
                        renderCell: (store) => {
                            console.log(store);
                            return <IconButton aria-label="Manage Event" onClick={ () => { onStoreClick(store.row.id)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>
            
                        }
                        }
                    ]}
                    autoHeight={true}
                     rowsPerPageOptions={[20]}
                />
            </div>
        </ConsoleTemplate>
}

export default StoresLandingView;