import React, {useState, useEffect} from 'react'

import firebase from '../components/firebase'
import { getFirestore,  doc, getDoc, } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Container,  Button,  Input,  InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputIcon from '@mui/icons-material/Input'

import Header from '../components/header'
import {adminUsers} from '../components/consoleTemplate'


var md5 = require("md5");
const db = getFirestore();
const auth = getAuth();


const LoginView = (props) => {

    const [username, setUsername] = useState(""); 
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const [showPassord, setShowPassord] = useState(false);


    

    useEffect( ()=> {
        sessionStorage.clear();
       
    }, [])

    

    const loginWithUsername = async (user, pass) => {
        //console.log(user, pass);
        setLoginError("");
        try {
            const loginResult = await signInWithEmailAndPassword(auth, username, password);
            
            if (loginResult.user) {
              //  console.log("logged in " );
               // console.log(loginResult.user);
                const profileResult = await getDoc(doc(db, "profiles", loginResult.user.uid));
                //await firebase.firestore().collection("profiles").doc(loginResult.user.uid).get();

                
                if (profileResult.exists()) {
                    const profile =  profileResult.data();
                    const hash =  md5(loginResult.user.uid);
                   // console.log("profile");
                    //console.log(profile);
                    
                    

                    if(profile.isVendor === true || profile.isAdmin === true || profile.isHost === true || adminUsers.includes(hash)) {
                        sessionStorage.setItem("uid", loginResult.user.uid);
                        sessionStorage.setItem("displayname", profile.displayname);
                        sessionStorage.setItem("email", loginResult.user.email);
                        sessionStorage.setItem("phone", loginResult.user.phoneNumber);
                        sessionStorage.setItem("isHost", profile.isHost);
                        sessionStorage.setItem("isAdmin", profile.isAdmin);
                        sessionStorage.setItem("isVendor", profile.isVendor);
                        sessionStorage.setItem("isVerified", profile.isVerified ?? false);
                        sessionStorage.setItem("badge", profile.badge ?? "");
                       
                        sessionStorage.setItem("avatar", (profile.image !== null && profile.image !== "" )  ?  profile.image :  "https://firebasestorage.googleapis.com/v0/b/livfieapp.appspot.com/o/exchvnge-image-placeholder.png?alt=media&token=c069656d-e94c-47e5-86ae-e28dad533e5e");
                        sessionStorage.setItem("hash", hash);
                        props.navigation.navigate("dashboard");
                    } else {
                        setLoginError("You do not have access to the console.")
                    }
                }

                
            }
            
        } catch (err) {
            //console.log(err.message);
            setLoginError("There was a problem with your details. Please check and try again.");
        }
    }

    const handleClickShowPassword = () => {
        setShowPassord(!showPassord);
    }


    return (
        <Container maxWidth="xl" className="App loginForm" >
        <Container maxWidth="md" >
       
        <Header />
         <h2>
            Login to Exchvnge° Console
         </h2>

        <Input type="email" className="textfield" placeholder="Username" id="username" required value={username} onChange={ (event) => { setUsername(event.target.value)}} /> <br />

        <Input type= { showPassord ? "text" : "password" } className="textfield" placeholder="Password" id="password" required value={password} onChange={ (event) => { setPassword(event.target.value)}} endAdornment={ 
            <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} >
                    { showPassord ? <VisibilityOff /> : <Visibility /> }
                </IconButton>
            </InputAdornment>
         } />
        <br />
        
         <Button variant="contained" color="primary" endIcon={ <InputIcon /> } onClick={ ()=>{ 
             loginWithUsername( username, password)
         } }>
            Login
        </Button>
         <div className="form-error">
            { loginError }
         </div>
         <p><a href="https://host.exchvnge.co" target="_host" rel="noreferrer">Login to Host Portal</a></p>
        </Container>
        </Container>
    )
}

export default LoginView