import React, {useState} from 'react'
import {  ImageListItem, ImageListItemBar, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import {imagePlaceholder} from "../components/consoleTemplate"
import { getFirestore, collection,  getDoc, getDocs, query, where, orderBy, setDoc, addDoc, doc} from "firebase/firestore";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FirebaseFileUpload from '../components/firebase-fileupload';

const db = getFirestore();

const LiveEventProductItem = (props) => {

    const [name, setName] = useState(props.name);
    const [price, setPrice] = useState(props.price);
    const [description, setDescription] = useState(props.subtitle);
    const [image, setImage] = useState(props.image);
    const [editProductOpen, setEditProductOpen] = useState(false);

    
    const updateProduct = async () => {
        try {
            const productObj = {
                name: name,
                description: description,
                image1: image,
                updatedOn: new Date(),
                updatedByUid: sessionStorage.getItem("uid"),
                updateByName: sessionStorage.getItem("name"),
                
            }
            props.price && (productObj.price = parseInt(price));
            await setDoc(doc(db, "products", props.id), productObj, {merge:true});
            console.log("saved " + props.id);
            props.onUpdate && props.onUpdate();
            setEditProductOpen(false);
        }catch(err){
            console.log("Unable to update product");
            console.error(err);
        }
    }

    const removeImage = () => {
        setImage(imagePlaceholder);
    }
    return (
        <div>
        <ImageListItem productid={props.id} key={props.key} isAuctionProduct={props.isAuction}  sx={{overflow:"hidden"}} {...props}  >
            <img src={image} alt={name} style={{height:props.height??"150px"}} />
            <ImageListItemBar className="product-list-item"
                title={<div>{props.name }
                 <IconButton onClick={ () => props.onEdit(props.id, props.isAuction) } ><EditRoundedIcon sx={{color:"#fff"}} /></IconButton>
                </div> } 
                subtitle={
                    <div>
                        {props.subtitle}
                    <br />
                    {props.isAuction !== true &&
                    <div>
                    Price: { (parseInt(props.price) === 0 ) ? "FREE" : parseInt(props.price) === -1 ? "N/A" : <span>${props.price}</span> }<br />
                    </div>}
                   
                    Local Shipping: {parseInt(props.localShipping) === 0  ? "FREE" : parseInt(props.localShipping) === -1 ? "N/A" : <span>${props.localShipping}</span>} <br />
                    International Shipping: {parseInt(props.intlShipping)===0 ? "FREE" : parseInt(props.intlShipping) === -1 ? "N/A"  : <span>${props.intlShipping}</span>}
                    
                    
                    </div>
                    } position="bottom"
                actionIcon={props.action}
            >
            <div className="price-banner">
                {"$" + props.price}
            </div>
               
            </ImageListItemBar>
        </ImageListItem>
        
        </div>
    )
}

export default LiveEventProductItem;