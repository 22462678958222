import React, {useState, useEffect, forwardRef} from 'react';
import ConsoleTemplate, {adminUsers} from '../components/consoleTemplate'



import moment from 'moment'

import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete';

import { Box, Fab, Button, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel, TextField, Switch, InputAdornment} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { getFirestore, collection,  doc, getDocs, query,  orderBy, setDoc, addDoc, Timestamp} from "firebase/firestore";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import firebase from '../components/firebase'

const db = getFirestore();


const DiscountsLandingView = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [coupons, setCoupons] = useState([])
    const [showCoupon, setShowCoupon] = useState(false);
    const [activeCoupon, setActiveCoupon] = useState(null);

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleteCouponCode, setDeleteCouponCode] = useState("");
    const [deleteCouponid, setDeleteCouponid] = useState("");
    const [couponsUsed, setCouponsUsed] = useState([]);

    useEffect ( () => {
        if (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true" ) {
            getCoupons();
        }
    }, []);


    

    const getCoupons = async () => {
        setIsLoading(true);
        try {
        
               const collectionRef = collection(db, "coupons");
               const result = await getDocs(query(collectionRef, orderBy("startDate", "asc")));
       
              
                if(result.empty !== true) {
                    const docs = result.docs;
                    const loadedCoupons = [];
                    docs.forEach( (coupon) => {
                        const obj = coupon.data();
                        obj.id = coupon.id;
                        loadedCoupons.push(obj);
                    });
                    console.log(loadedCoupons);
                    setCoupons(loadedCoupons);
                }

        }catch(err) {
            console.error("Error loading events " + err.message);
            setIsLoading(false);
        }

        setIsLoading(false);
    }

    const showCouponDetails = ( coupon ) => {
     //   console.log(coupon);
        setActiveCoupon(coupon);
    
        setShowCoupon(true);
    }

   
    const onCouponClose = () => {
        setShowCoupon(false);
        setActiveCoupon(null);
        getCoupons();
    
    }

   

    const deleteCoupon = async (couponid) => {
       await firebase.firestore().collection("coupons").doc(couponid).delete();
       setShowConfirmDelete(false);
       getCoupons();
    }

    const confirmDeleteCoupon = (couponid, couponname) => {
        console.log("delete coupon " + couponid);
        setDeleteCouponCode(couponname);
        setDeleteCouponid(couponid);
        setShowConfirmDelete(true);
    }

   


    return <ConsoleTemplate {...props} isLoading={isLoading}  showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Discounts and Coupons</h2>
           
             { (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isHost") === "true"  || sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("isVendor") === "true"  )&& 
            <Fab color="secondary" size="large" variant="extended" onClick={()=> showCouponDetails(null)}>
                <AddCircleOutlineIcon /> &nbsp;Add
            </Fab>
         
            }

        <div className="products-table data-table">
                <DataGrid 
                    pageSize={20}
                    autoHeight={true}
                    rowsPerPageOptions={[20]}
                    columns={ [
                        { field: "actions", headerName: "Action", type:"actions", flex: 1,  headerClassName: "data-table-header", 
                        renderCell: (data) =>  <IconButton aria-label="Manage Brand" onClick={ () => { showCouponDetails(data.row)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>},
                        {field: "name", headerName: "Name", flex: 3, headerClassName:"data-table-header"},
                        {field: "value", headerName: "Discount", flex:1, headerClassName: "data-table-header", valueFormatter: (data) => data.row.isPercent ? data.row.value+"% OFF" : "$"+data.row.value +" OFF" },
                        {field: "totalUsed", headerName: "Usage", flex: 1, headerClassName: "data-table-header", valueFormatter: (data) => data.row.totalUse+"/"+data.row.limit},
                        {field: "isActive", headerName:"Active", flex:1, headerClassName:"data-table-header", renderCell: (data) => data.row.isActive ? <CheckCircleIcon fontSize="small"/> :  <CancelIcon color="disabled" fontSize="small"/> },
                       

                    ]}

                    rows={coupons}

                />

            
            </div>

            
            <CouponDialog open={showCoupon} coupon={activeCoupon} onClose={ onCouponClose } setShowAlert={setShowAlert} setAlertType={setAlertType} setAlertMessage={setAlertMessage} setIsLoading={setIsLoading}/>
            <ConfirmDeleteDialog open={showConfirmDelete} onClose={ () => setShowConfirmDelete(false)} onConfirm={ () => deleteCoupon(deleteCouponid)} couponcode={deleteCouponCode} couponid={deleteCouponid} />
        </ConsoleTemplate>
    
}

export default DiscountsLandingView;

const CouponDialog = (props) => {

    const [couponState, setCouponState] = useState({
        name: "",
        startDate: new Date(),
        endDate: new Date(),
        value: 0,
        isPercent: false,
        isActive: false,
        limit: 0,
        limitPerUser :1,
        totalUse: 0,
    });

    useEffect( () => {
      //  console.log("coupon: ");
       // console.log(props.coupon); 
        if(props.coupon !== null) {

        

        setCouponState( {
            
            name: props.coupon !== null && props.coupon.name !== null ? props.coupon.name : "",
            startDate: props.coupon !== null && props.coupon.startDate !== null ? new Date(props.coupon.startDate.toDate()) : new Date(),
            endDate: props.coupon !== null && props.coupon.endDate !== null ? new Date(props.coupon.endDate.toDate()) : new Date(),
            value: props.coupon !== null && props.coupon.value !== null ? props.coupon.value : 0,
            isPercent: props.coupon !== null && props.coupon.isPercent !== null ? props.coupon.isPercent : false,
            isActive: props.coupon !== null && props.coupon.isActive !== null ? props.coupon.isActive : false,
            limit: props.coupon !== null && props.coupon.limit !== null ? props.coupon.limit : 0,
            limitPerUser: props.coupon !== null && props.coupon.limitPerUser !== null? props.coupon.limitPerUser : 1,
            totalUse: props.coupon !== null && props.coupon.totalUse !== null ? props.coupon.totalUse : 0,
        });

        

        } else {
            setCouponState( 
                {
                    name: "",
                    startDate: new Date(),
                    endDate: new Date(),
                    value: 0,
                    isPercent: false,
                    isActive: false,
                    limit: 0,
                    limitPerUser :1,
                    totalUse: 0,
                }
            )
        }

        

    }, [props.coupon])
    

    const checkCouponUsed = async (coupon) => {
        const uid = "fYaEMLvLhIfszxQu0oc2JkftgrQ2";
        const usedResult = await firebase.firestore().collection("orders").where("appliedCoupon", "==", coupon).get();
        console.log(usedResult);

      //  setCouponState({...couponState, totalUse: usedResult.size });
    }

    const saveCoupon = async () => {
        props.setIsLoading(true);
        try {
            
            const couponid = props.coupon !== null ? props.coupon.id : 0;
            console.log("save coupon" + couponid);


           // const firestore = firebase.firestore();

            const couponObj = couponState;
            couponObj.updatedByName = await sessionStorage.getItem("displayname");
            couponObj.updatedUid = await sessionStorage.getItem("uid");
            couponObj.updatedOn = Timestamp.fromDate(new Date());

            //console.log("Save " + couponid);
            //console.log(couponObj);

            if(couponid !== 0 ) {
                
                // await firestore.collection("coupons").doc(couponid).set(couponObj, {merge: true});
                await setDoc(doc(db, "coupons", couponid), couponObj, {merge: true});
            } else {
                couponObj.createdBy = await sessionStorage.getItem("displayname");
                couponObj.createdByUid = await sessionStorage.getItem("uid");
                couponObj.createdOn = Timestamp.fromDate(new Date());
               // await firestore.collection("coupons").add(couponObj);
              // await setDoc(doc(db, "coupons", couponid), couponObj, {merge: true});
              await addDoc(collection(db, "coupons"), couponObj);

            }
            props.setIsLoading(false);
            props.onClose();


        } catch( err) {
            console.log(err);
            props.setShowAlert(true);
            props.setAlertType("error");
            props.setAlertType(err.message);
            props.setIsLoading(false);
        }
    }
 

    return <LocalizationProvider dateAdapter={AdapterMoment}>
    <Dialog open={props.open} onClose={props.onClose} className="dialog discount-dialog"  maxWidth="sm" >
        <DialogTitle>Coupon {couponState.name !== "" ? " - " : ""}{couponState.name}</DialogTitle>
        <DialogContent>
        <Box sx={{marginTop:"10px"}}>
        <TextField fullWidth={true} type="text" className="form-input-field" label="Coupon Code" placeholder="Coupon Code" id="name" required value={couponState.name} onChange={ (event) => { setCouponState({...couponState, name: event.target.value.toUpperCase()})}} />
        
        <div className="input-row">
                <label className="field-label">Active</label>
            <FormControlLabel className="form-input-field" control={
                <Switch color="secondary" onChange={() => setCouponState({...couponState, isActive: !couponState.isActive})} checked={couponState.isActive}  />
            } label="Active" />

        </div> 
        
                <DateTimePicker
                    renderInput={(props) => <TextField className="form-input-field" fullWidth={true} {...props} />}
                    label="Start Date"
                    value={couponState.startDate}
                    onChange={(newDate) => {
                        setCouponState({...couponState, startDate: newDate.toDate()})}
                    }
                />
               
       
        
            <DateTimePicker
                    renderInput={(props) => <TextField className="form-input-field" fullWidth={true} {...props} />}
                    label="End Date"
                    value={couponState.endDate}
                    onChange={(newDate) => {
                        setCouponState({...couponState, endDate: newDate.toDate()})}
                    }
                />
         
        
        <TextField fullWidth={true} type="number" className="form-input-field" label="Discount" placeholder="Discount" id="value" required value={couponState.value} onChange={ (event) => { setCouponState({...couponState, value: event.target.value})}} 
            InputProps={{endAdornment: <InputAdornment position="end" >
                    <Button variant="outlined" onClick={ () => { 
                        setCouponState({ ...couponState, isPercent: !couponState.isPercent})
                    } }>{couponState.isPercent=== true ? "% off" : "$ off"}</Button>
                </InputAdornment>}} />
        
        
      
       
        
                <TextField fullWidth={true}  type="number" className="form-input-field" label={"Usage Limit (used " + couponState.totalUse +" times )"} placeholder="Total Limit" id="limit" required value={couponState.limit} onChange={ (event) => { setCouponState({...couponState, limit: event.target.value})}} />
        
        
      
                <TextField fullWidth={true} type="number" className="form-input-field" label="Limit Per User" placeholder="Limit per user" id="limit" required value={couponState.limitPerUser} onChange={ (event) => { setCouponState({...couponState, limitPerUser: event.target.value})}} />
        
        </Box>
        </DialogContent>
        <DialogActions>
        <Button className="black-button" onClick={saveCoupon}>Save</Button> &nbsp;&nbsp;
        <Button className="black-button" onClick={props.onClose}>Close</Button>
        </DialogActions>
    </Dialog>
    </LocalizationProvider>
}

const ConfirmDeleteDialog = (props) => {
    
    return <Dialog open={props.open} onClose={props.onClose} >
        <DialogTitle>Are you sure you want to delete coupon {props.couponcode}?</DialogTitle>
        <DialogActions>
            <Button onClick={props.onConfirm} className="black-button">Confirm</Button>
            <Button onClick={props.onClose} color="default">Cancel</Button>
        </DialogActions>
    </Dialog>
}