import React, {useState, useEffect} from 'react'
import ConsoleTemplate, {adminUsers} from '../components/consoleTemplate'
import {   IconButton, Fab, FormControlLabel, Switch} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { getFirestore, collection,  getDocs, query, where, orderBy, Timestamp } from "firebase/firestore";


import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import firebase from '../components/firebase'

import moment from 'moment'
const db = getFirestore();

const EventsLandingView = (props) =>{

    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [showAll, setShowAll] = useState(false);


    useEffect( ()=>{
        getEvents();
       
    }, [])

    const getEvents = async () => {
        setIsLoading(true)
        try {
        
        const collectionRef = collection(db, "events");
        var q;


        if (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true") {
           // result = await firestore.collection("events").orderBy("eventDate", "desc").get();
           q = query(collectionRef, orderBy("eventDate", "desc"));
        } else {
            q = query(collectionRef, where("host", "==", sessionStorage.getItem("uid"), orderBy("eventDate", "desc")));
            //result = await firestore.collection("events").where("host", "==", sessionStorage.getItem("uid")).orderBy("eventDate", "desc").get();
        }
        
        const result = await getDocs(q);

        if (result.size > 0) {
            const docs = result.docs;
            const loadedEvents = [];
            docs.forEach( (event) => {
                const eventObj = event.data();
                eventObj.id = event.id;
                loadedEvents.push(eventObj)
            });

            setEvents(loadedEvents);
        }
        }catch (err) {
            console.error("Error loading events");
            console.log(err);
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    const onEventClick = (eventid) => {
        console.log("update event " + eventid)
        props.navigation.navigate("eventEdit", {id: eventid});
    }

    return (
        <ConsoleTemplate {...props} isLoading={isLoading} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
           
            <h2>Events</h2>
           
          

        { (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true" || sessionStorage.getItem("isHost") === "true")&& 
            <Fab color="secondary" size="large" variant="extended" onClick={()=> onEventClick(null)}>
                <AddCircleOutlineIcon /> &nbsp;Add
            </Fab>
         
            }

<FormControlLabel control={
                <Switch className="form-input-field" name="showall" checked={showAll} onChange={ (e) => setShowAll(!showAll)} color="secondary" />
            } label="Show All" />

            <div className="events-table data-table">
                <DataGrid 
                     pageSize={20}
                     autoHeight={true}
                     rowsPerPageOptions={[20]}
                     columns={ [
                        { field: "actions", headerName: "Action", type:"actions", flex: 1,  headerClassName: "data-table-header", 
                        renderCell: (event) => {
                          
                            return <IconButton aria-label="Manage Event" onClick={ () => { onEventClick(event.id)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>
            
                        }},
                        {field: "name", headerName: "Event Name", flex:3, headerClassName:"data-table-header"},
                        { field: "eventDate", headerName:"Date", type:"dateTime", flex:2, headerClassName:"data-table-header", valueFormatter: (event) => { 
                            const timestamp = new Timestamp(event.value.seconds,event.value.nanoseconds);
                            return moment(timestamp.toDate()).format("MM/DD/YYYY h:mm a");
                        }},
                        {field: "hostname",headerName: "Host", flex:2, headerClassName:"data-table-header"},
                        {field:"products", headerName: "No. of Products", flex: 2, headerClassName:"data-table-header", valueFormatter: (event) => event.row.products.length },
                        {field: "tags", headerName: "Tags", flex: 2, headerClassName:"data-table-header"},
                        
                        
                     ]} 
                     rows={events.filter( (event) => showAll? true : event.isActive || event.isTest)}
                />

                
            </div>

            
        </ConsoleTemplate>
    )
}

export default EventsLandingView