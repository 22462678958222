import React, {useState, useEffect, forwardRef} from 'react'
import ConsoleTemplate, {adminUsers, orderStatus} from '../components/consoleTemplate'

import firebase from '../components/firebase'
import { Button, IconButton, Fab, NativeSelect, FormControl, InputLabel, Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel, Switch} from '@mui/material'
import { getFirestore, collection,  getDocs, query, where, orderBy, Timestamp, setDoc, doc, runTransaction, writeBatch, addDoc } from "firebase/firestore";
import { DataGrid } from '@mui/x-data-grid';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DownloadingIcon from '@mui/icons-material/DownloadingOutlined';

import moment from 'moment'
//import { off } from 'process';


import {saveAs} from 'file-saver'

const db = getFirestore();

const OrderLandingView = (props) => {

    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [showOrder, setShowOrder] = useState("");
    const [orderRowsSelected, setOrderRowsSelected] = useState([]);
    const [newStatus, setNewStatus] = useState("Processing");
    const [statusToDownload, setStatusToDownload] = useState("Processing");
    const [confirmMergeOpen, setConfirmMergeOpen] = useState(false);
    const [showAll, setShowAll] = useState(false);
   

    useEffect ( () => {
        loadOrders();
    },[])

    const loadOrders = async () => {
        try {
            setIsLoading(true);
            
            var orderResult;
            var hostedEvents = [];
            var hostedProducts = [];
            const q = query(collection(db, "orders"), where("order_date", ">=", new Date("2023-09-20")),  orderBy("order_date", "desc"));
            orderResult = await getDocs(q);
            console.log("order count " +  orderResult.size);
            if(!adminUsers.includes(sessionStorage.getItem("hash")) && sessionStorage.getItem("isAdmin") !== "true"){
               
                const productResult = await getDocs(query(collection(db, "products"), where("hosts", "array-contains", sessionStorage.getItem("uid"))));
                if(!productResult.empty) {
                    productResult.forEach( (productObj, index) =>{
                        hostedProducts.push(productObj.id);
                    });
                }
                console.log("hosted products", hostedProducts   );
                const eventResult = await getDocs(query(collection(db, "events"), ));
                    
                if(!eventResult.empty) {
                    eventResult.forEach( (eventObj, index) => {              
                        if(eventObj.data().host === sessionStorage.getItem("uid")) {
                       // console.log("hosted event :" + eventObj.data().name);
                            hostedEvents.push(eventObj.id);
                        } 
                        
                    });
                } 
                
               // const productResult = await getDocs(query(collection(db, "products"), where("hosts", "array-contains", sessionStorage.getItem("uid"))));
               

            }
           

            if (!orderResult.empty) {
                
                var loadedOrders = [];
               
                orderResult.forEach( (orderObj, index) => {
                    const order = orderObj.data();
                    order.id = orderObj.id;

                    if(!order.order_number) {
                        order.order_number = "0000";
                        //firebase.firestore.collection("orders")
                    }

                    if(!order.status){
                        order.status = "Pending";
                    }
                    order.orderDate = moment(order.order_date.toDate()).format("MM/DD/YYYY");
                    order.customer_name = order.displayname + " (" + order.email + ")";
                    //console.log(order.eventid);
                    
                    if(adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") ==="true" ||  hostedEvents.includes(order.eventid) ){
                        loadedOrders.push(order);
                    } else {
                       
                       if(order.products) {
                       // console.log(order.order_number + ": " + order.products.length);
                        order.products.forEach( (product, index) => {
                          //  console.log(order.order_number + ": ", order.products);
                          //  console.log("hosted products ;", hostedProducts);
                            if(hostedProducts.includes(product)) {
                                loadedOrders.push(order);
                            }
                            //console.log(product);
                        });
                       }

                      /*   for(var i=0; i<order.products.size; i++) {
                            if(hostedProducts.includes(order.products[i].id)) {
                                loadedOrders.push(order);
                                break;
                            }
                        } */
                    }
                });
                setOrders(loadedOrders);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }

        }catch (err) {
            console.log(err);
            setIsLoading(false);
            setAlertType("error");
            setAlertMessage("There is problem loading the orders, please try again later");
            setShowAlert(true)
        }
    }

    const showOrderDetails = (orderid) => {
        setShowOrder(orderid);
    }

    const hideOrderDetails = (orderid) => {
        setShowOrder("");
        loadOrders();
    }


    const onOrderClick = (orderid) => {
        const viewOnly = false;
        props.navigation.navigate("orderEdit", {id: orderid, viewonly: viewOnly});
    }


    const bulkUpdateOrderStatus = async () => {
        try {
            setIsLoading(true);
            const selectedOrders = [];
            for(var i=0; i<orderRowsSelected.length; i++) {
                //console.log(orderRowsSelected[i]);
                const data = {
                    status: newStatus,
                    lastUpdatedOn: new Date(),
                    updatedByUid: sessionStorage.getItem("uid"),
                    updatedByName: sessionStorage.getItem("displayname"),
                    

                }
                await setDoc(doc(db, "orders", orderRowsSelected[i]), data, {merge: true});
               
            }
            loadOrders();
            setIsLoading(false);
        } catch(err){
            setIsLoading(false);
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("unable to update order status. please try again later")
        }
    }

    const downloadSelectedOrders = async () =>{
        try {
            setIsLoading(true);
           
            var csvString = "Order Number, Order Date, Event, Name, Email,  Subtotal, Shipping, Total, Status, Address 1, Address 2, City, State, ZIP, Country, Length, Width, Height, Weight\n";
            for(var i=0; i<orderRowsSelected.length; i++) {
                for (var j=0; j<orders.length; j++) {
                    if(orderRowsSelected[i] === orders[j].id) {
                        const order = orders[j];
                        console.log(order);
                        const data = {
                            "Order Number": order.order_number,
                            "Order Date": order.orderDate,
                            "Event": order.event,
                            "Name": order.shipping_address.name ?? order.displayname,
                            "Email": order.email,
                            "Subtotal": order.subtotal,
                            "Shipping": order.shipping,
                            "Total": order.total,
                            "Status": order.status,
                            "Address 1": order.shipping_address.line1,
                            "Address 2":order.shipping_address.line2,
                            "City": order.shipping_address.city,
                            "State": order.shipping_address.city,
                            "Zip": order.shipping_address.postal_code,
                            "Country": order.shipping_address.country,
                            "Length": order.products[0].boxLength,
                            "Width": order.products[0].boxWidth,
                            "height": order.products[0].boxHeight,
                        }
                        var weight = 0;
                        for(var k=0; k<order.products.length; k++) {
                            weight += parseFloat(order.products[k].weight);
                        }
                        data.weight = weight;

                        //console.log(data);

                        var  str = "";
                        Object.keys(data).forEach((key) => {
                            str += data[key] + ",";
                        });
                        console.log(str);
                        csvString += str +"\n";

                    }
                    
                }
            }
            saveAs(new Blob([csvString], {type: "text/csv;charset=utf-8"}), "orders.csv");

           


            setIsLoading(false);
        } catch(err){
            setIsLoading(false);
            setShowAlert(true);
            setAlertType("error");
            console.log(err);
            setAlertMessage("unable to download spreadsheet. please try again later")
        }
    }

    const mergeOrders = async () => {
        try {
            setConfirmMergeOpen(false);
            setIsLoading(true);
            
            const ordersToMerge = [];
            var containsMerged = false;
            for(var i=0;i<orderRowsSelected.length; i++) {
               // ordersToMerge.push(orderRowsSelected[i]);
               for(var j=0;j<orders.length;j++){
                   if(orderRowsSelected[i] == orders[j].id) {
                        if(orders[j].status === "Merged") {
                            containsMerged = true;
                        } else {
                          ordersToMerge.push(orders[j]);
                        }
                   }
               }
            }
            console.log(ordersToMerge);

            if(ordersToMerge.length > 1 && !containsMerged) {

                const batch = writeBatch(db);

                const newOrder = ordersToMerge[0];
                var newProducts = newOrder.products;
                var newSubtotal = parseFloat(newOrder.subtotal);
                var newShipping = parseFloat(newOrder.shipping);
                var newTax = parseFloat(newOrder.tax);
                var newTotal = parseFloat(newOrder.total);
              
                for(var i=1;i<ordersToMerge.length;i++){
                
                    newProducts = newProducts.concat(ordersToMerge[i].products);
                    newSubtotal += parseFloat(ordersToMerge[i].subtotal);
                    newShipping += parseFloat(ordersToMerge[i].shipping);
                    newTax += parseFloat(ordersToMerge[i].tax);
                    newTotal = newSubtotal + newShipping + newTax;
                    batch.update( doc(db, "orders", ordersToMerge[i].id), {"status": "Merged"} );
                  
                }
               
                newOrder.products = newProducts;
                newOrder.subtotal = newSubtotal;
                newOrder.shipping = newShipping;
                newOrder.tax = newTax;
                newOrder.total = newTotal;

               

                batch.update( doc(db, "orders", newOrder.id), newOrder);
                await batch.commit();

                for(var i=0; i<ordersToMerge.length; i++) {
                  //  console.log("data");
                    const data = {"name": sessionStorage.getItem("displayname"), "note": "Merged with order "+ newOrder.order_number, "note_date": new Date(), orderid: ordersToMerge[i].id};
                  //  console.log(data);
                    await addDoc(collection(db, "order-notes"), data );
                }

             loadOrders();
               
               // setIsLoading(false);
            
            } else {
               
                setShowAlert(true);
                setAlertType("error");
                setIsLoading(false);
 
                setAlertMessage("You need to select 2 or more orders to merge");
            }
           
            if(containsMerged) {
                setShowAlert(true);
                setAlertType("error");
                setAlertMessage("One of more orders selected has already been merged. If you would still like to merge these orders, please update the orders status before merging them. ")
                setIsLoading(false);
            }
           
   
        }catch (err){
           
            setShowAlert(true);
            setAlertType("error");
            console.log(err);
            setAlertMessage("unable to merge order. please try again later");
        }
    }

    return (
        <ConsoleTemplate {...props} isLoading={isLoading} tab={4} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Orders</h2>
           
                   { (adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isHost") === "true" || sessionStorage.getItem("isAdmin") || sessionStorage.getItem("isVendor"))&& 
            <Fab color="secondary" size="large" variant="extended" onClick={()=> onOrderClick(null)}>
                <AddCircleOutlineIcon /> &nbsp;Add
            </Fab>
             
            }
            <FormControlLabel control={
                <Switch className="form-input-field" name="showall" checked={showAll} onChange={ (e) => setShowAll(!showAll)} color="secondary" />
            } label="Show All" />
            {orderRowsSelected.length >0 && 
            <div>
                <div className="table-action-row">
                    <FormControl fullWidth={true} className="select-status form-input-field" >
                    <InputLabel variant="standard" id="status-select-label" shrink={true}>Status</InputLabel>
                    <NativeSelect id="status-select" value={newStatus} onChange={(e)=> setNewStatus(e.target.value)}>
                    {orderStatus.map( (status, index)=> {
                            return <option key={status} value={status}>{status}</option>
                        })}
                    </NativeSelect>
                    </FormControl>&nbsp;&nbsp;
                    <Button variant="contained" color="secondary" onClick={ bulkUpdateOrderStatus }>Update Status</Button> 
                </div>
                
                <div className="table-action-row">
                   
                    <Button variant="contained" color="secondary" onClick={ downloadSelectedOrders }>Download Orders as CSV</Button>&nbsp;&nbsp;
                    {orderRowsSelected.length > 1 && <Button variant="contained" color="secondary" onClick={ ()=>setConfirmMergeOpen(true) }>Merge Orders</Button>}
                </div>
                
            </div>
            }

            <div className="order-table data-table">
                <DataGrid
                    checkboxSelection={true}
                    pageSize={20}
                    autoHeight={true}
                    rowsPerPageOptions={[20]}
                    //{products.filter( ( (product) => showAll ? true : product.isActive))}
                    rows={orders.filter(( (order) => showAll ? true : (order.status != "Merged" && order.status != "Cancelled" )))}

                    columns={[
                        { field: "actions", headerName: "Action", type:"actions", flex: 1,  headerClassName: "data-table-header", 
                        renderCell: (data) =>  <IconButton aria-label="Manage Order" onClick={ () => { onOrderClick(data.id)} } ><SettingsOutlinedIcon sx={{ color: "#000000"}}/></IconButton>},
                        {field: "order_date", headerName:"Date", flex:1, headerClassName:"data-table-header", valueFormatter: (data) => moment(new Timestamp(data.value.seconds, data.value.nanoseconds).toDate()).format("MM/DD/YYYY") },
                        {field: "products", headerName: "Product(s)", flex:2, headerClassName:"data-table-header", renderCell : ( data ) => data.row.product_info != null ?  data.row.product_info.map( (product) => product.name).join(", ") : "" },
                        {field: "order_number", headerName: "Order No.", flex:1, headerClassName:"data-table-header"},
                        {field:"event", headerName: "Event", flex:2, headerClassName:"data-table-header"},
                        {field:"customer_name", headerName: "Customer", flex:2, headerClassName:"data-table-header"},
                        {field:"subtotal", headerName: "Subtotal", flex:1, headerClassName:"data-table-header", valueFormatter : ( data ) => "$" + data.row.subtotal },
                        {field:"total", headerName: "Total", flex:1, headerClassName:"data-table-header", valueFormatter : ( data ) => "$" + data.row.total },
                        {field: "status", headerName:"Status", flex:1, headerClassName:"data-table-header"},
                        

                    ]}
                    onSelectionModelChange={(selectionModel, details) => {
                        setOrderRowsSelected(selectionModel);
                    }}
                />

              
            </div>

            <Dialog maxWidth="md" open={confirmMergeOpen} onClose={ () => setConfirmMergeOpen(false)} onBackdropClick={ () => setConfirmMergeOpen(false)}>
                <DialogTitle>Confirm Merge</DialogTitle>
                <DialogContent><p>Are you sure you want to merge these orders?</p>
                    <ol>
                        {orderRowsSelected.map( (order, index) => {
                           for(var i=0; i<orders.length; i++) {
                                if(orders[i].id === order) {
                                    return <li key={orders[i].id}>{orders[i].order_number} by {orders[i].customer_name} at event {orders[i].event} </li>
                                }
                           }
                           // return <li>{order.order_number}</li>
                        })}
                    </ol>

                    
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={ mergeOrders }>Confirm</Button>&nbsp;&nbsp;
                    <Button variant="outlined" color="primary" onClick={ () => setConfirmMergeOpen(false) }>Cancel</Button>
                </DialogActions>
            </Dialog>
            
           
        </ConsoleTemplate>
    )
}

export default OrderLandingView