import React, { useEffect, useState } from 'react'
import firebase from '../components/firebase'

import { getFirestore, collection,  getDoc, getDocs, query, where, orderBy, setDoc, addDoc, doc, Timestamp} from "firebase/firestore";
import ConsoleTemplate from '../components/consoleTemplate'

import { Container, TextField, Input, Button, Chip, MenuItem, FormControl, InputLabel, NativeSelect, Switch, Select, FormControlLabel} from '@mui/material';
import InputIcon from '@mui/icons-material/Input'

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

import FirebaseFileUpload from '../components/firebase-fileupload';

const db = getFirestore();


const BrandEditView = (props) => {

    const [hosts, setHosts] = useState([]);
    var hostArray = []
    const [state, setState] = useState({
        id: "",
        name: "",
        description: "",
        image: "",
        isActive: false,
        categories: [],
        users: [],
        tags: [],
    })

    const [brandCategories, setBrandCategories]= useState(["Accessories", "Tops", "Bottoms", "Shoes", "Collectibles", "Home"]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [imageToUpload, setImageToUpload] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");

    useEffect ( () => {
        getBrandData();
    }, [])

    const getBrandData = async () => {
        try {
            setIsLoading(true);
            
        
            const hostResult = await getDocs(query(collection(db, "profiles"), where("isVendor", "==", true), orderBy("displayname", "asc")));
           

            if(hostResult.size > 0) {
                const hostDocs = hostResult.docs;
                var hostResults = [];
                hostDocs.forEach( (host) => {
                    const data = host.data();
                    const obj = { displayname: data.displayname, email: data.email, uid: data.uid, avatar: data.image}
                    hostResults.push(obj);
                    
                });
                setHosts(hostResults);
                hostArray=hostResults;
            }
      

        const brandid = props.navigation.getParam("id");
        if (brandid !== null ) {
            const result = await getDoc(doc(db, "brands", brandid));
            //await firestore.collection("brands").doc(brandid).get();
       
        if (result.exists) {
            
            const doc = result.data();
            console.log(doc);
            setState( {
                ...state, 
                id: brandid,
                name: doc.name, 
                description: doc.description, 
                image: doc.image,
                categories: doc.categories,
                users: doc.users != null ? doc.users : [],
                tags: doc.tags != null? doc.tags: [],
                isActive: doc.isActive,
                }  )
                
                if(doc.users != null && doc.users.length > 0 ) {
                    const selectedHosts = [];
                    //console.log("hosts: ");
                    //console.log(hosts);
                    for(var i=0; i<doc.users.length;i++) {
                        for(var j=0; j<hostArray.length; j++) {
                            console.log(doc.users[i] + " / " + hostArray[j].uid);
                            if(doc.users[i] === hostArray[j].uid){
                                selectedHosts.push(hostArray[j]);
                            }
                        }
                    }
                  
                    setSelectedUsers(selectedHosts);
                }
            
            console.log("get brand");
             }
            
        }
         setIsLoading(false);
        }catch(err) {
            setIsLoading(true);
            console.log(err);

        }
    }

   


    

   

    const updateBrand = async () => {

        setIsLoading(true)
        try {
            const brandid = props.navigation.getParam("id");

            
           
            const brandObj = state;
            brandObj.updatedByUid = sessionStorage.getItem("uid");
            brandObj.updatedByName = sessionStorage.getItem("displayname");
            brandObj.updatedOn = new Date();

            if(brandid != null ) {
               
                const result = await setDoc(doc(db, "brands", brandid), brandObj, {merge:true});
                //await firestore.collection("brands").doc(brandid).set(brandObj, {merge: true});
            }else {
                brandObj.createdByUid = sessionStorage.getItem("uid");
                brandObj.createdByName = sessionStorage.getItem("displayname");
                brandObj.createdOn = new Date();
                console.log("add brand");
                console.log(brandObj);

                const result = await addDoc(collection(db, "brands"), brandObj)
                //await firestore.collection("brands").add(brandObj);
            }
            setIsLoading(false)
            props.navigation.navigate("brandsLanding");
        } catch (err) {
            console.log(err)
        }

    }

    const cancelUpdate = () => {
       // console.log("cancel");
        props.navigation.navigate("brandsLanding")
    }

    const onCategoryChange = (event) => {
       // console.log(event.target.value);
        setState({
            ...state,
            categories: event.target.value,
        })

    }

    const onHostChange = (event, child) => {
        //console.log(child.props.value);
        const selected =child.props.value;
        const hostList = selectedUsers;
        const stateUserList = state.users;
        var alreadyInList = false;
        hostList.forEach((host, index) => {
            if(host.uid === selected.uid) {
                alreadyInList = true;
                hostList.splice(index, 1);
                stateUserList.splice(index, 1);
            }
        })

        if(!alreadyInList) {
            hostList.push(selected);
            stateUserList.push(selected.uid);
        }

        console.log(stateUserList);

         setState( {
             ...state, 
            users: stateUserList,
        }) 
    }

    const onSwitchChange = (event) => {
        console.log(state)
        setState( {
            ...state,
            isActive: !state.isActive
        })
    }

    return (
        <ConsoleTemplate {...props} isLoading={isLoading} tab={3} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
            <h2>Brand Details - {state.name}</h2>
            <div className="brand-edit-form edit-form">
            <Container className="tab-panel">
            <TextField type="text" fullWidth={true} className="form-input-field" placeholder="Brand" label="Brand Name" id="name" required value={state.name} onChange={ (event) => { setState({...state, name: event.target.value})}} />
            <TextField  type="text" className="form-input-field" fullWidth={true} placeholder="Description" label="Description" aria-label="Brand description" value={state.description} onChange={ (event) => { setState( { ...state, description:event.target.value })}}/>
            <FirebaseFileUpload id="brand-logo" className="form-image-upload" label="Brand Logo" accept="image/*" name="logo" storagePath="brands/" onSuccess={ (path) => {setState({...state,image: path})}} preview={state.image} onRemovePreview={ () => { setState({...state, image:""})}} />
            <FormControlLabel  className="form-input-field" control={
                <Switch onChange={onSwitchChange} checked={state.isActive} color="secondary" />
            } label="Active" />
            <FormControl fullWidth={true} className="select-category form-input-field">
                <InputLabel varirant="standard" shrink={true}>Categories</InputLabel>
                <Select id="brand-categories" multiple value={state.categories} onChange={ onCategoryChange }
                            input={<Input id="select-multiple-chip" />}
                            renderValue={
                                (selected) => (
                                    <div className="select-chips category-chips">
                                        {selected.map( (value) => (
                                            <Chip key={value} label={value} className="chip" />
                                        ))}
                                    </div>
                                )

                            }
                        >
                            {brandCategories.map( (categoryName) =>(
                                <MenuItem key={categoryName} value={categoryName}>{categoryName}</MenuItem>
                            ))}

                        </Select>
            </FormControl>
            <FormControl fullWidth={true} className="select-host form-input-field">
                <InputLabel variant="standard" shrink={true}>Vendors</InputLabel>
                <Select id="brand-hosts" multiple value={selectedUsers} onChange={ onHostChange }
                            input={<Input id="select-multiple-chip" />}
                            renderValue={
                                (selected) => {
                                  
                                    return (<div className="select-chips category-chips">
                                        {selected.map( (value) => (
                                            <Chip key={value.uid} label={value.displayname} className="chip" />
                                        ))}
                                    </div>)
                                }

                            }
                        >
                            {hosts.map( (host) =>(
                                <MenuItem key={host.uid} value={host}>{host.displayname +"("+host.email +")"}</MenuItem>
                            ))}

                        </Select>
            
            </FormControl>
            </Container>

            
                
                
                
              

                <div className="input-row form-actions">
                <Button variant="contained" color="buttonDark" endIcon={ <SaveIcon /> } onClick={ updateBrand }>
                    Save
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="contained" color="buttonLight" endIcon={ <ClearIcon /> } onClick={ cancelUpdate  }>
                    Cancel
                </Button>
                

                </div>

            </div>

            </ConsoleTemplate>
    )

}

export default BrandEditView;