import React, {useState, useEffect} from 'react'

import ConsoleTemplate, {adminUsers, orderStatus} from '../components/consoleTemplate'
import firebase from '../components/firebase'
import {Container,Box, Divider, Card, CardActions, Grid, CardContent, CardHeader, Tabs, Tab, NativeSelect,  TextField, Button, Input, Autocomplete, FormControl, Select, ImageList, ImageListItem, ImageListItemBar, IconButton, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'

import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

import {saveAs} from 'file-saver'

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

import { getFirestore, collection, getDoc, doc, getDocs, query, where, orderBy, setDoc, addDoc} from "firebase/firestore";

import TabPanel from '../components/tabPanel'

import moment from 'moment'
//import { linkWithCredential } from '@firebase/auth';

var shippo = require('shippo')("shippo_live_013c546ecbb0d3bec071cb69cff21123db768ce7");

const axios = require('axios').default;
const db = getFirestore();
const cors = require('cors')({origin: true});

const OrderEditView = (props) => { 
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [events, setEvents] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [noteCount, setNoteCount] = useState(0);
    const [orderNotes, setNotes] = useState([])
    const [newNote, setNewNote] = useState("");
    const [products, setProducts] = useState([]);
    const [confirmationAddress, setConfirmationAddress] = useState("");
    const [productSelectOpened, setProductSelectOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("order");
    const [parcelWidth, setParcelWidth] = useState(9);
    const [parcelHeight, setParcelHeight] = useState(1);
    const [parcelLength, setParcelLength] = useState(15);
    const [parcelWeight, setParcelWeight] = useState(0.5);
    const [shippingRates, setShippingRates] = useState({});

    const [order, setOrder] = useState({
        order_date: new Date(),
        order_number: "",
        event: "",
        eventid: "",
        uid: "",
        displayname: "",
        phone: "",
        email: "",

        billing_address: {},
        shipping_address: {},
        sender_address: {},
        status: "Processing",
        products: [],
        subtotal: 0,
        tax: 0,
        shipping: 0,
        total: 0,

        paymentid: "",
        payment_from: "",
        payment_card: "",
        cardid: "", 

        appliedCoupon: "",
        device: "",
        discount: 0,
        discountPercent: false,
        os: "",
        osVersion: "",
        
        tracking_code: "",
        tracking_link: "",
        courier: "",
        labels: [],
        lastUpdateOn: new Date(),


    });

    const orderid = props.navigation.getParam("id");
    useEffect( () => { 
        

        
     //   const viewonly = props.navigation.getParam("viewonly");
      //  const isAdmin = adminUsers.includes(sessionStorage.getItem("hash"));


        getEvents();
        getCustomers();
        getProducts();
        if(orderid) {
            loadOrderInfo(orderid);
            getOrderNotes();
        }
    }, []);

    const loadOrderInfo =  async (orderid) => {
        try {
            setIsLoading(true);

            const orderResult = await getDoc(doc(db, "orders", orderid));
            //await firebase.firestore().collection("orders").doc(orderid).get();

            if(orderResult.exists) {
                const orderData = orderResult.data();
                
                orderData.order_date = new Date(orderData.order_date.toDate());
                orderData.id = orderResult.id;
                if ( !Array.isArray(orderData.products)) {
                    orderData.products = [];
                } else {
                    var weight = 0;
                    for(var i=0;i<orderData.products.length;i++){
                        weight += parseFloat(orderData.products[i].weight);
                    }
                    setParcelWeight(weight > 0 ? weight : 0.5);
                }
               
                if(orderData.sender_address == undefined) {
                    console.log("profile " + sessionStorage.getItem("uid"));
                    const profileResult = await getDoc(doc(db, "profiles", sessionStorage.getItem("uid")));
                    const profileData = profileResult.data();
                    orderData.sender_address = {
                        name: profileData.shipping_name ?? profileData.displayname,
                        line1: profileData.shipping_address1,
                        line2: profileData.shipping_address2,
                        city: profileData.shipping_city,
                        state: profileData.shipping_state,
                        zip: profileData.shipping_zip,
                        country: profileData.shipping_country,
                        phone: profileData.phone,
                        email: profileData.email,
                    }
                }
                
                setOrder(orderData);  
            } 

            setIsLoading(false);
        } catch (err){
            setAlertMessage("Unable to load order information");
            setShowAlert(true);
            setAlertType("error");
            setIsLoading(false);
            console.log(err);

        }
    }

    const getEvents = async () => { 
        try {   
            const eventResult = await getDocs(collection(db, "events"));
            //await firebase.firestore().collection("events").orderBy("eventDate", "desc").get();
           
            if(!eventResult.empty) {
                const eventDocs = eventResult.docs;
                const eventArray = [];
                eventDocs.forEach( (doc) => { 
                    const eventData = doc.data();
                    const eventObj = {
                        label: eventData.name,
                        id: doc.id
                    }
                    //eventData.id = doc.id;
                    //eventData
                    eventArray.push(eventObj);
                });
                //console.log("Event results");
                //console.log(eventArray);
                setEvents(eventArray);
                

                

            }
           

        }catch(err){ 
            console.log(err);
        }
    }

    const getOrderNotes = async () => { 
        try {
            
         
            const notesResult = await getDocs(query(collection(db, "order-notes"), where("orderid", "==", props.navigation.getParam("id")), orderBy("note_date", "desc")));
            //await firebase.firestore().collection("order-notes").where("orderid", "==", props.navigation.getParam("id")).orderBy("note_date", "desc").get();
            if(!notesResult.empty) {
                var loadedNotes = []
                notesResult.forEach ( (note, index) => {
                    const noteObj = note.data();
                    noteObj.id = note.id
                    noteObj.date = moment(noteObj.note_date.toDate());
                    loadedNotes.push(noteObj);
                    
                });
                //console.log(loadedNotes)
                 setNoteCount(loadedNotes.length)
                setNotes(loadedNotes);
            }
        


        } catch (err) {
            console.log(err)
        }
    }

    const getCustomers = async () => {
        try {
            const customerResult = await getDocs(query(collection(db, "profiles"), orderBy("displayname", "asc")));
            //await firebase.firestore().collection("profiles").orderBy("displayname", "asc").get();

            if(!customerResult.empty) {
                const customerDocs = customerResult.docs;
                const customerArray = [];
                customerDocs.forEach( (doc) => {
                    const customerData = doc.data();
                    customerData.id = doc.id;
                    if(customerData.displayname !== "") {
                    customerData.label = customerData.displayname + " (" +customerData.email+")" ;

                    customerArray.push(customerData);
                    }
                
                });
                setCustomers(customerArray);
                
            }

        }catch(err){
            console.log(err);
        }
    }

    const getProducts = async () => { 
        try {
            const productResult = await getDocs(query(collection(db, "products"), where("isActive", "==", true), orderBy("name", "desc")));

            //await firebase.firestore().collection("products").where("isActive", "==", true).orderBy("name", "desc").get();
            if(!productResult.empty) {
                const productDocs = productResult.docs;
                const productArray = [];
                productDocs.forEach( (doc) => { 
                    const productData = doc.data();
                    productData.id = doc.id;
                    productArray.push(productData);
                });
                setProducts(productArray);
                
            }

        } catch (err){
            console.log(err);
        }
    }
    const updateOrderStatus = async (status) => {
        try {
            if(orderid) {
             await setDoc(doc(db, "orders", props.navigation.getParam("id")), {status: status, lastUpdatedOn: new Date()} , {merge:true})
           

            addOrderNotes("Update Order Status - " + status);
            } 
            setOrder({...order, status: status }); 
        } catch(err){
            console.log(err);
            setAlertType("error");
            setAlertMessage("Unable to update product status");
            setShowAlert(true);
        }

    }

    

    const addOrderNotes = async (content)=> {
        const obj = {
            orderid: props.navigation.getParam("id"), 
            note_date: new Date(),
            note: content,
            name: sessionStorage.getItem("displayname"),
            uid: sessionStorage.getItem("uid")
            }
            
           // await firebase.firestore().collection("order-notes").add(obj)
           await addDoc(collection(db, "order-notes"), obj);
    
            obj.date = moment(obj.note_date)
            
            const newNoteList = orderNotes;
            newNoteList.unshift(obj);
            setNotes(newNoteList)
    
            setNoteCount(noteCount+1);
    }

    const saveOrder = async () => {
        console.log(order.id);
        if(order.id === undefined){  // add new order
            console.log(order);
            const addResult = await addDoc(collection(db, "orders"), order);
        
            addOrderNotes("Created Order");
            console.log(addResult.id);
        } else { // update order
             await setDoc(doc(db, "orders", order.id), order, {merge:true})
         
            addOrderNotes("Updated Event");

        }   

        props.navigation.navigate("orderLanding")

    }

    const cancelUpdate = () => { 
        props.navigation.navigate("orderLanding")
    }

    const generateOrderNumber = (uid) => {         
            var orderNumber = uid.substring(0, 3).toUpperCase() + "-" + Date.now().toString().substring(0,5) + (Math.ceil(Math.random()*100)+1).toString();
            return orderNumber;
    } 

    const saveTracking = async () => {
        console.log(order);
        try {
            await setDoc(doc(db, "orders", order.id), { 
                tracking_code: order.tracking_code,
                tracking_link: order.tracking_link,
                courier: order.courier,
            }, {merge:true});
            /* firebase.firestore().collection("orders").doc(order.id).set({ 
                tracking_code: order.tracking_code,
                tracking_link: order.tracking_link,
                courier: order.courier,
            }, {merge:true}) */
            await addOrderNotes("Tracking Updated");
            setAlertType("success");
            setAlertMessage("Tracking information saved");
            setShowAlert(true);
        } catch (err){
            console.log(err);
            setAlertType("error");
            setAlertMessage("There was an error saving tracking information. Please ensure all tracking information are correct and again later");
            setShowAlert(true);
        }
    }

    const sendTracking = async () => {
        try {


            const request_url = "https://us-central1-livfieapp.cloudfunctions.net/sendTrackingEmail";
            const data = { orderid: order.id}
            const result =  await axios.post(request_url, data);
            console.log(result);
            await addOrderNotes("Tracking Email Sent");
            setAlertType("success");
            setAlertMessage("Tracking Code email is sent to " + order.email);
            setShowAlert(true);
            
        }catch (err) {
            setAlertType("error");
            setAlertMessage("There was an error sending email. Please try again later");
            setShowAlert(true);
        }
    }

    const sendConfirmation = async (toAddress) => {
        try {
            setIsLoading(true);

            const request_url = "https://api.exchvnge.co/orders/sendOrderConfirmation"; //"https://us-central1-livfieapp.cloudfunctions.net/sendOrderEmailById";
            const data = { "orderid": order.id, "toAddress": toAddress}
            const result =  await axios.post(request_url, data);
            console.log(result);
            //await addOrderNotes("Order Email Sent");
            setAlertType("success");
            setAlertMessage("Order email is sent to " + toAddress);
            setShowAlert(true);
            setIsLoading(false);
            
        }catch (err) {
            setIsLoading(false);
            setAlertType("error");
            setAlertMessage("There was an error sending email. Please try again later");
            setShowAlert(true);
        }
    }

    const addProductToOrder = (productid) => {

        for(var i=0; i<products.length; i++ ){
            if(productid === products[i].id) {
                console.log("Add Product " + productid);
                console.log(products[i]);

                const orderProducts = order.products;
                const product = products[i];
                product["selectedSize"] = products[i].size[0]["size"];
                product["quantity"] =1;
                orderProducts.push(product); 
            }
        }
        setProductSelectOpen(false);
    }


    const updateSelectedProduct = (idx, productid, size, qty) =>{
        const orderProducts = order.products;
        console.log(idx + " : " + productid + " - " + size + " / " + qty);
        for(var i=0; i<orderProducts.length; i++ ){
            if(orderProducts[i].id === productid && idx === i) {
                if(size !== "" ){
                    orderProducts[i].selectedSize = size;
                } 
                
                if( qty >0 ) {
                    orderProducts[i].quantity = qty;
                }
            }
        }
        setOrder({...order, products: orderProducts});

    }

    const removeSelectedProduct = (idx, productid) => { 
        const orderProducts = order.products;

        for(var i=0; i<orderProducts.length; i++ ){
            if(orderProducts[i].id === productid && idx===i) {
                orderProducts.splice(i, 1);
            }
        }
        setOrder({...order, products: orderProducts});
    }

    const updateAddress = (type, field, value) => {
        if(type === "shipping") {
            const address = order.shipping_address;
            address[field] = value;
            console.log(address);
            setOrder({...order, shipping_address: address});
        } else if(type ==="sender") {
            const address = order.sender_address;
            address[field] = value;
            console.log(address);
            setOrder({...order, sender_address: address});
        }
        
    }

    const getShippingRates = async () => {
        try {
            setIsLoading(true);
            
            
            const address_from = {
                "name": order.sender_address.name,
                "street1": order.sender_address.line1, 
                "street2": order.sender_address.line2,
                "city": order.sender_address.city,
                "state": order.sender_address.state,
                "zip": order.sender_address.zip,
                "country": order.sender_address.country,
                "phone": order.sender_address.phone,
                "email": order.sender_address.email,
            }

            const address_to = {
                "name": order.shipping_address.name,
                "company" : "Order #:" + order.order_number,
                "street1": order.shipping_address.line1,
                "street2": order.shipping_address.line2,
                "city": order.shipping_address.city,
                "state": order.shipping_address.state,
                "zip": order.shipping_address.postal_code,
                "country": order.shipping_address.country,
                "metadata" : "Order #: " + order.order_number,
            }

            const parcel = {
                "length": parcelLength,
                "width": parcelWidth,
                "height": parcelHeight,
                "distance_unit": "in",
                "weight": parcelWeight,
                "mass_unit": "lb",
            }
            
            const shipmentData =  { 
                "address_from": address_from,
                "address_to": address_to, 
                "address_return": address_from,
                parcels: [parcel],
                "async": false,

                } ;
            console.log("ships to");
            console.log(address_to.country);
            if(address_to.country !== "US") {

                const productResult = await getDoc(doc(db, "products", order.products[0]));
                const productData = productResult.data();
                var customsItem = { 
                    "description":"Online purchase " + productData["category"],
                    "quantity": order.products.length,
                    "net_weight": parcelWeight,
                    "mass_unit":"lb",
                    "value_amount":order.total,
                    "value_currency":"USD",
                    "origin_country":"US",
                };
                 const customsResult = await shippo.customsdeclaration.create({
                    "contents_type": "MERCHANDISE",
                    "contents_explanation": productData["category"],
                    
                    "non_delivery_option": "RETURN",
                    "certify": true,
                    "certify_signer": order.sender_address.name,
                    "items": [customsItem],
                }, function (err, customsDeclaration) {
                    // asynchronously called
                    //console.log("customs error ", err);
                   // console.log(customsDeclaration.objectid);
                });
                    
                console.log("customs data");
                console.log(customsResult);
                shipmentData["customs_declaration"] = customsResult.object_id;
                
            }
            console.log("Shipment data: ");
                console.log(shipmentData);
            const shipment = await shippo.shipment.create(shipmentData);
            console.log(shipment);
            setShippingRates(shipment);
            //console.log(shipment)

                setIsLoading(false);

        } catch (err) {
            setIsLoading(false);
            setAlertType("error");
            setAlertMessage("Unable to receive shipping rates. Please try again later");
            console.log(err);
            setShowAlert(true);
        }
    }

    const createShippingLabel = async (rate) => {
        try {
            shippo.transaction.create({
                "rate" : rate.object_id,
                "label_file_type": "PDF_4x6", 
                "async": false
            }, (err, transaction) => { 
               // console.log("transction created");
               // console.log(transaction);
                const labels = order.labels ?? [];
                const labelObj = {rate: rate, transaction: transaction};
            
                labels.push(labelObj);
                setDoc(doc(db, "orders", orderid), {
                    labels: labels,
                 }, {merge:true});
                 setOrder({...order, labels: labels});
                 addOrderNotes("Purchased Shipping Label - " + rate.provider + " - " + rate.servicelevel.name);

            } );

        } catch(err) {
            setIsLoading(false);
            setAlertType("error");
            setAlertMessage("Unable to create shipping label. Please try again later");
            setShowAlert(true);
        
        }
    }   

    const sendEmailFromShippingLabel = async (courier, code, link) => {
        try {
            setIsLoading(true);
        setOrder(
            {
                ...order,
                courier: courier, 
                tracking_code: code,
                tracking_link: link,
            }
        );


       // await saveTracking();
       await setDoc(doc(db, "orders", order.id), { 
        tracking_code: order.tracking_code,
        tracking_link: order.tracking_link,
        courier: order.courier,
        }, {merge:true});
        
        await addOrderNotes("Tracking Updated");

        await sendTracking();
        setAlertType("success");
        setAlertMessage("Order Tracking email has been sent to customer.");
        setShowAlert(true);
        setIsLoading(false);
        } catch(err){
            setIsLoading(false);
            setAlertType("error");
            setAlertMessage("Unable to send notification email. Please try again later.");
            setShowAlert(true);
        }


    }

    const downloadLabel = async (filepath, filename) => {
        try {
            console.log("download file " + filepath );
            axios({
                headers: {
                    'Access-Control-Allow-Origin': '*',
                  },
                url: filepath,
                method: 'GET',//
                withCredentials: false,
                responseType: 'blob', // important
            }).then ((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                 const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); 
                //saveAs(new Blob([response.data]), filename);
            });
        } catch (err) {
            setIsLoading(false);
            setAlertType("error");
            setAlertMessage("Unable to download file. Please try again later.");
            setShowAlert(true);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <ConsoleTemplate {...props} isLoading={isLoading} tab={2} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage} >
        {props.navigation.getParam("id") ? <h2>Edit Order</h2> : <h2>Create Order</h2> }
        <div className="order-edit-form edit-form">

            <Container className="form-tabs">
                <Tabs value={activeTab} onChange={ (e, newValue) => setActiveTab(newValue)}>
                    <Tab label="Order Details" value="order" />
                    <Tab label="Products" value="products" />
                    <Tab label="Shipping" value="address" />
                    <Tab label="Shipping Label" value="label" /> 
                    { props.navigation.getParam("id") && <Tab label="Notifications" value="notifications" /> }
                </Tabs>
            </Container>
            <TabPanel name="order" activeTab={activeTab}>
                <FormControl fullWidth={true} className="form-input-field" >
                <DateTimePicker className="form-date-input form-input-field" label="Order Date" variant="standard" renderInput={ (props) => <TextField  {...props} /> } value={order.order_date} onChange={ (newDate) => { setOrder({...order, order_date: newDate.toDate()})}} />
                </FormControl>

                

                    <Autocomplete fullWidth={true} id="event" 
                    options={events} 
                  
                    autoSelect={true}
                    autoHighlight = {true}
                    openOnFocus={true}
                    disableClearable={true}
                    value={order.event}
                    renderOption= { (props, option, state) =>  <li  {...props} key={option.id} >{option.label}</li> } 
                    renderInput= { (params) => { return <TextField  className="form-input-field" {...params} label="Event" variant ="outlined" inputProps={{...params.inputProps}} /> }}
                    
                    onChange={ (event, value, reason, details) => {
                        //console.log("changed to " );
                        //console.log(value)
                        setOrder({...order, event: value.label, eventid: value.id });
                    }}

                    />
                    <Autocomplete fullWidth={true} id="customer" options={customers} 
                    
                    autoHighlight = {true}
                    autoSelect={true}
                    
                    openOnFocus={true}
                    disableClearable={true}
                    value={order.displayname}
                    renderInput={ (params) => <TextField  className="form-input-field" label="Customer" {...params} variant="outlined" inputProps={{...params.inputProps}} /> }
                    onChange={(event, value) =>{ 
                        if(value) {
                           // console.log(value);
                            const orderNum = generateOrderNumber(value.uid);
                            setOrder({...order, 
                                displayname:value.displayname, 
                                email: value.email,
                                uid: value.uid,
                                phone: value.phone,
                                order_number: orderNum,
                                shipping_address: {
                                    name: value.shipping_name ?? value.displayname,
                                    line1: value.shipping_address1,
                                    line2: value.shipping_address2,
                                    city: value.shipping_city,
                                    state: value.shipping_state,
                                    postal_code: value.shipping_zip ,
                                    country:value.shipping_country,

                                },
                                billing_address: {
                                    name: value.billing_name ?? value.displayname,
                                    line1: value.billing_address1 ?? value.shipping_address1,
                                    line2: value.billing_address2 ?? value.shipping_address2,
                                    city: value.billing_city ?? value.shipping_city,
                                    state: value.billing_state ?? value.shipping_state,
                                    postal_code: value.billing_zip?? value.shipping_zip,
                                    country:value.billing_country ?? value.shipping_country,
                                }

                        });
                        
                        }
                    }}
                    
                />
                <TextField className="form-input-field" type="text"  fullWidth={true} label="Order Number" value={order.order_number} onChange={ (event)=> setOrder({...order, order_number: event.target.value})} />
                <TextField className="form-input-field" type="number"  fullWidth={true} label="Subtotal" placeholder="Subtotal" value={order.subtotal} onChange={ (event) => {setOrder({...order, subtotal: event.target.value})}} />
                <TextField className="form-input-field" type="number"  fullWidth={true} label="Shipping" placeholder="Shipping" value={order.shipping} onChange={ (event) => {setOrder({...order, shipping: event.target.value})}} />
                <TextField className="form-input-field" type="number"  fullWidth={true} label="Tax" placeholder="Tax" value={order.tax} onChange={ (event) => {setOrder({...order, tax: event.target.value})}} />
                <TextField className="form-input-field" type="number"  fullWidth={true} label="Total" placeholder="Total" value={order.total} onChange={ (event) => {setOrder({...order, total: event.target.value})}} />
                <FormControl fullWidth={true} className="select-status form-input-field" >
                    <InputLabel variant="standard" shrink={true}>Status</InputLabel>               
                    <NativeSelect
                   
                    id="order-status-select"
                    value={order.status}
                    onChange={ (event) => { updateOrderStatus(event.target.value); } }
                    >
                    {orderStatus.map( (status, index)=> {
                            return <option value={status} >{status}</option>
                        })}
                    </NativeSelect>
                </FormControl>
                
               

            </TabPanel>
            <TabPanel name="address" activeTab={activeTab}>
                <h4>Shipping Address</h4>
                <TextField className="form-input-field" fullWidth={true} label="Name" placeholder="Shipping Name" id="shipping-name" value={order.shipping_address.name ??""} onChange={(event) => {updateAddress("shipping", "name", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Address Line 1" placeholder="Shipping Address Line 1" id="shipping-address1" value={order.shipping_address.line1 ?? ""} onChange={(event) => {updateAddress("shipping", "line1", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Address Line 2" placeholder="Shipping Address Line 2" id="shipping-address2" value={order.shipping_address.line2 ?? "" } onChange={(event) => {updateAddress("shipping", "line2", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="City" placeholder="City" id="shipping-city" value={order.shipping_address.city?? ""} onChange={(event) => {updateAddress("shipping", "city", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="State" placeholder="State" id="shipping-state" value={order.shipping_address.state ?? "" } onChange={(event) => {updateAddress("shipping", "state", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="ZIP Code" placeholder="ZIP Code" id="shipping-postal-code" value={order.shipping_address.postal_code ?? "" } onChange={(event) => {updateAddress("shipping", "postal_code", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Country" placeholder="Country" id="shipping-country" value={order.shipping_address.country ?? "United States" } onChange={(event) => {updateAddress("shipping", "country", event.target.value)}} />
             <Divider />
                

                { props.navigation.getParam("id") &&
                <Box sx={{border:"1px solid #ccc", padding:"5px 10px"}}>
                    <h4>Tracking</h4>
                    <TextField fullWidth={true} className="form-input-field" label="Courier" placeholder="Courier" value={order.courier ? order.courier : "" } type="text" onChange={ (e) => setOrder( { ...order, courier: e.target.value})} />
                    <TextField fullWidth={true} className="form-input-field" label="Tracking Code" placeholder="Tracking Code" value={order.tracking_code ? order.tracking_code : ""} type="text" onChange={ (e) => setOrder( { ...order, tracking_code: e.target.value})} />
                    <TextField fullWidth={true} className="form-input-field" label="Tracking Link" placeholder="Tracking Link (incldues https://)" value={order.tracking_link ? order.tracking_link : ""} type="text" onChange={ (e) => setOrder( { ...order, tracking_link: e.target.value})} />
                    <Button color="buttonDark" onClick={ saveTracking } variant="contained">Save</Button>&nbsp;&nbsp;
                    <Button  color="buttonDark" onClick={ sendTracking } variant="contained">Email</Button>
                </Box>
                }
            </TabPanel>
            <TabPanel name="products" activeTab={activeTab}>
            <Button variant="contained" color="buttonDark" onClick={ () => { setProductSelectOpen(true) } } >Select Products</Button>

            <ImageList cols={3} rowHeight={200}>
                        {order.product_info == null ? <div /> : order.product_info.map( (product, idx) => {
                            return (
                                <ImageListItem  key={product.id}>
                                
                                    <img src={product.image} alt={product.name} />
                                <ImageListItemBar title={product.name} subtitle={"$"+product.price}  position="top" actionIcon={ 
                                    <IconButton onClick= { () => { removeSelectedProduct(idx, product.id); }} >
                                        <CancelIcon style={{ color: "#fff"}}/>
                                    </IconButton>
                                }/>
                                <ImageListItemBar style={{height:80}}title={

                                    <div>
                                        { Array.isArray(product.size) && 
                                            <div>
                                            <FormControl variant="filled" >
                                                <InputLabel htmlFor="filled-age-native-simple" style={{color:"#fff" }}>Size</InputLabel>
                                                <Select native style={{color:"#fff" }} value={product.selectedSize ?? product.size} label="Size" inputProps={{name:"size"}} onChange={ (event) => { 
                                                    updateSelectedProduct(idx, product.id, event.target.value, -1);
                                                }}
                                                >
                                                    {product.size.map( (size, sizeIdx) => <option key={size["size"]} value={size["size"]}>{size["size"]}</option>
                                                    )}
                                                </Select>
                                            </FormControl>
                                            
                                            </div>
                                            }
                                             { !Array.isArray(product.size) && <div><span>Size: {product.size}</span></div> }
                                    </div>

                                }
                                    actionIcon={
                                        <div>
                                    {<div>
                                        <FormControl fullWidth={true}>
                                                <InputLabel style={{color:"#fff" }}>Quantity</InputLabel>
                                                <Input style={{width:50, color:"#fff"}} type="number" name="quantity" value={product.quantity ?? 1} label="Quantity" onChange= { (event) => { updateSelectedProduct(idx, product.id, "", event.target.value);}} />
                                        </FormControl>
                                    
                                    </div>}
                                    </div>
                                    }
                                />             
                                <div>
                                        
                                       
                                        </div>
                                </ImageListItem>     
                            );
                        })}

                        </ImageList>

            </TabPanel>
            <TabPanel name="label" activeTab={activeTab}>
            <Box sx={{border:"1px solid #ccc", padding:"5px 10px"}}>
                    {order.labels !== undefined && order.labels.length > 0 &&
                    <Box>
                    
                    <h4>Existing Shipping Label</h4>
                    <Grid container spacing={5}>
                        {order.labels.map( (label, index) => {
                            console.log("labels: ");
                            console.log(order.labels);
                            return <Grid item md={6} lg={4} xs={12}> <Card key={label.rate.object_id} sx={{width: '100%', maxWidth: 350, marginBottom:"10px"}} elevation={2}>
                                <CardHeader title={label.rate.provider + " - " + label.rate.servicelevel.name} subheader={"$" + label.rate.amount} avatar={<img src={label.rate.provider_image_75} alt={label.rate.provider} width="75" />} />
                                {label.transaction !== null  &&
                                <CardContent>
                                    <h5>Tracking Number</h5>
                                    <p>{ label.transaction.tracking_number}</p>
                                    <h5>Tracking URL</h5>
                                    <p><a href={label.transaction.tracking_url_provider} target="_blank" rel="noreferrer" >{label.transaction.tracking_url_provider}</a></p>
                                    <h5>Tracking Status</h5>
                                    <p>{label.transaction.tracking_status}</p>
                                </CardContent>
                                }
                                   {label.transaction !== null  &&
                                <CardActions>
                             
                                <Button variant="contained" color="buttonDark" href={label.transaction.label_url} download="abc.pdf"  target="_blank" >Download Label</Button>&nbsp;
                               
                                <Button variant="contained" color="buttonDark" onClick={ () =>  sendEmailFromShippingLabel (label.rate.provider + " - " + label.rate.servicelevel.name, label.transaction.tracking_number, label.transaction.tracking_url_provider)} >Email Customer</Button>
                                </CardActions>}
                            </Card>
                            </Grid>
                        })}
                    </Grid>
                    <hr />
                    </Box>
                    }
                    <h4>Create Shipping Label</h4>
                     

                    <TextField name="parcelWidth" type="number" label="Parcel Width (inch)" placeholder="9 in" fullWidth={true} className="form-input-field" value={parcelWidth} onChange={ (e) => setParcelWidth(parseInt(e.target.value)) }/>
                    <TextField name="parcelHeight" type="number" label="Parcel Height (inch)" placeholder="1 in" fullWidth={true} className="form-input-field" value={parcelHeight} onChange={ (e) => setParcelHeight(parseInt(e.target.value)) }/>
                    <TextField name="parcelLength" type="number" label="Parcel Length (inch)" placeholder="15 in" fullWidth={true} className="form-input-field" value={parcelLength} onChange={ (e) => setParcelLength(parseInt(e.target.value)) }/>
                    <TextField name="parcelWeight" type="number" label="Parcel Weight (lbs)" placeholder="1 lbs" fullWidth={true} className="form-input-field" value={parcelWeight} onChange={ (e) => setParcelWeight(parseFloat(e.target.value)) }/>

                    <TextField className="form-input-field" fullWidth={true} label="Sender Name" placeholder="Sender Name" id="shipping-name" value={order.sender_address.name != undefined ? order.sender_address.name : ""} onChange={(event) => {updateAddress("sender", "name", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Sender Address Line 1" placeholder="Sender Address Line 1" id="shipping-address1" value={order.sender_address.line1 ?? ""} onChange={(event) => {updateAddress("sender", "line1", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Sender Address Line 2" placeholder="Sender Address Line 2" id="shipping-address2" value={order.sender_address.line2 ?? "" } onChange={(event) => {updateAddress("sender", "line2", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Sender City" placeholder="City" id="shipping-city" value={order.sender_address.city?? ""} onChange={(event) => {updateAddress("sender", "city", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Sender State" placeholder="State" id="shipping-state" value={order.sender_address.state ?? "" } onChange={(event) => {updateAddress("sender", "state", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Sender ZIP Code" placeholder="ZIP Code" id="shipping-postal-code" value={order.sender_address.zip ?? "" } onChange={(event) => {updateAddress("sender", "zip", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Sender Country" placeholder="Country" id="shipping-country" value={order.sender_address.country ?? "United States" } onChange={(event) => {updateAddress("sender", "country", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Sender Phone" placeholder="Shipping Phone" id="shipping-phone" value={order.sender_address.phone ??""} onChange={(event) => {updateAddress("sender", "phone", event.target.value)}} />
                <TextField className="form-input-field" fullWidth={true} label="Sender Email" placeholder="Shipping Email" id="shipping-phone" value={order.sender_address.email ??""} onChange={(event) => {updateAddress("sender", "email", event.target.value)}} />

                {/*  {order.shipping_address.country !== "US" && order.shipping_address.country !== "USA" && order.shipping_address.country !== "United States" && order.shipping_address.country !== "United States of America" &&
                    <div>
                        <h5>Customs Declaration for International Shipping</h5>

                    </div>
                } */}

                    <Button variant="contained" color="buttonDark" onClick={(e) => getShippingRates() }>Get Shipping Rates</Button>
                    <hr />
                    {shippingRates.rates !== undefined && shippingRates.rates.length >0 && 
                    <div>
                    {shippingRates.rates.map( (rate, index) => {
                        

                        return <Card key={rate.object_id} sx={{width: '100%', maxWidth: 350, marginBottom:"10px"}} elevation={2}>
                                <CardHeader title={rate.provider + " - " + rate.servicelevel.name} subheader={"$" + rate.amount} avatar={<img src={rate.provider_image_75} alt={rate.provider} width="75" />} />
                                
                                <CardActions>
                                
                                <Button variant="contained" color="buttonDark" onClick={(e) => createShippingLabel(rate) }>Purchase Label</Button>
                                </CardActions>
                            </Card>


                    })}
                    
                    
                       
                            </div>

                    }
                </Box>
            </TabPanel>
            <TabPanel name="notifications" activeTab={activeTab}>
            <Box sx={{border:"1px solid #ccc", padding:"5px 10px"}}>
                    <h4>Order Confirmation Email</h4>
                    <TextField fullWidth={true} className="form-input-field" label="Email Address" placeholder="Email Address" value={confirmationAddress ?? ""} type="text" onChange={ (e) => setConfirmationAddress(e.target.value)} />
                    <Button variant="contained" color="buttonDark" onClick={ () => {sendConfirmation(confirmationAddress) }} >Send Confirmation Email</Button>
                </Box>
                
                
                <Box sx={{border:"1px solid #ccc", padding:"5px 10px"}}>
                    <h4>Add Notes</h4>
                    <TextField fullWidth={true} className="form-input-field" value={newNote} onChange={(event) => { setNewNote(event.target.value)} } />
                    <Button variant="contained" color="buttonDark" onClick={ () => {addOrderNotes(newNote) }} >Add Note</Button>
                </Box>
                
            </TabPanel>
           

            {orderNotes.length > 0 &&
            <Container sx={{border:"1px solid #ccc",padding:"0px 10px 5px 10px",marginTop:"10px"}}>
                <h4>Notes</h4>
                <Box sx={{textAlign:"left"}}>
                        {
                                    orderNotes.map( (note, index) => {

                                        const date = note.date.format("dddd, MMMM Do YYYY, h:mm a");

                                        return (
                                        <div key={index} className="order-note">
                                            <div className="note-content">{note.note}</div>
                                            <div className="note-date">{note.name + " on " + date}</div>
                                        </div>
                                        )
                                    })

                                }
                </Box>
            </Container>
            }
          
           

          


        </div>
        { ((adminUsers.includes(sessionStorage.getItem("hash")) || sessionStorage.getItem("isAdmin") === "true" )&& props.navigation.getParam("viewonly") !== "true" && props.navigation.getParam("viewonly") !== true ) &&
        <div className="input-row form-actions">
                <Button variant="contained" color="primary" endIcon={ <SaveIcon /> } onClick={ saveOrder }>
                    Save
                </Button>
                
                &nbsp;&nbsp;&nbsp;
                <Button variant="contained" color="primary" endIcon={ <ClearIcon /> } onClick={ cancelUpdate  }>
                    Cancel
                </Button>
                <br />
                <span style={{fontSize:10, color:"#ff0000"}}>*Once the order is created, an order confirmation email will be sent to user automatically</span>

                </div>
    }
        </ConsoleTemplate>

        <Dialog maxWidth={"lg"} open={productSelectOpened} onClose={ () => { setProductSelectOpen(false)} } >
            <DialogTitle>Select Order Products</DialogTitle>
            <DialogContent>
                <div className="product-list">
                    <ImageList id="select-product-list" rowHeight={150} cols={products.length <= 3 ? products.length : 3}>
                        {products.map( (product, index) => {
                            return (<ImageListItem key={product.id}>
                                <img src={product.image1} alt={product.name} />
                                <ImageListItemBar title={product.name} subtitle={"$"+product.price} actionIcon={ <IconButton onClick={() => { addProductToOrder(product.id) }} >
                                    <AddCircleIcon style={{color:"#fff"}}/>
                                </IconButton>} />                       
                            </ImageListItem>);
                        })}
                    </ImageList>

                </div>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={ () => setProductSelectOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>

        </LocalizationProvider>
        );
}

export default OrderEditView;