import React, {useEffect, useState, useRef} from 'react'

import ConsoleTemplate, {adminUsers, imagePlaceholder} from '../components/consoleTemplate'
import moment, { now } from 'moment'
import firebase from '../components/firebase'
import { getAuth} from "firebase/auth";

import { FormControl, InputLabel, NativeSelect, Box, Card,  Typography, CardHeader, CardActions, CardContent,Container, Tabs, Tab,Grid, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Avatar,  Button, IconButton, TextField,  ImageList, ImageListItem, ImageListItemBar, Switch, FormControlLabel, Dialog, DialogContent, DialogActions, DialogTitle, Input, Select, ListItemButton} from '@mui/material'
import { onSnapshot, getFirestore, writeBatch, getDoc, getDocs, addDoc, setDoc, collection, doc, query, where, orderBy, limit, Timestamp, batch, or, and } from 'firebase/firestore';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {saveAs} from 'file-saver'
import TabPanel from '../components/tabPanel'
import LiveEventProductItem from '../components/liveEventProductItem';
import DeleteForeverIcon  from '@mui/icons-material/DeleteForeverRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentIcon from'@mui/icons-material/Payment';
//import DeleteForever from '@mui/icons-material/DeleteForever';
//import EditIcon from '@mui/icons-material/Edit';
import PushPinIcon from '@mui/icons-material/PushPin';
import BlockIcon from '@mui/icons-material/Block';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadingIcon from '@mui/icons-material/DownloadingOutlined';
import CheckMark from '@mui/icons-material/CheckCircle';
import FirebaseFileUpload from '../components/firebase-fileupload';
import { ConstructionOutlined } from '@mui/icons-material'


const axios = require('axios').default;
const db = getFirestore();


const LiveEventView = (props) => { 

    const agoraAppId = "9d46e7a108ce4f638d739b6644e65737";

    const eventid = props.navigation.getParam("id");
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info");
    const [alertMessage, setAlertMessage] = useState("");
    const [activeTab, setActiveTab] = useState("live");
    const [brands, setBrands] = useState([]);
    const [productBrandId, setProductBrandId] = useState("");
    const [qtyToCreate, setQtyToCreate] = useState(1);
    const [directToAuction, setDirectToAuction] = useState(true);
    const [resourceId, setResourceId] = useState("");
    const [recordingUid, setRecordingUid] = useState("");
    const [recordingId, setRecordingId] = useState("");
    
    

    const [event, setEvent] = useState({
        products : [],
        productsInAuction: [],
        liveShippingLocal: 8,
        liveShippingIntl: 25,
        isClaimMode: false,
    });
    const [chatLog, setChatLog] = useState([]);
    const [chatMessage, setChatMessage] = useState("");
    const [showDeletedChat, setShowDeletedChat] = useState(false);
    const [inviteCode, setInviteCode] = useState("");
    const [eventOrders, setEventOrders] = useState([]);
    const [incompleteOrders, setIncompleteOrders] = useState([]);
    //const [totalSale, setTotalSale] = useState(0);
    const [liveCount, setLiveCount] = useState(0);
    const [guestCount, setGuestCount] = useState(0);
    const [memberCount, setMemberCount] = useState(0);
    const [activeProducts, setActiveProducts] = useState([]);
    const [auctionSelectOpen, setAuctionSelectOpen] = useState(false);
    const [productSelectOpen, setProductSelectOpen] = useState(false);
    const [bulkCreateOpen, setBulkCreateOpen] = useState(false);
    const [productSearchText, setProductSearchText] = useState("");
    const [auctionProduct, setAuctionProduct] = useState( {} );
   // const [isClaimMode, setIsClaimMode] = useState(false);
    const [startingBid, setStartingBid] = useState(1);
    const [reserve, setReserve] = useState(0);
    const [buynow, setBuynow] = useState(0);
    const [minbid, setMinbid] = useState(1);
    const [shippingLocal, setShippingLocal] =  useState(0);
    const [shippingIntl, setShippingIntl] = useState(-1);
    const [auctionTime, setAuctionTime] = useState(5);
    //const [auctionStartTime, setAuctionStartTime] = useState(new Date());
    const [auctionExpectedStopTime, setAuctionExpectedStopTime] = useState(new Date());
    const [auctionStopTime, setAuctionStopTime] = useState(new Date());
    //const [auctionCountdown, setAuctionCountdown] = useState(0);
    const [isAuctionStarted, setIsAuctionStarted] = useState(false);
    const [countdownDisplay, setCountdownDisplay] = useState("5:00");
    
    const [editProductId, setEditProductId] = useState("");
    const [editProductInAuction, setEditProductInAuction] = useState(false);
    const [editProductName, setEditProductName]= useState("");
    const [editProductDescription, setEditProductDescription] = useState("");
    const [editProductPrice, setEditProductPrice] = useState(10);
    const [editProductSize, setEditProductSize] = useState("DEFAULT");
    const [editProductInventory, setEditProductInventory] = useState(1);
    const [editProductImage, setEditProductImage ] = useState(imagePlaceholder);
    const [editProductShippingLocal, setEditProductShippingLocal] = useState(shippingLocal);
    const [editProductShippingIntl, setEditProductShippingIntl] = useState(shippingIntl);
    const [eventVendors, setEventVendors] = useState([]);
    const [localShipping, setLocalShipping] = useState(8);
    const [intlShipping, setIntlShipping] = useState(25);
    const [giveawayFreeShipping, setGiveawayFreeshipping] = useState(true);
    const [isClaimHidden, setIsClaimHidden] = useState(false);
    const [isDonateHidden, setIsDonateHidden] = useState(false);
    const [filterChat, setFilterChat] = useState(false);
    const [chatSearchTerm, setChatSearchTerm] = useState("");
    

    var statsTimerId = useRef(0);
    var auctionTimerId = useRef(0);
    var auctionCountdown = useRef(0);
    var auctionStartTime = useRef(null);
    var auctionStopped = useRef(false);
    var auctionEndTime = useRef(null);
    var eventSalesTotal = useRef(0);


   /*  var editProductId = useRef("");
    var updatedProductName = useRef("");
    var updatedProductDesc = useRef("");
    var updatedProductPrice = useRef(0);
    var createdProductCount = useRef(0); */
  
    useEffect ( () => {
   

        const getEventStats = async () => {
            console.log("get stats " + Date.now());
            const statsResult = await getDocs(query(collection(db, "event-stats"), where("eventid", "==", eventid)));
            if(!statsResult.empty) {
                var guests = 0;
                var members = 0;
                var live = 0;
    
                const stats = statsResult.docs; 
                stats.forEach( (stat, index) => {
                    const statData =stat.data();
                    if(statData.uid === "guest") {
                        guests += 1;
                    } else {
                        members += 1;
                    }

                //    console.log("last updated");
                 //   console.log(statData["last_updated"].toDate());
                  //  console.log("logout time");
                  //  console.log(statData["logout"] !== "" ? statData["logout"] : "ACTIVE");

                    if(statData["logout"] === "") {
                        //console.log("date strings");
                        //console.log(Date());
                        //console.log( statData["last_updated"].toDate());
                        const sinceUpdate = Math.ceil(Math.abs(Date.now() - statData["last_updated"].toDate().getTime()) / (1000*60));
                        //console.log("since update");
                        //console.log(sinceUpdate);
                        if(sinceUpdate < 3){
                            live += 1;
                        }
                    }

                   
    
    
                });
                setGuestCount(guests);
                setMemberCount(members);
                setLiveCount(live);
    
            }
            const orderResult = await getDocs(query(collection(db, "orders"), where("eventid", "==", eventid), orderBy("order_date", "asc")));
            if(!orderResult.empty) {
                const orders = orderResult.docs;
                var loadedOrders = [];
                var total = 0;
                orders.forEach ( (order, index) => {
                    const orderData = order.data();
                    orderData.id = order.id;
                    total += parseFloat(orderData.total);
                    loadedOrders.push(orderData);
                });
                eventSalesTotal.current = total;
                console.log("event orders");
                console.log(loadedOrders);
                setEventOrders(loadedOrders);
    
            }

            const incompleteResult =  await getDocs(query(collection(db, "pending-orders"), where("eventid", "==", eventid), orderBy("order_date", "desc")));
            if(!incompleteResult.empty) {
                const orders = incompleteResult.docs;
                var incomplete = [];
                //var total = 0;
                console.log("incomplete orders");

                orders.forEach ( (order, index) => {
                    const orderData = order.data();
                    orderData.id = order.id;
                   // total += parseFloat(orderData.total);
                   
                   if(orderData.products.length > 0) {
                    console.log(orderData);
                    incomplete.push(orderData);
                   }
                });
                //eventSalesTotal.current = total;
    
                setIncompleteOrders(incomplete);
    
            }
        }

        getEventStats();
        statsTimerId.current = setInterval(getEventStats, 120000);
        getActiveProducts();

    }, []);

    useEffect ( () => () => {
        clearInterval(statsTimerId.current);
        //clearInterval(auctionTimerId.current);
    }, [])

    useEffect ( () => {
        const eventUnSub = onSnapshot(doc(db, "events", eventid), (eventSnapshot) => {
            if(eventSnapshot.exists) {
              //  console.log("refresh event data");
                
                const eventDoc = eventSnapshot.data();
                eventDoc.id = eventSnapshot.id;
               //console.log(eventDoc.products);
                setEvent(eventDoc);
                
                setLocalShipping(eventDoc.liveShippingLocal);
               // console.log("intl shipping : " + eventDoc.liveShippingIntl);
                setIntlShipping(eventDoc.liveShippingIntl);
                if(eventDoc.isArchive) {
                    setActiveTab("stats");
                }
                if(eventDoc.invteCode !== "") {
                    setInviteCode(eventDoc.inviteCode);
                }
                const vendors = [];
                const hostObj = {
                    uid: event.host,
                    name: event.hostname,
                    email: event.hostEmail,
                    avatar: event.hostAvatar
                 }
                 vendors.push(hostObj);

                 if(eventDoc.guestHosts.length > 0) {
                    for(var i=0; i<eventDoc.guestHosts.length; i++){
                        const guestObj = {
                            uid: eventDoc.guestHosts[i].uid,
                            name: eventDoc.guestHosts[i].name,
                            email: eventDoc.guestHosts[i].email,
                            avatar: eventDoc.guestHosts[i].image,
                        }
                        vendors.push(guestObj);
                    }
                 }

                 setEventVendors(vendors);

                

            }
        });
      
        const chatQuery = query(collection(db, "event-chat"), where("eventid", "==", eventid), orderBy("timestamp", "desc"), limit(500));

       

        const chatUnsub = onSnapshot(chatQuery, (chatSnapshot) => {
            if(!chatSnapshot.empty) {
                const messages = [];
                const chats = chatSnapshot.docs;

                chats.forEach( (chat, index) => {
                    const chatData = chat.data();
                    chatData.id = chat.id;
                    messages.push(chatData);
                });
                //console.log(messages);
                setChatLog(messages.reverse());
                var ele = document.getElementById("chat-log");
                if (ele !== null ){
                  
                 ele.scrollTop = ele.scrollHeight;
                }
 
            }
        } );

    }, [db]);


    useEffect( () => {
        if(event.isAuction && event.activeAuctionProduct !== "" ) {
            const auctionUnsub = onSnapshot(doc(db, "auctions/"+eventid+"/products", event.activeAuctionProduct), (auctionSnapshot) => {
              //  console.log(auctionSnapshot.exists());
                if(auctionSnapshot.exists()) {
                    //console.log(auctionSnapshot);
                    const auctionData = auctionSnapshot.data();
                //    console.log(auctionData);
                    auctionData.id = auctionSnapshot.id;
                    if (auctionData.bid_history !== undefined && auctionData.bid_history.length > 0) {
                        auctionData.bid_history = auctionData.bid_history.reverse();
                    }
                   
                    setAuctionProduct(auctionData);
                    setStartingBid(auctionData.starting_bid);
                    setReserve(auctionData.reserve);
                    setMinbid(auctionData.minbid);
                    setBuynow(auctionData.buynow);
                    setShippingIntl(event.liveShippingIntl);
                    setShippingLocal(event.liveShippingLocal);
                    setAuctionTime(Math.ceil(auctionData.duration/60));

                    auctionStartTime.current = auctionData.start_time !== "" ? auctionData.start_time.toDate() : "";
                    setAuctionExpectedStopTime(auctionData.expected_stop_time !== "" ? auctionData.expected_stop_time.toDate() : Date.now());
                    auctionEndTime.current = auctionData.expected_stop_time !== "" ?  auctionData.expected_stop_time.toDate() : Date.now();
                    setAuctionStopTime(auctionData.stop_time !== "" ? auctionData.stop_time.toDate() : "" );
                    auctionStopped.current = (auctionData.stop_time === "" && auctionData.start_time !== "") ? false:  true;
                    setIsAuctionStarted(!auctionStopped.current);
                   /*  if(!auctionStopped) { 
                    auctionTimerId.current = setInterval(updateCountdownTimer, 1000);
                    } */

                }
            })
       }
    }, [ event.activeAuctionProduct, eventid, event.isAuction, event.liveShippingLocal, event.liveShippingIntl])

    useEffect ( () =>{
        getBrands();
    }, []);

    const getBrands = async () => {
        try {
            var result; 
            //if (adminUsers.includes(sessionStorage.getItem("hash"))) {
                result = await getDocs(query(collection(db, "brands"), where("isActive", "==", true), orderBy("name", "asc")));
               
          //  } else {
              //  result = await getDocs(query(collection(db, "brands"), where("isActive", "==", true), where("users", "array-contains", sessionStorage.get("uid")), orderBy("name", "asc")));
              
           // }
            if(result.size > 0) {
                const docs = result.docs;
                var brandResults = [];
                docs.forEach( (item) => {
                    const data = item.data();
                    data.id = item.id;
                    brandResults.push(data);
                });
              

                setBrands(brandResults);
            }


        }catch (err) {
            console.log(err)
        }
    }

    const onBrandChange = (e) => {
       // setState({...state, brand: e.target.value});

       console.log(e.target.value);
       setProductBrandId(e.target.value);
    }




    const getActiveProducts = async () => {
        try {
            const productResult = await getDocs(query(collection(db, "products"), where("isActive", "==", true), orderBy("name", "asc") ));

            if(!productResult.empty) {
                const productDocs = productResult.docs;
                const products = [];
                productDocs.forEach( (product, index) => {
                    const productData = product.data();
                    productData.id = product.id;
                    console.log("host");
                    console.log(productData.hosts);
                    
                    if (adminUsers.includes(sessionStorage.getItem("hash")) || productData.hosts.includes(sessionStorage.getItem("uid") ) || productData.createdByUid === sessionStorage.getItem("uid") ) {
                    products.push(productData);
                    }
                });
             //   console.log("active products");
              //  console.log(products);
                setActiveProducts(products);
            }

        } catch( err) {
            setAlertType("error");
            setAlertMessage("Unable to retrieve product information, please check and try again. \n"+err.message);
            setShowAlert(true);
           // setIsLoading(false);
        }
    }


    

    const updateChatStatus = async (chatid, status) => {
        try {
            setIsLoading(true);
            await setDoc(doc(db, "event-chat", chatid), {deleted:status}, {merge:true});

            setIsLoading(false);
        } catch(err) {
            setAlertType("error");
            setAlertMessage("Unable to update message status, please check and try again. \n"+err.message);
            setShowAlert(true);
            setIsLoading(false);
        }
    }

    const updatePinChat = async (chatid, timestamp, status) => {
        try {
            setIsLoading(true);

            var data = status ? {
                "isPinned": status,
                "timestamp": new Date("31 Dec 2099 11:59:59 GMT"),
                "original_timestamp" : timestamp
             } : {
                 "isPinned" : status,
                 "timestamp" : timestamp,
             }

            console.log("set chat " +chatid);
            console.log(data);
            await setDoc(doc(db, "event-chat", chatid), data, {merge:true});
            setIsLoading(false);
        }catch(err) {
            setAlertType("error");
            console.log(err);
            setAlertMessage("Unable to update message status, please check and try again. \n"+err.message);
            setShowAlert(true);
            setIsLoading(false);
        }
    }


    
    const sendChatMessage = async () => {
        try {
        if(chatMessage !== "") {
            const messageObj = {
                sender: sessionStorage.getItem("displayname"),
                senderid: sessionStorage.getItem("uid"), 
                sender_avatar: sessionStorage.getItem("avatar"),
                sender_badge: sessionStorage.getItem("badge"),
                sender_verified: sessionStorage.getItem("isVerified") ==="true" ? true : false,
                message: chatMessage,
                eventid: eventid,
                timestamp: new Date(),
                deleted: false
            }
            await addDoc(collection(db, "event-chat"), messageObj);
           // console.log(addResult);
           // console.log(messageObj);
            setChatMessage("");
            
        }

        }catch (err) {
            setAlertMessage(err.message);
            setAlertType("error");
            setShowAlert(true);
        }
    }

    const updateEventClaimButton = async (isHidden) =>{
        try {
            setIsLoading(true);
            await setDoc(doc(db, "events", eventid), {isClaimHidden:isHidden}, {merge:true});
            setIsLoading(false);
        } catch (err){
            setAlertType("error");
            setAlertMessage("Unable to update button status, please check and try again. \n"+err.message);
            setShowAlert(true);
            setIsLoading(false);
        }
    }  

    const updateEventDonateButton = async (isHidden) => {
        try {
            setIsLoading(true);
            await setDoc(doc(db, "events", eventid), {isDonateHidden:isHidden}, {merge:true});
            setIsLoading(false);

        } catch (err){
            setAlertType("error");
            setAlertMessage("Unable to update button status, please check and try again. \n"+err.message);
            setShowAlert(true);
            setIsLoading(false);
        }
    }

    const updateOnlyVerified = async (onlyVerified) => {
        try {
            setIsLoading(true);
            await setDoc(doc(db, "events", eventid), {onlyVerified:onlyVerified}, {merge:true});
            setIsLoading(false);

        } catch (err){
            setAlertType("error");
            setAlertMessage("Unable to update button status, please check and try again. \n"+err.message);
            setShowAlert(true);
            setIsLoading(false);
        }
    }

    const updateEventStatus = async (updateObj) => {
        try {
            setIsLoading(true);
            
         
            await setDoc(doc(db, "events", eventid), updateObj, {merge: true});
            setIsLoading(false);
        } catch (err) {
            setAlertType("error");
            setAlertMessage("Unable to update event status, please check and try again. \n"+err.message);
            setShowAlert(true);
            setIsLoading(false);
        }
    }
    
    const onBlockUser = async (userid, isBlocked) =>{
        try {
        setIsLoading(true);
        // set user profile isBlocked
        await setDoc(doc(db, "profiles", userid), {isBlocked: isBlocked}, {merge:true});
      // add to restricted_user collection
        await setDoc(doc(db, "restricted_users", userid), {uid: userid, banned_by: sessionStorage.getItem("uid"), banned_date: new Date(), reason: "Banned during event " + event.name }, {merge:true});
      // remove all user chat messages
            
        const chatResult = await getDocs(query(collection(db, "event-chat"), where("senderid", "==", userid), where("eventid", "==", eventid)), orderBy("timestamp", "desc"),limit(500));
        console.log(chatResult);
        if(chatResult.docs.length > 0) {
            const batch = writeBatch(db);
            for(var i=0; i<chatResult.docs.length; i++ ){
               // db.a
               batch.set(doc(db, "event-chat", chatResult.docs[i].id), {deleted: true}, {merge:true});
               //console.log(chatResult.docs[i].id);
            }
            // batch update chat messages
            batch.commit();
        }
        
      // block user firebase account    
      //const auth = getAuth();

            

        setIsLoading(false);

        } catch (err){
            setAlertType("error");
            setAlertMessage("Unable to block user, please try again later");
            console.log(err);
            setShowAlert(true);
            setIsLoading(false);
        }
    }

    const onAddProduct = async (productid, toAdd, isAuction) => {
        toAdd ? console.log("add " + productid) : console.log("remove " + productid);
        var products = isAuction ? event.productsInAuction : event.products;
       
       

        if(toAdd) {
            products.push(productid);

            if(isAuction) {
                const auctionDefaults = {"start": 1, "reserve": 0, "buynow":0, "minbid":5, "timer":10};
                const auctionDetails = event.auctionProductDetails;
                auctionDetails[productid] = auctionDefaults;
               
                await setDoc(doc(db, "events", eventid), {productsInAuction: products, auctionProductDetails: auctionDetails }, {merge:true});
            } else {
                await setDoc(doc(db, "events", eventid), {products: products}, {merge:true});
            }

        } else {  
            products.splice(products.indexOf(productid), 1);

            if(isAuction) {
                const auctionDetails = event.auctionProductDetails;
                delete auctionDetails[productid];
               
                await setDoc(doc(db, "events", eventid), {productsInAuction: products, auctionProductDetails: auctionDetails }, {merge:true});
            } else {
                await setDoc(doc(db, "events", eventid), {products: products}, {merge:true});
            }
        }
       
        

    }

    const onProductSearch = (productName) => {
        setProductSearchText(productName);
    }

    const setActiveAuctionProduct = async ( productid ) => {
        setIsLoading(true);
        try {
            const batch = writeBatch(db);
            batch.set( doc(db, "auctions", eventid), {
                host: event.host,
                hostname: event.hostname,
                hostAvatar: event.hostAvatar,
                hostEmail: event.hostEmail,
            }, {merge:true});
            if(productid === event.activeAuctionProduct) {
                batch.update(doc(db, "events", eventid), {activeAuctionProduct: ""});
                batch.update(doc(db, "auctions", eventid), {activeAuctionProduct: ""});
                //await setDoc(doc(db, "events", eventid), {activeAuctionProduct:""}, {merge:true});
                //await setDoc(doc(db, "auctions", eventid), {activeAuctionProduct:""}, {merge:true});
            } else {
                var product;
                for(var i=0; i< activeProducts.length; i++) {
                    if(productid === activeProducts[i].id) {
                        product = activeProducts[i];
                    }
                }
                if(product) {
                    
                    const auctionDefaults = {    
                        "name": product.name,
                        "image1": product.image1,
                        "description":product.description,
                        "size": product.size[0].size,
                        "productid": product.id,
                        "starting_bid": 1,
                        "buynow":0,
                        "reserve": 0,
                        "minbid":5,
                        "duration":300,
                        "highest_bidder" :"",
                        "highest_bidder_uid": "",
                        "highest_bidder_pmid":"",
                        "highest_bid": 0,
                        "highest_bid_time": "",
                        "processed" : false,
                        "order_number":"",
                        "bid_history": [],
                        "shippingLocal":parseInt(localShipping) >=0  ? parseInt(localShipping)  : -1,
                        "shippingIntl": parseInt(intlShipping) ? parseInt(intlShipping) : 0,
                        "start_time":"",
                        "stop_time":"",
                        "expected_stop_time":"",
                    };

                    
                    console.log(auctionDefaults);

                    const auctionProductResult = await getDoc(doc(db, "auctions/"+eventid+"/products", productid));

                    if(auctionProductResult.exists()) {
                        const auctionProduct = auctionProductResult.data();
                        if(auctionProduct.bid_history) {
                            auctionDefaults.bid_history = auctionProduct.bid_history;
                        }
                    }

                    
                    batch.update(doc(db, "events", eventid), {activeAuctionProduct:productid});
                    batch.update(doc(db, "auctions", eventid), {activeAuctionProduct:productid});
                    batch.set(doc(db, "auctions/"+eventid+"/products", productid), auctionDefaults);
                }

            }
            batch.commit();
            setIsLoading(false);
        } catch(err){
            setIsLoading(false);
            setAlertMessage(err.message);
            setAlertType("error");
            setShowAlert(true);     
        }
    }

    const onDragEnd = async (result ) => {
        try {
        console.log("drag end");
        if(!result.destination) {
            return false;
        }

        const items = reorder(
            event.products,
            result.source.index,
            result.destination.index,
        );

        setEvent({...event, products: items});
        setIsLoading(true);
        await setDoc(doc(db, "events", eventid), {products: items}, {merge:true});

            setIsLoading(false);
        //console.log(items);
        }catch(err){
            setIsLoading(false);
            setAlertMessage(err.message);
            setAlertType("error");
            setShowAlert(true);     
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] =result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }

    const updateAuctionProduct = async (data) => {
        try { 
            await setDoc(doc(db, "auctions/" + eventid +"/products", event.activeAuctionProduct ), data, {merge:true} );
        } catch(err){
           
            setAlertMessage(err.message);
            setAlertType("error");
            setShowAlert(true);     
        }

    }

    const startAuction = async (data) => { 
        try {
            if(event.activeAuctionProduct) {
                const countdownTime = auctionTime * 60;
                const startTime = Timestamp.now();
                const startMoment = moment(startTime.toDate());
                const endMoment = startMoment;
                endMoment.add(countdownTime, "seconds");
               
               // console.log(startMoment);

                const data = {
                    "start_time" : startTime.toDate(),
                    "expected_stop_time": endMoment.toDate(),
                    "stop_time": "",
                    "starting_bid": parseInt(startingBid),
                    "reserve":parseInt(reserve),
                    "minbid":parseInt(minbid),
                    "buynow":parseInt(buynow),
                    "duration":parseInt(countdownTime),
                    "shippingLocal": parseInt(shippingLocal),
                    "shippingIntl" : parseInt(shippingIntl),

                }

                await setDoc(doc(db, "auctions/" + eventid+"/products", event.activeAuctionProduct), data, {merge:true});

                //setAuctionStartTime(startMoment.toDate());
                auctionStartTime.current = startTime.toDate();
                setAuctionExpectedStopTime(endMoment.toDate());
                auctionEndTime.current = endMoment.toDate();
                //setAuctionCountdown(countdownTime.current);
                auctionCountdown.current = countdownTime;
                console.log("startCountdown " + auctionCountdown.current);
                auctionTimerId.current = setInterval(updateCountdownTimer, 1000);

                setIsAuctionStarted(true);

            }
        } catch(err){
            setIsLoading (false);
            console.log(err.message);
            setAlertMessage("Unable to start the auction. Please try again later ");
            setAlertType("error");
            setShowAlert(true);
        }
    }

    const updateCountdownTimer = async () => {
        
        
        //console.log(startTime + " / " + currentTime);

        auctionCountdown.current -= 1;

        const currentTime = Timestamp.now().toDate();
        const startTime = auctionStartTime.current;
        const sinceStart = Math.floor(currentTime/1000) - Math.floor(startTime/1000); //Math.floor((currentTime - startTime)/1000);
       // const endTime = auctionExpectedStopTime;
        const endTime = auctionEndTime.current;

        console.log("start: " + startTime + " / end:" + endTime);
        //const t = Math.floor(endTime/1000) - Math.floor(startTime/1000) - sinceStart;
        //console.log("remaining : " + t);

       // const remaining = auctionTime*60 - sinceStart;
       const remaining =Math.floor(endTime/1000) - Math.floor(Timestamp.now().toDate()/1000);
      

        setCountdownDisplay(timeDisplay(remaining));
        
       // console.log(auctionCountdown.current);
        if(remaining <= 0 ) {
            console.log("Time is up");
            clearInterval(auctionTimerId.current);
            endAuction();
            if(!event.isClaimMode) {
                processAuctionOrder(event.activeAuctionProduct);
            }
        }
    }

    const endAuction = async () => {
        try {
           
           // if(isAuctionStarted) {
                console.log("end auction")
                setIsAuctionStarted(false);
                const stopTime = new Date();
                const data = { 
                    "stop_time" : stopTime,
                };

                await setDoc(doc(db, "auctions/" + eventid+"/products", event.activeAuctionProduct), data, {merge:true});
                auctionStopped.current = true;
                setAuctionStopTime(stopTime);
                setIsAuctionStarted(false);
                clearInterval(auctionTimerId.current);
          //  }

        } catch (err){
            setIsLoading (false);
            console.log(err.message);
            setAlertMessage("Unable to stop the auction. Please try again later ");
            setAlertType("error");
            setShowAlert(true);
        }
    }

    const processAuctionOrder = async (pid) => {
        try {
            setIsLoading(true);
            
            const auctionProductSnapshot = await getDoc(doc(db, "auctions/"+eventid+"/products", pid));

            if(auctionProductSnapshot.exists()) {
                const productData = auctionProductSnapshot.data();
                if(productData["highest_bidder_uid"]) {

                    sendOrder(pid,productData["highest_bidder_uid"], parseInt(productData["highest_bid"]), false, productData["highest_bidder_pmid"] );

                }

            } else {
                setIsLoading(false);
                setAlertMessage("Unable to load the product data. Please check your connection and try again");
                setAlertType("error");
            }

            setIsLoading(false);
        } catch(err) {
            setIsLoading(false);
            setAlertMessage(err.message);
            setAlertType("error");
            setShowAlert(true);
            
        }
    }



    const clearAuctionBids = async (pid) => {
        try {
            setIsLoading(true);
            const data = {
                bid_history: [],
                highest_bid : 0,
                highest_bid_time: "",
                highest_bidder: "",
                highest_bidder_uid: "",
                highest_bidder_pmid: "",
                total_bid: 0,
                processed: false,
                order_number: "",
            }

            await  setDoc(doc(db, "auctions/"+eventid+"/products", pid), data, {merge:true});
            setIsLoading(false);

        } catch (err){
            setIsLoading(false);
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("Error while clearning bids, please try again later.");
        }
    }
    
    

    const timeDisplay = (seconds) => {
        var s = "00";
        var m = 0;
        

        m = Math.floor(seconds /60);
        var sInt = seconds%60;
        s = sInt < 10 ? "0"+sInt : sInt.toString();


        return m + ":" + s;
    }
    
    const setAuctionWinner = async (pid, uid, username, pmid, bidtime, price) => {
        setIsLoading(true);
        try {
            await setDoc(doc(db, "auctions/"+eventid+"/products", pid), {"highest_bid": price, "highest_bidder":username, "highest_bidder_uid": uid, "highest_bidder_pmid": pmid, "highest_bid_time": bidtime, "processed": false }, {merge:true});

            setIsLoading(false);
        } catch (err) {
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("Unable to set auction winner. Please check and try again");
            setIsLoading(false);
        }
    }

    const quickCreateProducts = async () => {
        
        try {
            
            setIsLoading(true);
            var defaultImage = imagePlaceholder;
            var defaultBrand = "";

            const productObj = {
                id: "",
                sku: "",
                name: "",
                description: "",
                brand: "",
                image1: "",
                image2: "",
                image3: "",
                size: [{"size" : "DEFAULT", "inventory": 1}],
                isActive: true,
                category:"",
                events: [],
                hosts: [sessionStorage.getItem("uid")],
                tags: [],
                weight: 0.5,
                price: 100,
                originalPrice: 100,
                limitPerUser: 0,
                boxWidth:9,
                boxHeight: 1,
                boxLength: 15,
                order: 1,
                shippingCost: event.liveShippingLocal !== null ? event.liveShippingLocal : -1,
                freeShipping: event.liveShippingLocal === 0,
                intlFreeShipping: event.liveShippingIntl === 0,
                intlShippingCost: event.liveShippingIntl,
                createdByUid: sessionStorage.getItem("uid"),
                createdByName: sessionStorage.getItem("displayname"),
                createdOn: new Date(),

            }

            if(productBrandId !== "") {
                for(var i=0; i<brands.length; i++) {
                    if(brands[i].id === productBrandId) {
                        defaultBrand = brands[i].name;
                        defaultImage = brands[i].image !== "" ? brands[i].image : imagePlaceholder;
                        break;
                    }
                }
            } 
            console.log("create " + qtyToCreate + " products of brand " + defaultBrand +" with image " + defaultImage);
            
            
            for(var j=0; j< qtyToCreate; j++){
                const newProduct = productObj;
                newProduct.name = "Product #"+(j+1);
                newProduct.brand = defaultBrand;
                newProduct.image1 = defaultImage;
                newProduct.description = "This product is up for claim";
                
                const product = await addDoc(collection(db, "products"), newProduct);
                if(directToAuction){
                    onAddProduct(product.id, true, true);
                } else{
                    onAddProduct(product.id, true, false);
                }
                
                
                if(j === (qtyToCreate-1)) {
                    getActiveProducts();
                    setShowAlert(true);
                    setAlertType("success");
                    setAlertMessage((j+1) + " products have been added successfully.");
                    setIsLoading(false);
                    setBulkCreateOpen(false);
                }
                

            }
           // setIsLoading(false);
         } catch (err) {

            
            console.log("Error creating new products");
            console.log(err);
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("There was an error creating new products. Please try again later");
            setIsLoading(false);
        }
 
        
    }

    const addAuctionTime = async (sec) => {
        try {
            setIsLoading(true);
            
            const auctioningProduct = await getDoc(doc(db, "auctions/"+eventid+"/products", event.activeAuctionProduct));
            if(auctioningProduct.exists){
                const auctioningProductData = auctioningProduct.data();
                const endingTime = moment(auctioningProductData.expected_stop_time.toDate());
               //console.log("end time");
               // console.log(endingTime);
               const endTimestamp = moment(endingTime).add(sec, "seconds");
               //console.log("new ending time");
               //console.log(endTimestamp);
               auctionEndTime.current = endTimestamp.toDate();
               setAuctionExpectedStopTime(endTimestamp.toDate());
               const data = {
                "expected_stop_time": endTimestamp.toDate(),
               }
               //await setDoc(doc(db, "auctions/"+eventid+"/products", event.activeAuctionProduct), {"expected_stop_time": endTimestamp}, {merge:true});
               await setDoc(doc(db, "auctions/" + eventid+"/products", event.activeAuctionProduct), data, {merge:true});


               setShowAlert(true);
               setAlertType("success");
               setAlertMessage("Additional auction added successfully.");
            } else {
                console.log("product does not exist");
                setShowAlert(true);
                setAlertType("error");
                setAlertMessage("Unable to add auction time. Please try again later");
            }

            setIsLoading(false);
        }catch(err){
            console.log(err);
            setIsLoading(false);
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("Unable to add auction time. Please try again later");
        }
    }

    const onUpdateProduct = async (product) => {
        try{
            const productObj = {
                name: editProductName,
                description: editProductDescription,
                image1: editProductImage,
                price: parseInt(editProductPrice),
                originalPrice: parseInt(editProductPrice),
                shippingCost: parseInt(editProductShippingLocal),
                freeShipping: parseInt(editProductShippingLocal) === 0 ? true : false,
                intlShippingCost: parseInt(editProductShippingIntl),
                intlFreeShipping: parseInt(editProductShippingIntl) === 0 ? true : false,
                size: [{"size" : editProductSize, "inventory": parseInt(editProductInventory)}],
                updatedOn: new Date(),
                updatedByUid: sessionStorage.getItem("uid"),
                updateByName: sessionStorage.getItem("displayname"),
            }

            await setDoc(doc(db, "products", editProductId), productObj, {merge:true});
            getActiveProducts();
            setShowAlert(true);
            setAlertType("success");
            setAlertMessage("Product "+ editProductName +" has been updated successfully.");
            setEditProductId("");

        } catch(err){
            console.log("Unable to update product info");
            console.error(err);
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("Unable to update product. Please try again later.");

        }
    }

    

    const onRemoveImage = async (image, productid) => {
        setEditProductImage(imagePlaceholder);
    }

    const openProductEditDialog = async (productid, isAuction) => {
        setEditProductId(productid);
        setEditProductInAuction(isAuction);
        const productList = activeProducts;
        for (var i=0; i<productList.length; i++) {
           // console.log("product: " + productList[i]);
            if(productList[i].id === productid){
                setEditProductName(productList[i].name);
                setEditProductDescription(productList[i].description);
                setEditProductPrice(productList[i].price);
                setEditProductImage(productList[i].image1);
                setEditProductSize(productList[i].size[0].size);
                setEditProductInventory(productList[i].size[0].inventory);
                setEditProductShippingLocal(productList[i].shippingCost);
                setEditProductShippingIntl(productList[i].intlShippingCost);
            }
        }
    }

    const clearClaims = async () => {
        try {
            setIsLoading(true);
            await setDoc(doc(db, "events", eventid), {"products": []}, {merge:true});
            setShowAlert(true);
            setAlertType("success");
            setAlertMessage("All claims have been cleared successfully.");
            
        }catch(err){
            console.log("Unable to clear claim products");
            console.error(err);
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("Unable to clear claim products. Please try again later.");
        }
        setIsLoading(false);
    }

    const updateProductShipping = async () => {
        setIsLoading(true);
        try {
            var allProducts = event.products;
            allProducts = allProducts.concat(event.productsInAuction);

            var claimProducts = event.products;
            var auctionProducts = event.productsInAuction;
          //  console.log(allProducts);
           // console.log("free shipping " + parseInt(localShipping) === 0);
           
            const batch = writeBatch(db);
            batch.set(doc(db, "events", eventid), {
                "liveShippingLocal": parseInt(localShipping),
                "liveShippingIntl": parseInt(intlShipping),
                "freeShipping": parseInt(localShipping) === 0,
                "intlFreeShipping": parseInt(intlShipping) === 0,
            }, {merge:true});

            const productList = activeProducts;
            for(var i=0; i< allProducts.length; i++){
                batch.set(doc(db, "products", allProducts[i]), {"shippingCost": parseInt(localShipping), "intlShippingCost": parseInt(intlShipping), "freeShipping": parseInt(localShipping) === 0, "intlFreeShipping": parseInt(intlShipping) === 0}, {merge:true});
            }
            /*
             // auctions
             for(var i=0; i<auctionProducts.length; i++){
                batch.set(doc(db, "products", auctionProducts[i]), {"shippingCost": parseInt(localShipping), "intlShippingCost": parseInt(intlShipping), "freeShipping": parseInt(localShipping) === 0, "intlFreeShipping": parseInt(intlShipping) === 0}, {merge:true});
            }

            // claims
            for(var j=0; j<productList.length; j++){
                for(var k=0; k< claimProducts.length; k++){
                 if(productList[j].id == claimProducts[k] ) {
                   // console.log("product " + productList[j].id + " : " + productList[j].name + " shipping " + productList[j].shippingCost );
                    batch.set(doc(db, products, claimProducts[k]), { 
                        "shippingCost": claimProducts[k].price === 0 ? 0 : parseInt(localShipping),
                        "intlShippingCost": claimProducts[k].price === 0 ? 0 : parseInt(intlShipping),
                        

                    }, {merge: true});
                 }
                }
                
            }
                */  
           

            batch.commit();

            setShowAlert(true);
            setAlertType("success");
            setAlertMessage("Shipping rates successfully updated for " + allProducts.length + " products.");
           
            
        }catch(err){
          //  console.log("Unable to clear claim products");
            console.error(err);
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("Unable to update product shipping. Please try again later.");
        }
        setIsLoading(false);
    }

    const sendOrder =  async (pid, bidder_uid, price, buynow, pmid) => {
        try {
            setIsLoading(true);
            const data = {
                pid: pid, 
                uid: bidder_uid, 
                price: parseInt(price),
                buynow: buynow,
                eventid: eventid, 
                eventname: event.name,
                pmid: pmid,

            };
            console.log("Place Order");
            console.log(data);

            const orderResult = await axios.post("https://us-central1-livfieapp.cloudfunctions.net/placeAuctionOrder", data);
            var message = orderResult.data.message;
            console.log(orderResult);
            setAlertMessage(message);
            setAlertType("success");
            setShowAlert(true);
            
            
        }catch(err){
            setAlertMessage("Unable to place the order. Please try again later. \n" + err.message);
            setAlertType("error");
            setShowAlert(false);
            return false;
        }
        setIsLoading(false);
        return true;

    }

    const retryPlaceOrder  = async (pid, bidder_uid, price, pendingOrderId = "") => {
        setIsLoading(true);
        try{
            const pmidResult = await axios.post("https://us-central1-livfieapp.cloudfunctions.net/getCustomerCards", {"uid":bidder_uid});
            console.log("pmid result: ");
            console.log(pmidResult.data);
            if(pmidResult.data.status === "success"){
                //console.log(pmidResult.data.objects.);
                if(pmidResult.data.objects.length > 0) {
                    const paymentCard = pmidResult.data.objects[0].id;
                    const orderResult = sendOrder(pid, bidder_uid, price, false, paymentCard);
                    //if(pendingOrderId !== "" && orderResult) {
                      //  await setDoc(doc(db, "pending-orders", pendingOrderId), {"processed": true}, {merge:true});
                   // }



                } else {
                    setAlertMessage("Unable to get user's payment card. Please try again later.");
                    setAlertType("error");
                    setShowAlert(false);
                }
                
            } else {
                setAlertMessage("Unable to get user's payment card. Please try again later.");
                setAlertType("error");
                setShowAlert(false);
            }

        }catch(err) {
            setAlertMessage("Unable to place the order. Please try again later. \n" + err.message);
            setAlertType("error");
            setShowAlert(false);
        }

        setIsLoading(false);
    }

    const downloadChat = async () => {
        try {
            setIsLoading(true);
            
            const result = await getDocs(query(collection(db, "event-chat"), where("eventid", "==", eventid), orderBy("timestamp", "desc")));
    
           // console.log(result);
        var csvString = "Display Name, Message, Time, Deleted\n";
             if(!result.empty) {
                const chatData= result.docs;
               // console.log(subscriberData);
              
               chatData.forEach( (chatDoc) => {
               
                const chat = chatDoc.data();
                console.log(chat); 
                csvString += chat["sender"]+","+chat["message"]+","+chat["timestamp"].toDate()+","+chat["deleted"]+"\n";
               });
               saveAs( new Blob([ csvString ], {type: "text/csv;charset=utf-8"}), "chat-log-"+eventid+".csv");
              /*   const users = subscriberData["users"];
                var csvstring = "";
                
                users.forEach(( user) => {
           
                   csvstring += user["displayname"]+", "+user["email"]+","+user["date"].toDate()+"\n";
                })
                console.log(csvstring);
                saveAs( new Blob([ csvstring ], {type: "text/csv;charset=utf-8"}), eventname+".csv"); */
            } 

            setIsLoading(false);
        }catch (err){
            setAlertType("error");
            console.log(err);
            setAlertMessage("Unable to generate CSV File. Please try again");
            setShowAlert(true);
            setIsLoading(false);
        }
    
    }

    const startRecording = async () => {
        try {

            const  key = "483371da1d334a57aa5f45dea0e58101";
            const secret = "b030d33dee514d3aa54541a11853d333";
            const plainCredentials = key + ":" + secret;
            const encodedCredentials = Buffer.from(plainCredentials).toString("base64");

            //console.log("credentials:", encodedCredentials);

            const acquireUrl = "https://api.agora.io/v1/apps/" + agoraAppId + "/cloud_recording/acquire";
            const   headerConfig = { headers: {
                'Authorization': 'Basic ' + encodedCredentials,
                'Content-Type': 'application/json'
            }};

            const uid = (Date.now()%100000000).toString();
            setRecordingUid(uid);

            const acquireData = {
                "cname": eventid, 
                "uid": uid,
                "clientRequest":{
                    "resourceExpiredHour": 24,
                    "scene": 1,
                }
            };

      
            // acquire
            const acquireResult = await axios.post(acquireUrl, acquireData, headerConfig);
           
            let resourceID = "";
            if(acquireResult.status === 200) {
                resourceID = acquireResult.data.resourceId;
                setResourceId(resourceID);
            }

                //console.log("acquire result:", resourceID);
            
            const startUrl = "https://api.agora.io/v1/apps/"+agoraAppId +"/cloud_recording/resourceid/" + resourceID + "/mode/web/start";
           

            const startData = {
                "cname": eventid, 
                "uid": uid,
                "clientRequest":{
                   
                    "recordingFileConfig": {
                        "avFileType": ["hls", "mp4"],
                    },
                 
                   "storageConfig": {
                        "vendor": 6,
                        "region": 3,
                        ///"bucket": "livfie.appspot.com/live-recordings/"+eventid+"/"+uid,
                        //"accessKey": "GOOG1E4HINTGEMA7P7UJPQZGOBQR6T7SBW7QG3FUJFGDJVS3MY7OR35X32RVA",
                        //"secretKey": "oF4IRWz2cAnb6rGyGYdTuf9fw3xzDgLLXatrqXzz",
                        "bucket": "livfieapp.appspot.com/live-recordings/"+eventid+"/"+sessionStorage.getItem("uid"),
                        "accessKey": "GOOG1EDN3COJKUGS5Y6WCHGXMFVVTNZQIY6EX4J62DUEYX4SLUBFKT2B47YQY", //"GOOG1EBHSYT7RFEYQKRZSPYPPBVNJLRJSNGOASB3RDAWZY664YTTUHRG5G3HQ", 
                        "secretKey": "gqmbNuGvwGGkKKjQk9xLlZ0pYdwRFUAETGbh7Ez9", //"800j2ycvBbAtS/1fiLyVl6FVPOC5Qyx9Avn3dTKL", 
                        "filename_prefix": [eventid, sessionStorage.getItem("uid")],
                   },
                   "extensionServiceConfig": {
                    "errorHandlePolicy": "error_abort",
                    "extensionServices": [{
                        "serviceName": "web_recorder_service",
                        "errorHandlePolicy": "error_abort",
                        "serviceParam": {
                            "url": "https://watch.exchvnge.co/event?eventid="+eventid+"&host="+sessionStorage.getItem("uid"), 
                            "maxRecordingHour": 1,
                            "audioProfile": 0,
                            "videoWidth": 780,
                            "videoHeight": 1688,
                        }
                   }]
                    }
                }
            };

            const startResult = await axios.post(startUrl, startData, headerConfig);
            console.log(startResult);
            if(startResult.status === 200){
                setRecordingId(startResult.data.sid);
            }


        } catch(err) {
            console.error(err);
        }
    }

    const stopRecording = async () => {
        try {
            const key = "483371da1d334a57aa5f45dea0e58101";
            const secret = "b030d33dee514d3aa54541a11853d333";
            const plainCredentials = key + ":" + secret;
            const encodedCredentials = Buffer.from(plainCredentials).toString("base64");

            const resourceId2 = "QHh2-Y2svWABHvDdE00bYleD8tWWRH5tqnh_5OioWFFEayWvxvCbf96pgOG49BHJWRDVeJ2P7JPtg7iW0yHSybHpIcumnoDOe0Ge1vogeVNQ0u3MEVLC3OLGxqFS0x1Vu8Q7yhZp_9325BI9fYbQ140DqDzbAgQVHk80sklZoQBanarOlDCdM31_ri_M5X8QFJCdk6a3Ke2tzOqeeVTac9Bz0Iqe0rEBwnA_UWkcBr-iQOKkt4mPLuzEoiu94qp2";
            const recordingId2 = "d12a075cc54818efd5d9a7b93b6f80f2";
            const recordingUid2 = "84325109";

            const stopUrl = "https://api.agora.io/v1/apps/"+agoraAppId +"/cloud_recording/resourceid/" + resourceId2 + "/sid/" + recordingId2 + "/mode/web/stop";

            const   headerConfig = { headers: {
                'Authorization': 'Basic ' + encodedCredentials,
                'Content-Type': 'application/json',
            }};

             const stopData = {
                "cname": eventid,
                "uid": recordingUid2,
                "clientRequest": {
                    "async_stop":  false,
                }
             };

             const stopResult = await axios.post(stopUrl, stopData, headerConfig);
             console.log(stopResult);


        } catch(err){
            console.error( err);
        }
    }

    const queryRecording = async () => {
        try {
            const key = "483371da1d334a57aa5f45dea0e58101";
            const secret = "b030d33dee514d3aa54541a11853d333";
            const plainCredentials = key + ":" + secret;
            const encodedCredentials = Buffer.from(plainCredentials).toString("base64");

            const queryUrl = "https://api.agora.io/v1/apps/"+agoraAppId +"/cloud_recording/resourceid/" + resourceId + "/sid/" + recordingId + "/mode/web/query";

            const   headerConfig = { headers: {
                'Authorization': 'Basic ' + encodedCredentials,
                'Content-Type': 'application/json',
            }};

            const queryResult = await axios.post(queryUrl, {}, headerConfig);
             console.log(queryResult);

        } catch(err){
            console.error( err);
        }
    }
   

    return <ConsoleTemplate {...props} isLoading={isLoading} tab={0} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
    <h2>{event.name}</h2>
    <Container className="form-tabs">
        <Tabs value={activeTab} onChange={ (e, newTab) => setActiveTab(newTab)}>
            {!event.isArchive &&
            <Tab label="Live" value="live" /> 
        }
            <Tab label="Claims" value="claim" />
            {event.isAuction &&
            <Tab label="Auction" value="auction" />
            }
            {event.isArchive &&
            <Tab label="Stats" value="stats" />
            }
            <Tab label="Settings" value="settings" />
        </Tabs>
        <TabPanel name="live" activeTab={activeTab}>
            <Grid container spacing={5}>
                <Grid item  sm={12} md={6}>
                    <h4>Messages</h4>
                    <FormControlLabel className="form-input-field" control={
                        <Switch color="secondary" checked={showDeletedChat} onChange={ (e) => setShowDeletedChat(!showDeletedChat)} />
                    } label="Show Deleted" />

                      <IconButton onClick={ () => downloadChat() }><DownloadingIcon sx={{color:"#000" }} /></IconButton>
                 
                    <List className="event-chat-log" id="chat-log">
                        {chatLog.map( (chat, index) => {
                            return ( (!chat.deleted || showDeletedChat) &&
                            <ListItem key={chat.id + Math.floor(Math.random()*1000)} className="event-chat-item">
                                <ListItemIcon><Avatar alt={chat.sender} src={chat.sender_avatar} sx={{width:18,height:18}}>{chat.sender.substring(0,1)}</Avatar></ListItemIcon>
                                <ListItemText>
                                <span className="sender ">{chat.sender}</span>{chat.sender_verified && <span className="senderCheckMark"><CheckMark sx={{ position: "relative", top:"2px", width:12, height:12, color: "#2979ff" }}  /></span>} <span className="content">{chat.message}</span>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    {chat.isPinned ? 
                                        <IconButton onClick={ (e) => updatePinChat(chat.id, chat.original_timestamp !== undefined ? chat.original_timestamp.toDate() : chat.timestamp.toDate() , false)} >
                                            <PushPinIcon color="primary" />
                                        </IconButton>
                                    :
                                        <IconButton onClick={ (e) => updatePinChat(chat.id, chat.timestamp.toDate(), true)} >
                                            <PushPinIcon color="disabled" />
                                        </IconButton>
                                        }

                                    {chat.deleted && 
                                        <IconButton onClick={ (e) => updateChatStatus(chat.id, false)} >
                                            <DeleteForeverIcon color="disabled" />
                                        </IconButton>
                                    }
                                    {!chat.deleted && 
                                        <IconButton onClick={ (e) => updateChatStatus(chat.id, true)} >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                    }
                                    <IconButton onClick={ (e) => { if (window.confirm("Block User "+ chat.sender +"?") ) {
                                        onBlockUser(chat.senderid, chat.sender, true)
                                    }}} >
                                        <BlockIcon color="error" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>)
                        })}
                    </List>
                    <Box className="event-new-message">
                        <TextField id="new-message" size="small" name="new-message" fullWidth={true} label="Message" type="text"
                         placeholder="message..." className="form-input-field" value={chatMessage} 
                         onChange={ (e) => setChatMessage(e.target.value) } onKeyDown={ (e) => { if( e.key === "Enter" ) { sendChatMessage() }} }
                         InputProps={ {
                             endAdornment: <IconButton onClick={ () => { sendChatMessage() }} sx={{right:10}}><SendRoundedIcon color="primary" /></IconButton>
                         } }
                         />
                    </Box>
                </Grid>
                <Grid item sm={12} md={6}>
                    <h4>Stats</h4>
                    <List className="event-stats">
                        <ListItem key="live-stat" className="stat-item">
                             <ListItemText primary={"Current Users in Event: " + liveCount + " (Updates every 5 mins)"}  />
                         </ListItem>
                         <ListItem key="total-stat" className="stat-item">
                             <ListItemText primary={"Total Users Since Start: " + (guestCount + memberCount)} secondary={"Members: "+memberCount + " || Guests: " +guestCount} />
                         </ListItem>
                    </List>
                    <h4>Sales</h4>
                    <List className="event-orders">
                         {eventOrders.map( (order, index) => {
                            if(typeof order.products  !== "undefined" && order.products.length > 0){
                             var primary = (index+1) + " : " + order.products[0].name +" by " + order.displayname;
                             var secondary = "$" + parseFloat(order.total).toFixed(2) + " (Order #" + order.order_number + ")";
                             //eventSalesTotal.current += parseFloat(order.total);
                             return <ListItem key={order.id + Math.floor(Math.random()*1000)} className="order-item">
                                        <ListItemText primary={primary} secondary={secondary} />
                                    </ListItem>
                            }
                         })}
                    <h4>Recording</h4>
                    <div>
                    <Button onClick={ () => { if (window.confirm("Start Recording?") ) {
                        startRecording();
                    }}} >Start Recording</Button>
                    <Button onClick={ () => { if (window.confirm("Stop Recording?") ) {
                        stopRecording();
                    }}} >Stop Recording</Button>
                    <Button onClick={ () => { if (window.confirm("Start Recording?") ) {
                       queryRecording();
                    }}} >Query Recording</Button>
                    </div>
                    </List>
                    {eventSalesTotal.current > 0 && <div><p>Total Sales: <strong>${parseFloat(eventSalesTotal.current).toFixed(2)}</strong></p></div>}
                    <List className="event-orders">
                         {incompleteOrders && <h5>Pending Orders</h5>}
                         {incompleteOrders.map( (order, index) => {
                              var primary = (index+1) + " : " + order.products[0].name + " by " + order.displayname;
                              const reason = order.status !== "" ? order.status : " Payment rejected";
                              var secondary = "$" + order.subtotal + "\n (Reason: " + reason +")" ;

                              return <ListItem key={order.id + Math.floor(Math.random()*1000)} className="order-item">
                                        <ListItemText primary={primary} secondary={secondary} />
                                        <ListItemButton>
                                        <Button variant="outlined" onClick={ () => { retryPlaceOrder (order.products[0].id, order.uid, order.total, order.id)}} startIcon={<PaymentIcon />}>
                                            Retry
                                        </Button>
                                        </ListItemButton>
                                    </ListItem>
                         })}

                    </List>
                </Grid>
            </Grid>

        </TabPanel>
        <TabPanel name="claim" activeTab={activeTab}>
        <Button variant="contained" color="buttonDark" onClick={ ()=> setProductSelectOpen(true)} >Add Existing Products</Button>&nbsp;&nbsp;
        <Button variant="contained" color="buttonDark" onClick={ ()=> setBulkCreateOpen(true)} >Create New Products</Button>&nbsp;&nbsp;
        <Button variant="contained" color="buttonDark" onClick={ clearClaims } >Clear Claims</Button>
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
                            { (provided, snapshot) =>  <ImageList id="product-dnd-grid" cols={1} rowHeight={150} {...provided.droppableProps}
            ref={provided.innerRef} >
                {event.products.map( (productid,  index) => {
                    var product = {};
                    
                    for (var i=0; i< activeProducts.length; i++) {
                        if(productid === activeProducts[i].id) {
                            product = activeProducts[i];
                            var stock = activeProducts[i].size[0].inventory
                        }
                    }
                    
                    if(product.name !== undefined) {
                    return <Draggable key={product.id + Math.floor(Math.random()*1000)} draggableId={product.id} index={index}>
                                { (provided, snapshot) => (
                    <div  {...provided.draggableProps} {...provided.dragHandleProps}  ref={provided.innerRef}>
                    <LiveEventProductItem product={product}  isAuction={false} onEdit={ openProductEditDialog } key={productid + Math.floor(Math.random()*1000) }
                        id={product.id} image={product.image1} name={product.name} subtitle={product.description + " (Size: " + product.size[0].size +" / Stock: " + stock +")"} 
                         price={product.price} localShipping={product.shippingCost} intlShipping={product.intlShippingCost}
                        action={
                        <div>
                            <IconButton onClick={ (e) => onAddProduct(productid, false, false) } >
                                <DeleteForeverIcon color="error" />
                            </IconButton>
                            </div>
                     }
                        onUpdate={getActiveProducts}
                                
                     />
                    </div>
                    )} 
                    </Draggable>
                    } else {
                        return <div key={Date.now() + Math.floor(Math.random()*1000)} />
                    }
                    
                })}
            {provided.placeholder}
            </ImageList>
            
           }
        </Droppable>
        </DragDropContext>
        </TabPanel>
        <TabPanel name="auction" activeTab={activeTab}>
        
        <Grid container spacing={5}>
            <Grid item sm={12} md={6}>
                <h4>Live Auction</h4>
               
                { event.activeAuctionProduct !== "" ?
                <div>
               
                <Card sx={{marginTop:"10px"}}>
                    <CardHeader title={auctionProduct["name"]} subheader={auctionProduct["size"]} avatar={<Avatar src={auctionProduct["image1"]} />} />
                    <CardContent>
                       
                        <TextField disabled={isAuctionStarted} size="small" fullWidth={true} label= {event.isClaimMode === true ? "Min. Offer" :"Starting Bid"} placeholder="Starting Bid" type="number" className="form-input-field" 
                            value={startingBid}  
                            onChange= { (e) => setStartingBid(e.target.value) } 
                           />
                        
                        {event.isClaimMode === true ? <div /> :
                        <TextField  disabled={isAuctionStarted} size="small" fullWidth={true} label= "Reserve Price" placeholder="Reserve Price" type="number" className="form-input-field" value={reserve} onChange= { (e) => setReserve(e.target.value) } 
                        />
                        }
                        {event.isClaimMode === true ? <div /> :
                        <TextField  disabled={isAuctionStarted} size="small" fullWidth={true} label="Minimum Bid" placeholder="Minimum Bid" type="number" className="form-input-field" value={minbid} onChange= { (e) => setMinbid(e.target.value) }
                        />
                        }
                        <TextField disabled={isAuctionStarted} size="small" fullWidth={true} label={event.isClaimMode === true ? "Claim Price": "Buy Now Price"} placeholder="Buy Now Price" type="number" className="form-input-field" value={buynow} onChange= { (e) => setBuynow(e.target.value) } 
                        />
                        <TextField  disabled={isAuctionStarted} size="small" type="number" label="Timer (Minutes)" className="form-input-field" fullWidth={true} placeholder="auction time in minutes" value={auctionTime} onChange={ (e) => { setAuctionTime(e.target.value); setCountdownDisplay(timeDisplay(e.target.value*60))}}  />
                        
                    </CardContent>
                    <CardActions>
                   
                    </CardActions>
                </Card>
                <hr />
                
                {isAuctionStarted ? 
                    <Button variant="contained" color="buttonDark" onClick={ (e) => endAuction() } >{event.isClaimMode === true ? "End Claim" : "End Auction"}</Button>
                
                 :   <Button variant="contained" color="buttonDark" onClick={ (e) => startAuction() } >{event.isClaimMode === true ? "Start Claim" : "Start Auction"}</Button>
                }

                {isAuctionStarted &&
                    <div>
                        <br />
                        <Button variant="contained" color="buttonLight" onClick= { (e)=> { addAuctionTime(30)} }>+30sec</Button>&nbsp;
                        <Button variant="contained" color="buttonLight" onClick= { (e)=> { addAuctionTime(60)} }>+1min</Button>
                    </div>
                }
                <div>
                <Typography className="auction-time-countdown">
                { // isAuctionStarted ? timeDisplay(auctionCountdown.current) : timeDisplay(auctionTime*60) 
                    countdownDisplay
                }
                </Typography>
                </div>
                {auctionProduct.bid_history !== undefined && auctionProduct.bid_history.length > 0 && 
                <div>
                    <h4>Bids</h4>
                <List className="live-auction-bids" >
                    {auctionProduct.bid_history.map ( (data, index) => 
                        <ListItem key={index} className="live-auction-bid-item" secondaryAction={
                            
                            data["highest_bidder_uid"] === auctionProduct.highest_bidder_uid && data["highest_bid"] === auctionProduct.highest_bid ? 
                            <MonetizationOnIcon />: 
                            <Button variant="contained" color="buttonLight" onClick={ (e)=>{ setAuctionWinner(auctionProduct.id, data["highest_bidder_uid"], data["highest_bidder"], data["highest_bidder_pmid"], data["highest_bid_time"], data["highest_bid"]) }}>
                                Set Winner 
                            </Button>
                            
                            
                        }>
                            <ListItemText primary={data["highest_bidder"]} secondary={"$" + data["highest_bid"] + " at " + moment(data.highest_bid_time.toDate()).format("h:mm:ss, MMMM Do" )} 
                            
                            />
                        </ListItem>
                    )}
                </List>
                <br />
                { !isAuctionStarted && auctionProduct["processed"] !== true &&<Button variant="contained" color="buttonDark" onClick={ () => { processAuctionOrder(event.activeAuctionProduct)} } >Place Auction Order</Button>}&nbsp;&nbsp;
                { !isAuctionStarted && <Button variant="contained" color="buttonLight" onClick={ () => { clearAuctionBids(event.activeAuctionProduct)}}>Clear Bids</Button> }
                </div>
                }
                </div>
                : <p>No Product is selected for auction</p>
                }

            </Grid>
            <Grid item sm={12} md={6}>
            <Button variant="contained" color="buttonDark" onClick={ ()=> setAuctionSelectOpen(true) } >Add Products to Auction</Button>
            &nbsp;&nbsp;
        <Button variant="contained" color="buttonDark" onClick={ ()=> setBulkCreateOpen(true)} >Create New Products</Button>
            <ImageList cols={1} rowHeight={150}>
                {event.productsInAuction.map( (productid) => {
                    var product = {};
                    
                    for (var i=0; i< activeProducts.length; i++) {
                        if(productid === activeProducts[i].id) {
                            product = activeProducts[i];
                            var stock = activeProducts[i].size[0].inventory
                        }
                    }
                    
                    if(product.name !== undefined) {
                    return  <div >
                    <LiveEventProductItem product={product} id={productid} isAuction={true} onEdit={ openProductEditDialog } key={productid + Math.floor(Math.random()*1000)} 
                        image={product.image1} name={product.name} subtitle={product.description + " (Size: " + product.size[0].size +" / Stock: " + stock + ")"} 
                        localShipping={product.shippingCost} intlShipping={product.intlShippingCost}
                        action={<FormControlLabel label={event.isClaimMode ===true ? "For Claim"  : "Auction Now"} labelPlacement="start" sx={{color:"#fff"}} control={
                            <IconButton onClick={ (e) => { setActiveAuctionProduct(productid) }}>
                            
                                {event.activeAuctionProduct === productid ? 
                                    <CheckBoxIcon style={{color:"#fff"}} />
                                    :
                                    <CheckBoxOutlineBlankIcon style={{color:"#fff"}} />
                                }
                               
                            </IconButton>
                            }
                            />}
                            onUpdate={getActiveProducts}
                    />
                    
                        </div>
                    } else {
                        return <div key={Date.now() + Math.floor(Math.random()*1000)} />
                    }
                })}
            </ImageList>
            </Grid>
        </Grid>
        
        </TabPanel>
        <TabPanel  name="stats" activeTab={activeTab}>
        <Grid item sm={12} md={6}>
                   
                    <h4>Sales</h4>
                    <List className="event-orders">
                         {eventOrders.map( (order, index) => {
                             
                             var primary = (index+1) + " : " + order.order_number + " by " + order.displayname;
                             var secondary = "$" + order.total;
                             //eventSalesTotal.current += parseFloat(order.total);
                             return <ListItem key={order.id + Math.floor(Math.random()*1000)} className="order-item">
                                        <ListItemText primary={primary} secondary={secondary} />
                                    </ListItem>
                         })}
                    </List>
                    {eventSalesTotal.current > 0 && <div><p>Total Sales: <strong>${parseFloat(eventSalesTotal.current).toFixed(2)}</strong></p>
                   
                    </div>}
                </Grid>
        </TabPanel>
        <TabPanel name="settings" activeTab={activeTab}>
            <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={event.isActive ?? false} onChange={ (e) => { updateEventStatus({ "isActive" : !event.isActive }) }} />
            } label="Active" />
        <hr />
        <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={event.isLive ?? false} onChange={ (e) => { updateEventStatus({ "isLive" : !event.isLive }) }} />
            } label="Live" />
        <hr />
             <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={event.isTest ?? false} onChange={ (e) => { updateEventStatus( {"isTest": !event.isTest }) }} />
            } label="Preview" />
        <hr />
             <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={event.isAuction ?? false} onChange={ (e) => { updateEventStatus({ "isAuction": !event.isAuction }) }} />
            } label="Auction" />
        <hr />
         <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={event.isArchive ?? false} onChange={ (e) => { updateEventStatus( {"isArchive": !event.isArchive}) }} />
            } label="Shop" />
        <hr />
        <FormControlLabel className="form-input-field" control={
            <Switch color="secondary" checked={localShipping === 0 } onChange={ (e) => { setLocalShipping(localShipping > 0 ? 0 :8);} } />
        } label="Free Local Shipping" />
        { localShipping >0 && 
            <TextField name="shipping-local" fullWidth={true} type="number" className="form-input-field" placeholder="Local Shipping Fee" label="Local Shipping" value={localShipping} onChange={ (e) => setLocalShipping(e.target.value) } />
        }
        <hr />
        <FormControlLabel className="form-input-field" control={
            <Switch color="secondary" checked={intlShipping > -1 } onChange={ (e) => setIntlShipping( intlShipping >= 0 ? -1: 20) } />
        } label="International Shipping" />
        { intlShipping >= 0 &&
        <FormControlLabel className="form-input-field" control={
            <Switch color="secondary" checked={intlShipping === 0} onChange={ (e) => setIntlShipping(intlShipping >0 ? 0 : 20) } />
        } label="Free International Shipping"  />
        }
        { intlShipping >0 && 
            <TextField name="shipping-intl" fullWidth={true} type="number" className="form-input-field" placeholder="International Shipping Fee" label="International Shipping" value={intlShipping} onChange={ (e) => setIntlShipping(e.target.value) } />
        }
       
 
        <Button variant="contained" color="buttonDark" onClick={ updateProductShipping }>Apply Shipping Rates</Button>

        <hr />
        <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={event.isClaimHidden ?? false} onChange={ (e) => { updateEventClaimButton(event.isClaimHidden === true ? false : true) }} />
            } label="Hide Claims Button" />
        <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={event.isDonateHidden ?? false} onChange={ (e) => { updateEventDonateButton(event.isDonateHidden == true ? false : true) }} />
            } label="Hide Donate Button" />
        <hr />
    
       <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={event.onlyVerified ?? false} onChange={ (e) => { updateOnlyVerified(event.onlyVerified == true ? false : true) }} />
            } label="Only Verified Users can comment" />
        <hr />
        <TextField name="inviteCode" fullWidth={true} type="text" className="form-input-field" placeholder="Access Code" label="Access Code" id="invite-code" value={inviteCode} onChange={(e)=>setInviteCode(e.target.value)} />
        <Button variant="contained" color="buttonDark" onClick={ () => updateEventStatus({inviteCode: inviteCode})}>Set Invite Code</Button>


        </TabPanel>
    </Container>
    <Dialog fullScreen={true} open={productSelectOpen || auctionSelectOpen} onClose = { () => { setProductSelectOpen(false); setAuctionSelectOpen(false)}} name="product-select" id="product-select">
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent>
            <Container>
                <TextField sx={{marginTop: "10px" }}name="product-search" className="form-input-field" fullWidth={true} label="Search Product" placeholder="Search by product name..." value={productSearchText} onChange={(e) => onProductSearch(e.target.value)} />
                <ImageList spacing={5} id="product-select-list" rowHeight={150} cols={activeProducts.length <=4 ? activeProducts.length : 4} >
                    {activeProducts.filter( (product) => productSearchText === "" || product.name.toLowerCase().indexOf(productSearchText.toLowerCase()) >= 0).map( (product, index) => {

                        var checked = false;

                        if(productSelectOpen && event.products.includes(product.id)) {
                            checked = true;
                        }

                        if(auctionSelectOpen && event.productsInAuction.includes(product.id)) {
                            checked = true;
                        }

                        return (
                            <ImageListItem key={product.id + Math.floor(Math.random()*1000)} sx={{overflow:"hidden"}}>
                                <img src={product.image1} alt={product.name} style={{height:"150px"}}/>
                                <ImageListItemBar title={product.name} subtitle={"$" + product.price} actionIcon={
                                    <IconButton onClick={ () =>{ onAddProduct(product.id, !checked, auctionSelectOpen ) }}>
                                        {checked ? <CheckBoxIcon style={{color:"#fff"}} />:<CheckBoxOutlineBlankIcon style={{color:"#fff"}}/>}
                                    </IconButton>
                                } />
                            </ImageListItem>    
                        )
                    })}
                </ImageList>
            </Container>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="buttonDark" onClick={ () => { setProductSelectOpen(false); setAuctionSelectOpen(false);}}>Close</Button>
        </DialogActions>
    </Dialog>

    <Dialog  open={bulkCreateOpen}  name="bulk-create" id="bulk-create">
        <DialogTitle>Create New Products</DialogTitle>
        <DialogContent>
            <FormControl fullWidth={false} className="select-brand form-input-field" >
                <InputLabel variant="standard" id="brand-select-label" shrink={true}>Brand</InputLabel>
                <NativeSelect id="brands-select" value={productBrandId} onChange={ onBrandChange } label="Brands" >
                    <option value=""> --- </option>
                    {brands.map( (brand) =><option key={brand.id} value={brand.id}>{brand.name}</option>)}
                </NativeSelect>
                </FormControl>
                <TextField className="form-input-field" type="number" size="small" fullWidth={true} label="No. of products to create" placehoder="No. of products to create" value={qtyToCreate} onChange={ (event) => setQtyToCreate(event.target.value)} />
                
                
                <FormControlLabel className="form-input-field"  control={
                <Switch color="secondary" checked={directToAuction} onChange={ (e) => { setDirectToAuction(e.target.checked)}} />
                } label="Add To Auction" /> <br />

                <Button variant="contained" color="buttonDark" onClick={ () => quickCreateProducts()}>Create {qtyToCreate} products Now</Button>
        </DialogContent>
        <DialogActions>
            
            <Button variant="contained" color="buttonDark" onClick={ () => { setBulkCreateOpen(false);}}>Close</Button>
        </DialogActions>
    </Dialog>

    <Dialog open={editProductId !== ""}  name="edit-product" id={"edit-product-"+props.id}>
            <DialogTitle>Update Product Info</DialogTitle>

            <DialogContent>
                <br />
                <TextField className="form-input-field" type="text" size="small" fullWidth={true} label="Product Name" placeholder="Product Name" id="name" value={editProductName} onChange={ (e) => { setEditProductName(e.target.value);}} 
                    />
                <TextField className="form-input-field " type="text" size="small" fullWidth={true} label="Description" placeholder="Description" id="description" value={editProductDescription} onChange={ (e) => { setEditProductDescription(e.target.value);}} />
                {!editProductInAuction &&
                <TextField className="form-input-field" type="number" size="small" fullWidth={false} label="Price" placeholder="Price" id="price" value={editProductPrice} onChange={ (e) => { setEditProductPrice(e.target.value);}} 
                   
                    />}
                <br />
                <TextField className="form-input-field" type="number" size="small" fullWidth={false} label="Local Shipping" placeholder="Local Shipping" id="shippingLocal" value={editProductShippingLocal} onChange={ (e) => setEditProductShippingLocal(e.target.value) } />
                <br />
                <TextField className="form-input-field" type="number" size="small" fullWidth={false} label="International Shipping" placeholder="International Shipping" id="shippingIntl" value={editProductShippingIntl} onChange={ (e) => setEditProductShippingIntl( e.target.value)} />
                <FirebaseFileUpload id="image1-upload" className="form-image-upload" label="Main Image" accept="image/*" name="image1" storagePath="products/" onSuccess={ (path)=>{ setEditProductImage(path)}} preview={editProductImage} onRemovePreview={ () => onRemoveImage() }/> 
                <TextField className="form-input-field" type="text" size="small" fullWidth={true} label="Size" placeholder="Size" id="size" value={editProductSize} onChange={ (e) => { setEditProductSize(e.target.value);}} 
                    />
                    <TextField className="form-input-field" type="text" size="small" fullWidth={true} label="Stock" placeholder="Stock" id="inventory" value={editProductInventory} onChange={ (e) => { setEditProductInventory(e.target.value);}} 
                    />
            </DialogContent>
            <DialogActions>
           
                <Button variant="contained" color="buttonDark" onClick={ onUpdateProduct }>Update</Button>
                <Button variant="contained" color="buttonDark" onClick={ () => setEditProductId("") }>Cancel</Button>
                
            </DialogActions>
        </Dialog>

    </ConsoleTemplate>
}

export default LiveEventView;