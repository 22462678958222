import React from "react";
import { Container } from '@mui/material';


const TabPanel = (props) => {

    return <div hidden={props.activeTab !== props.name}>
        <Container className="tab-panel">
            {props.children}
        </Container>
    </div>
}

export default TabPanel;