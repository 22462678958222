import React from 'react'

import clsx from 'clsx'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

const LoadingIndicator = (props) => {
   
    return (
        <div className={clsx('loading_wrapper', !props.visible && 'hidden' )}>
            <Loader type="Rings" color="#f9a521" height={80} width={80} visible={props.visible} />
        </div>
    )

}

export default LoadingIndicator;