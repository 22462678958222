import React from 'react'
import { AppBar,Button }  from '@mui/material'
import logo from '../assets/exchvnge-logo-white.png';
import ExitIcon from '@mui/icons-material/ExitToAppOutlined'

import ConsoleMenu from '../components/consoleMenu';
import { getAuth, signOut } from 'firebase/auth';

const auth = getAuth();

const Header = (props) => { 

    


    const logout = async () => {
        await sessionStorage.clear();
        await signOut(auth);

        props.navigation.navigate("loginView");
        
    }

    return (

       
            <AppBar className="App-header" position="fixed" color="primary" ac>
                <div className="header-content">
                    {sessionStorage.getItem("uid") &&
                    <ConsoleMenu {...props} admin={props.admin} />
                    }
                    
                    <div className="app-header-logo">
                        <img src={logo} className="app-logo" alt="logo"/>
                    </div>
                    {sessionStorage.getItem("uid") &&
                    <div className="app-header-logout">
                        <Button variant="outlined" onClick={ (event) => { 
                                logout();
                            }}><ExitIcon style={{color:"#ccc"}} /></Button>
                    </div>
                    }
                </div>
            </AppBar>
            
  
    )
}

export default Header;
