import React from 'react';


import { ThemeProvider, createTheme } from '@mui/material/styles';





import AppNavigator from './components/navigators'
import './App.css';



const theme = createTheme({
  palette: {
    primary: {main: "#1d1e21", contrastText: "#fff"},
    secondary: {main: "#f9a521", contrastText: "#fff"},
    error: {main: "#f75252"},
    warning: {main: "#f75252"},
    cancel: {main:  "#cccccc"},
    info: {main: "#717171", light: "#ababab", dark: "#1d1e21"},
    buttonDark: {main: "#333", dark: "#1d1e21", light: "#454545" , contrastText: "#fff" },
    buttonLight: {main: "#ccc", dark: "#959595", contrastText: "#1d1e21" },
  
  },
 
  
});


function App() {


  return <ThemeProvider theme={theme}>
        <AppNavigator />
      </ThemeProvider>
  ;
}

export default App;
