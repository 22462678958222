import React, { useState, useEffect } from 'react';

import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import IconButton from '@mui/material/IconButton'

import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Divider  from '@mui/material/Divider'
import Input from '@mui/material/Input'
import Box from '@mui/material/Box'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import UploadIcon from '@mui/icons-material/UploadFile'




const FirebaseFileUpload = (props) =>{

    const [preview, setPreview] = useState();
    const [file, setFile] = useState("");
    const [isUploading,  setIsUploading] = useState(false);

    const storage = getStorage();

    useEffect( () => {
        if(props.preview !== undefined || props.preview !== null) {
            setPreview(props.preview);
        }
    }, [props.preview])
    

    const removePreview = () => {
        setPreview(null);
        if(props.onRemovePreview !== undefined) {
            props.onRemovePreview()
        }
    }
    
    const onUploadFile = async (file) => {
        //console.log(file);
        try{
        if(file !== null) {
            setIsUploading(true);
            const newFilename = Date.now().toString() +"_"+ file.name ;
            console.log("ref " + props.storagePath+newFilename);
            console.log(storage);
            const imageRef = ref(storage, props.storagePath+newFilename);
           
            console.log(imageRef);
             uploadBytes(imageRef, file).then( async (snapshot) =>{
                 console.log(snapshot);
                 const url = await getDownloadURL(imageRef);

                 onUploadSuccess(url);
                setIsUploading(false);
            }); 
        }
        
        }catch(err){
            setIsUploading(false);
            onUploadError(err.message);
        }
    }
    
    const onUploadSuccess = (downloadPath) => {
       // console.log("uploaded at " + downloadPath);
        if(props.onSuccess !== undefined) {
            props.onSuccess(downloadPath);
        }
    }

    const onUploadError = (message) => {
        console.log("Upload Error: " + message)
        if(props.onError !== undefined) {
            props.onError(message);
        } 

    }
 
    return (<Box sx={{marginTop:"10px", marginBottom:"10px"}}className={props.className}>
            <form encType='multipart/form-data'>
                <Typography sx={{marginBottom:"5px"}}>{props.label}</Typography>
                <label htmlFor={props.id+"-input"} className="form-image-upload-label">
                <Input sx={{ display: "none"}}type="file" accept={props.accept} id={props.id +"-input"} value={file} onChange={ (e) => onUploadFile(e.target.files[0])}/>
                <LoadingButton loading={isUploading} color="buttonDark" variant="contained" component="span"  endIcon={<UploadIcon />}>Upload</LoadingButton>
                </label>
               
                
            </form>
            {preview && 
                <div className="image-preview-wrapper">
                    <img src={preview} className="image-preview" alt={props.id + "-preview"} />
                    <IconButton aria-label="delete"  className="delete-image" size="medium" onClick={ removePreview }>
                            <RemoveCircleIcon fontSize="large" color="error"/>
                        </IconButton>       
                </div>
            }
            <Divider />
        </Box>);
}

export default FirebaseFileUpload;