import React, { useState, useEffect, useRef } from 'react'
import firebase from '../components/firebase'

import ConsoleTemplate, {adminUsers}  from '../components/consoleTemplate'

import { getFirestore, collection,  getDoc, getDocs, query, where, orderBy, setDoc, addDoc, doc} from "firebase/firestore";

import {Container, Tabs, Tab, Autocomplete, FormControlLabel, ImageList, ImageListItem , ImageListItemBar,  Button, Dialog, DialogActions, DialogContent, DialogTitle,  TextField, Switch,  IconButton, Select, FormControl, InputLabel, Input, Chip, MenuItem } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import FirebaseFileUpload from '../components/firebase-fileupload';
import TabPanel from '../components/tabPanel'

const StoreEditView = (props) => {

    const db = getFirestore();

    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState("info"); // info / success / error
    const [alertMessage, setAlertMessage] = useState("");
    const [activeTab, setActiveTab] = useState("info");
    const [activeProducts, setActiveProducts] = useState([]);
    const [productSelectOpen, setProductSelectOpen] = useState(false);
    const [hostUsers, setHostUsers] = useState([]);
    const [tags, setTags] = useState([]);
    var selectedTags = useRef([]);
    const [productSearchText, setProductSearchText] = useState("");
    const storeid = props.navigation.getParam("id") ?? "";
    const [state, setState] = useState({
        id:  "",
        name: "",
        description: "",
        image: "",
        isActive: false,
        isFeatured: false,
        tags: [],
        host: "",
        hostname: "",
        hostEmail: "",
        hostAvatar: "",
        products:[],
        updatedByName: "",
        updatedUid: "",
        updatedOn : new Date(),
        createdByName: "",
        createdByUid: "",
        createdOn: new Date(),
    })


        const getStore = async (id ) => {
            setIsLoading(true);
            try {
                const result = await getDoc((doc(db, "stores", id)));
                if(result.exists()) {
                    const doc = result.data();
                    setState({
                        ...state,
                        id: id ?? "",
                        name: doc.name ?? "" ,
                        description: doc.description ?? "",
                        image:  doc.image ?? "",
                        isActive: doc.isActive ?? false,
                        isFeatured: doc.isFeatured ?? false,
                        tags: doc.tags ?? [],
                        host: doc.host ?? "",
                        hostname:  doc.hostname ?? "",
                        hostEmail: doc.hostEmail ?? "",
                        hostAvatar: doc.hostAvatar ?? "",
                        products: doc.products ?? [],
                        
                    });
                }
            }catch(error) {
                setAlertMessage("Unable to load store info. Please try again later");
                setAlertType("error");
                setShowAlert(true);
            }
            setIsLoading(false);
        }

     const loadHostsInfo = async () => {
        try {
            
            var result;
      
            result = await getDocs(query(collection(db, "profiles"), where("isHost", "==", true), orderBy("displayname", "asc")));
                //await firestore.collection("profiles").where("isHost", "==", true).orderBy("email", "asc").get();
           
            
            if(result.size > 0 ){
                const docs = result.docs;
                var userResults = [];
                docs.forEach( (item) => {
                    const data = item.data();
                    //console.log(data);
                    const obj = { label: data.displayname +" (" + data.email +")", displayname: data.displayname, email: data.email, uid: data.uid, avatar: data.image}
                    userResults.push(obj);
            
                })
                console.log(userResults);
                setHostUsers(userResults)
            //    console.log(userResults)
            }
        } catch (err) {
            console.log("Error loading users");
            console.log(err);
        }
    }
    const loadTags = async () => {
        try {
            const result = await getDocs(query(collection(db, "tags"), orderBy("name", "asc")));
            const loadedTags= [];
            if( result.size> 0) {
                const docs = result.docs;
                docs.forEach( (item) => {
                    const data = item.data();
                    data.id = item.id;
                    loadedTags.push(data);
                });
                console.log(loadedTags);
                setTags(loadedTags);
            }

        }catch(err){
            console.error("Error loading Tags");
            console.error(err);

        }
    }
    
    const getAllProducts = async () => {
        try {
            const productResult = await getDocs(query(collection(db, "products"), where("isActive", "==", true), orderBy("name", "asc") ));
            //const productResult = await getDocs(query(collection(db, "products"), where("isActive", "==", true), orderBy("name", "asc") ));

            if(!productResult.empty) {
                const productDocs = productResult.docs;
                const products = [];
                productDocs.forEach( (product, index) => {
                    const productData = product.data();
                    productData.id = product.id;
                    products.push(productData);
                });
               // console.log("active products");
               // console.log(products);
                setActiveProducts(products);
            
                  
                }

        
        } catch (err) {
            setAlertMessage("Error loading products");
            setAlertType("error");
            setShowAlert(true);
            console.log(err);
        }
    }
    
    useEffect ( () => {
        
        if (storeid !== "") {
      
            getStore(storeid);
            loadHostsInfo();
            loadTags();
            getAllProducts();
        }
    },[]);

    

    const onActiveChange = (event, checked ) => {
        console.log(checked);
        setState( {
            ...state,
            isActive: checked,
            
        })
    }

    const onFeatureChange = (event, checked ) => {
        setState( {
            ...state,
            isFeatured: checked,
        })
    }


    const onTagsChange = (event, child ) => {
        
        const selected = child.props.children;
        var tagInList = false;
        selectedTags.current.forEach( (tag, index) => {
            //console.log(tag);
            if(tag === selected) {
                console.log("remove " + tag)
                selectedTags.current.splice(index, 1);
                tagInList = true;
                
            }
        });
        if(!tagInList) {
            selectedTags.current.push(selected);   
        }
       setState( {
            ...state,
            tags: selectedTags.current
        }) 
        } 
    
        const onProductSearch = (productName) => {
            setProductSearchText(productName);
        }
        const onAddProduct = async (productid, toAdd, isAuction) => {
            toAdd ? console.log("add " + productid) : console.log("remove " + productid);
            var products = isAuction ? state.productsInAuction : state.products;
            
            
    
            if(toAdd) {
                
                products.push(productid);
    
                
                    setState({...state, products: products});
                
            } else {  
                products.splice(products.indexOf(productid), 1);
                
                setState({...state, products: products});
                
                
            }
            
            
    
        }
        
    

    const updateStore  = async () => {
        setIsLoading(true);
        try {
            const storeid = props.navigation.getParam("id") ?? "";
            const storeObj = state;
            storeObj.updatedByName = await sessionStorage.getItem("displayname") ?? "";
            storeObj.updatedUid = await sessionStorage.getItem("uid") ?? "";
            storeObj.updatedOn = new Date();
            if(storeid !== "") {
                await setDoc(doc(db, "stores", storeid), storeObj, {merge: true});
            } else {
                storeObj.createdByName = await sessionStorage.getItem("displayname") ?? "";
                storeObj.createdByUid = await sessionStorage.getItem("uid") ?? "";
                storeObj.createdOn = new Date();
                const addResult = await addDoc(collection(db, "stores"), storeObj);

            }

            props.navigation.navigate("storesLanding");

        } catch (error) {
            console.log(error);
            setShowAlert(true);
            setAlertType("error");
            setAlertMessage("Unable to update store information. Please try again later");
        }
        setIsLoading(false);
    }

    const cancelUpdate = () => {
        props.navigation.navigate("storesLanding");
    }

    

    

    return   <ConsoleTemplate {...props} isLoading={isLoading} tab={1} showAlert={showAlert} setShowAlert={setShowAlert} alertType={alertType} alertMessage={alertMessage}>
             
    <h2>Store Details - {state.name}</h2>
    <Container className="form-tabs">
        <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
            <Tab label="Info" value="info" />
            <Tab label="Products" value="products" />
        </Tabs>
    </Container>
    <TabPanel name="info" activeTab={activeTab}>
        <TextField  fullWidth={true} label="Store Name" type="text" className="form-input-field" placeholder="Store Name" id="name" required value={state.name} onChange={ (event) => { setState({...state, name: event.target.value})}} />
        <TextField className="form-input-field" label="Description" fullWidth={true} placeholder="Description" aria-label="Event description" value={state.description} onChange={ (event) => { setState( { ...state, description:event.target.value })}}/>
        <FirebaseFileUpload id="image-upload" className="form-image-upload" label="Cover Image" accept="image/*" name="image1" storagePath="events/" onSuccess={ (path)=>{ setState({...state, image: path})}} preview={state.image} onRemovePreview={ () => {setState({...state, image: ""}) }}/> 
        
        <FormControlLabel className="form-input-field" control={
                    <Switch onChange={onActiveChange}  checked={state.isActive} color="secondary" />
                } label="Active" />
        <br />
        { adminUsers.includes(sessionStorage.getItem("hash")) &&
        <FormControlLabel className="form-input-field" control={
                    <Switch onChange={onFeatureChange}  checked={state.isFeatured} color="secondary" />
                } label="Featured Store" />
        }
        <br />
        <Autocomplete fullWidth={true} id="host" options={hostUsers} autoSelect={true} autoHighlight={true} openOnFocus={true}
                    value={state.hostname !== "" ? state.hostname + " (" + state.hostEmail+")" : ""}
                    renderOption = { (props, option, state) => <li key={option.uid}  {...props} >{option.label}</li>}
                    renderInput = { (params) => <TextField className="form-input-field" {...params} label="Host" variant="outlined" inputProps={{...params.inputProps}} />}
                    onChange={ (event, value, reason, details) => { 
                        setState( {...state, 
                            hostname: value.displayname,
                            host: value.uid,
                            hostEmail: value.email,
                            hostAvatar: value.avatar,
                        })
                    }}
                />
        <FormControl fullWidth={true} className="multi-select select-category form-input-field">
                            <InputLabel variant="standard" id="brand-select-label" shrink={true}>Tags</InputLabel>
                            <Select id="product-tags" multiple value={state.tags} onChange={ onTagsChange } 
                                input={<Input id="select-tag-chips" />}
                                renderValue={
                                    (selected) => (
                                        <div className="select-chips tag-chips">
                                            {selected.map(( tag) => <Chip key={tag} label={tag} className="tag-chip" />)}
                                        </div>
                                    )
                                }
                            >   
                            {tags.map (( tag ) => <MenuItem  key={tag.id} value={tag.id}>{tag.name}</MenuItem>)}
                            </Select>

                         
                        </FormControl>
    </TabPanel>
    <TabPanel name="products" activeTab={activeTab}>
        <Button variant="contained" color="buttonDark" onClick={ () => setProductSelectOpen(true)}>Add Product to Shop</Button>
        <ImageList cols={4} rowHeight={150}>
                    {state.products.map ((productid, index) => {
                        
                        var product = {};
                        for(var i=0; i<activeProducts.length; i++) {
                            if(activeProducts[i].id === productid) {
                                product = activeProducts[i];
                            }
                        }

                        return product.name !== undefined && <ImageListItem key={productid} sx={{overflow:"hidden"}}>
                                 <img src={product.image1} alt={product.name} style={{height:"150px"}} />
                                 <ImageListItemBar title={product.name} subtitle={"$"+ product.price} position="bottom" 
                                   
                                 />
                                </ImageListItem>
                    })}
                </ImageList>
    </TabPanel>
    <div className="input-row form-actions">
                <Button variant="contained" color="primary" endIcon={ <SaveIcon /> } onClick={ (event) => { updateStore() }}>
                    Save
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="contained" color="info" endIcon={ <ClearIcon /> } onClick={ (event) => { cancelUpdate() } }>
                    Cancel
                </Button>
                

                </div>
    <Dialog fullScreen={true} open={productSelectOpen } onClose={ () => { setProductSelectOpen(false); }} >
                <DialogTitle>Add Product</DialogTitle>
                <DialogContent>
                <Container>
                <TextField sx={{marginTop: "10px" }}name="product-search" className="form-input-field" fullWidth={true} label="Search Product" placeholder="Search by product name..." value={productSearchText} onChange={(e) => onProductSearch(e.target.value)} />
                <ImageList spacing={5} id="product-select-list" rowHeight={150} cols={activeProducts.length <=4 ? activeProducts.length : 4} >
                    {activeProducts.filter( (product) => productSearchText === "" || product.name.toLowerCase().indexOf(productSearchText.toLowerCase()) >= 0).map( (product, index) => {

                        var checked = false;

                        if(productSelectOpen && state.products.includes(product.id)) {
                            checked = true;
                        }

                        
                        return (
                            <ImageListItem key={product.id} sx={{overflow:"hidden"}}>
                                <img src={product.image1} alt={product.name} style={{height:"150px"}}/>
                                <ImageListItemBar title={product.name} subtitle={"$" + product.price} actionIcon={
                                    <IconButton onClick={ () =>{ onAddProduct(product.id, !checked ) }}>
                                        {checked ? <CheckBoxIcon style={{color:"#fff"}} />:<CheckBoxOutlineBlankIcon style={{color:"#fff"}}/>}
                                    </IconButton>
                                } />
                            </ImageListItem>    
                        )
                    })}
                </ImageList>
                </Container>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="buttonDark" onClick={ () => { setProductSelectOpen(false); }} >Close</Button>
                </DialogActions>
                </Dialog>


    </ConsoleTemplate>
}

export default StoreEditView
